import React from "react";
import { AuthProtected } from "@swiftcarrot/react-auth";
import { Loading } from "components";
import AuthLayout from "src/routes/_auth";
import LoginPage from "src/routes/_auth/login";

export default function Layout({ children }) {
  return (
    <AuthProtected
      renderLoading={Loading}
      renderLogin={() => (
        <AuthLayout>
          <LoginPage />
        </AuthLayout>
      )}
    >
      {children}
    </AuthProtected>
  );
}
