import React, { useState, Fragment } from "react";
import { cx } from "components";
import { SearchInput } from "./components/SearchInput";
import { SearchResult } from "./components/SearchResult";
import { Recommendation } from "./components/Recommendation";
import { analytics } from "ccch9051/src/analytics";

// TODO: tab "Recently Played"
const TABS = ["Custom Search", "Recommendation"];

export const BGM = ({ value, onChange, onCancel, story, scene }) => {
  const [tab, setTab] = useState(0);
  const [params, setParams] = useState({
    q: "",

    // set is ordered in javascript anyway
    // TODO: better tag managment without hardcoded category everywhere
    genres: new Set(),
    moods: new Set(),
    instruments: new Set(),
    themes: new Set(),
  });

  return (
    <div className="fixed top-[36px] right-0 bottom-0 w-[350px] border flex flex-col z-10 bg-white">
      <div
        className="absolute top-0 left-[-30px] w-[30px] h-[30px] text-lg text-center leading-[30px] bg-gray-700 text-white cursor-pointer"
        onClick={onCancel}
      >
        &times;
      </div>

      <div className="tabs">
        {TABS.map((d, i) => (
          <div
            key={d}
            className={cx("tab", { tabActive: tab === i })}
            onClick={() => {
              analytics.track(`Click BGM ${TABS[i]}`, {
                story_id: story.id,
                scene_id: scene.id,
              });

              setTab(i);
            }}
          >
            {d}
          </div>
        ))}
      </div>

      {tab === 0 ? (
        <Fragment>
          <SearchInput params={params} setParams={setParams} />
          <SearchResult
            source="Custom Search"
            params={params}
            onChange={onChange}
            story={story}
            scene={scene}
          />
        </Fragment>
      ) : tab === 1 ? (
        <Recommendation story={story} scene={scene} onChange={onChange} />
      ) : (
        <div>Recent played music will show here</div>
      )}
    </div>
  );
};
