import styles from "./UserAvatar.module.scss";
import cx from "clsx";
import React from "react";

export default function UserAvatar({ user, size, className, ...props }) {
  return (
    <div
      className={cx(styles.avatar, className)}
      style={{ width: size, height: size }}
      {...props}
    >
      <img
        className={styles.image}
        width={size}
        height={size}
        src={
          user.avatar
            ? user.avatar.url
            : require("src/images/default-avatar.png")
        }
        alt={user.name}
      />
    </div>
  );
}

UserAvatar.defaultProps = {
  size: 40,
};
