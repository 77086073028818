// TODO: splash from Player.js

// "Loading VR Story"
// "Start"

// TODO: test loading error (refresh)
// TODO: player separate build with tailwindcss
export class Splash {
  constructor(story) {
    // TODO: use html tag template
    const $container = document.createElement("div");
    $container.className =
      "absolute w-full h-full top-0 left-0 flex items-center justify-center flex-col z-10 bg-white space-y-4 px-4 z-[10000]";

    const $text = document.createElement("div");
    $text.className = "text-lg";
    $text.innerHTML = "Loading VR Story...";

    const $progress = document.createElement("div");
    $progress.className = "rounded-full bg-gray-200 w-full max-w-[400px]";

    const $progressBar = document.createElement("div");
    $progressBar.className = "h-3 rounded-full bg-gray-700 max-w-full";
    $progress.append($progressBar);

    const $button = document.createElement("button");
    $button.className =
      "bg-gray-700 text-lg text-white px-6 py-2 rounded-lg shadow hover:opacity-70 hidden";
    $button.innerText = "Enter";

    $container.append($text, $progress, $button);
    this.$container = $container;
    this.$progress = $progress;
    this.$progressBar = $progressBar;
    this.$text = $text;
    this.$button = $button;
    this.story = story;
  }

  updateProgress(progress) {
    this.$progressBar.style.width = progress * 100 + "%";
  }

  finish() {
    this.$text.innerHTML = this.story.title;
    this.$button.classList.remove("hidden");
    this.$progress.remove();
  }

  remove() {
    this.$container.remove();
  }
}
