import React, { useEffect } from "react";
import { gql, useQuery } from "@apollo/client";
import { AspectRatio, Loading } from "components";
import { Container, Row, Col } from "react-grid";
import { imageURL } from "src/utils";

// prettier-ignore
export const STORIES = [
  { "title": "Pak Tai Temple (HK)", "id": "84370aac-7e01-4cde-a5d3-63df149808f4" },
  { "title": "Shing Wong Temple (HK)", "id": "cf195172-3e15-4dc0-b199-172aa5daebc5" },
  { "title": "Chiwan Tianhou Temple (Shenzhen)", "id": "5c5e549a-3ada-4bde-ac42-d3a789e1dedd" },
  { "title": "Ladder Street (HK)", "id": "cc1d0a52-bf4e-4512-8a45-295b4e66c6ff" },
  {
    "title": "Hong Kong Trams and Tramways",
    "id": "eca932c3-edab-476d-88ad-e1a55263d018",
  },
  {
    "title": "Jubilee Battery (HK)",
    "id": "88db17a8-8828-4421-a5e7-d98145701287",
  },
  {
    "title": "Khalsa Diwan Hong Kong",
    "id": "3a595e8c-3116-4de4-83d8-d5fadae294ac",
  },
  {
    "title": "Former Yau Ma Tei Police Station (HK)",
    "id": "e28bf3e1-e94e-42d2-a0ae-28cec13e95e5",
  },
  {
    "title": "Foreign Correspondents' Club (HK)",
    "id": "d4e6da40-fa30-4faa-95f3-dbd626916fec",
  },
  {
    "title": "The Former Dona Carolina Cunha Mansion (Macau)",
    "id": "958be90e-07af-458a-8ac4-e147cffde3d3",
  },
  {
    "title": "Nan Lian Gardn (HK)",
    "id": "3a04066f-e8ca-471a-99dd-4e9a59432224",
  },
  {
    "title": "Race Course Fire Memorial (HK)",
    "id": "6e184c44-b80a-45ba-9f8b-d6856f93afca",
  },
  {
    "title": "Kwong Fuk Ancestral Hall",
    "id": "2ae47bf8-2dd0-4e33-9d3c-95abff7ae21e",
  },
  {
    "title": "1933 Shanghai Slaughterhouse (China)",
    "id": "bfbba549-54b0-45b8-acf7-0061f1f12718",
  },
  {
    "title": "Old Wan Chai Post Office (HK)",
    "id": "448b0501-5774-48d7-bb75-88754dca36a6",
  },
  {
    "title": "St. Paul's Convent Church (HK)",
    "id": "bca56c7d-fd72-40c8-9577-537cbc9e8407",
  },

{ id: "f6346d54-93a2-43c1-9f54-e169d6666b38" },
{ id: "5aa1afc2-77d5-4806-8563-ec19f1486c91" },
{ id: "703aa6ce-b77c-4d53-bae8-2dff09619313" },
{ id: "3bc97ca7-2a7d-4e01-8193-4154914984f7" },
{ id: "2b52fdf9-6abf-4c45-9d99-98ed2bc0ab4e" },
{ id: "35833503-043e-4edb-8afa-d63875f86e5b" },
{ id: "62d8535b-598c-4f47-8f45-2b7d7f981b44" },
{ id: "4538168c-29bd-4170-a20e-95576e68c627" },
{ id: "8123f53e-1e96-4e7b-b6a5-3c7f92f96caf" },
{ id: "3b2c4430-5244-4360-8dce-19be2e9e918b" },
{ id: "c38ab148-5337-4b73-987e-185d4397ee37" },
{ id: "071346ed-d16a-4de1-9ff7-600af8efc6c0" },
{ id: "a95e677c-ae9f-4fa7-9999-34389f527b7d" },
{ id: "ce6a85a0-0dc8-4e34-b136-726f4bc2067a" },
{ id: "8f5d5680-b628-4ee1-8235-45c1505c4aa7" },
{ id: "a7049809-e36e-47b6-afb6-7c33761b1789" },
{ id: "75519eaa-201e-40b8-8b0d-81e22f639577" },
{ id: "d116273a-9a49-48e7-96e1-844decd81650" },
{ id: "a49f23b6-9208-4192-a414-123ae8df508b" },
{ id: "85d7f7dc-3561-4d73-a48b-78215eee5865" },
{ id: "dc190413-78ec-4dc8-ad02-b0073be39c03" },
  { title: "Chungking Mansion (HK) ", id: "758e8204-1015-41c7-a8b5-636cb085fbe1" },
  { title: "Eliot Hall and May Hall (HKU)", id: "c51f1db0-3cb6-443e-be99-66ff09c822af" },
  { title: "National History Museum (UK) ", id: "5071228a-156c-4f0f-bb3d-bce06738e90b" },
  { title: "Tengwang Pavilion (China)", id: "ecdee3c0-b3fa-47d8-9010-85d9433cf5df" },
  { title: "Whitfield Barracks (HK)", id: "ffa824b5-799e-4f6c-bc03-14662788a82f" },
  { title: "Peninsula (HK) ", id: "ff53dbe2-97db-4073-b6e8-ffd32003dc4c" },
  { title: "Tin Hau Temple (HK)", id: "4ea25bc0-7b7c-40f1-8014-036c85a6f66e" },
  { title: "Lo Pan Temple (HK) ", id: "fd2b178e-20df-4bd3-8ef3-c0787b911156" },
  { title: "Bonham Road Goverment Primary School (HK)", id: "a5487764-9fd1-492c-9960-3571620353f3" },
  { title: "Western Market (HK)", id: "b6b287ae-2d47-4f1f-bdd4-089833566548" },
  {title: "Dining Hall at St. John's College (HKU)" ,id: "828a12c4-d3d2-4c07-8a10-91b8d6da23f4"},
  {title: "St. Mary's Church (HK) " ,id: "ce88c42f-04ab-40ba-b3a9-404ec11f8738"},
  {title: "Tianzifang (China) " ,id: "908c83b9-250f-4e63-adde-902792526118"},
  {title: "Thean Hou Temple (Malaysia)" ,id: "20c68512-cfd1-4c80-94df-a69e34091bd6"},
  {title: "Pottinger Street (HK)" ,id: "fe7e1b7d-856f-4b09-a77b-935efc35b09e"},
  {title: "Canglang Pavilion (China)" ,id: "ae1af82e-aeb8-42ce-a6dd-827aaf456fa4"},
  {title: "Fan Sin Temple (HK)" ,id: "12bd0fc0-7d6e-490b-bf5e-0ae16ed00c4b"},
  {title: "Yu Garden (China)" ,id: "e677fab0-4e77-4ec5-a781-65c7ceb2c58d"},
  {title: "Martinikerk (Netherlands)" ,id: "8119a2c0-0e9c-4768-8177-d372211ff6cd"},
  {title: "Clock Tower (HK) " ,id: "265fc9f0-23b4-404e-a4ac-f48bb3449f8c"},
  {title: "Nanguanxiang Historical and Cultural District (China)" ,id: "9f0cdb88-a8df-443c-a5fd-562f86bca430"},
  {title: "Blue House (HK)" ,id: "aeab1ba4-232e-4ab4-aa78-90b0ae1f3598"},
  {title: "Peace Hotel (China)" ,id: "138d4b4e-7488-4f09-a027-dc9d69142cdf"},
  {title: "Former Marine Police Headquarters / 1881 Heritage (HK) " ,id: "6bc48fba-323b-4b5c-a771-3b691bea30ff"},
  {title: "Mengle Buddhist Temple (China) " ,id: "242e623e-e4ae-4326-94ab-d5d303906559"},
  {title: "Old Town of Jakarta (Indonesia)" ,id: "022b7615-91a4-47a9-acb1-9f2924d5e47d"},
  {title: "Central Market (HK)" ,id: "ab251fb5-728e-405f-8d75-96860597e130"},
  {title: "Daci Temple (China)" ,id: "fe6addcd-fb59-4dc3-8b85-79047cfca216"},
  {title: "Elliot Pumping Station & Filters (HKU) " ,id: "8c7619fc-a52f-4691-a583-136d35312c1a"},
  {title: "Mei Ho House (HK)" ,id: "3bcadea2-57b3-42e1-a561-8373d3f63996"},
  {title: "Yu Palace (China)" ,id: "a948d2f7-3c07-4173-9af3-69eec897adc6"},
  {title: "Wong Uk Village (HK) " ,id: "ac07efeb-e98b-4f62-99d1-3a6fa125df17"},
  {title: "Yuxiang Cotton Mill (China)" ,id: "b6e7097e-aaf1-4991-a71e-ded407e2d674"},
  {title: "Wat Chaiwatthanaram (Thailand) " ,id: "9f51171d-dca3-48e1-b6c2-406a7d241b80"},
  {title: "Jun Wang Fu (China)" ,id: "d3621146-a9a2-4155-968a-02cb74d3d3bf"},
  {title: "Tianzhuang Ancient Street (China)" ,id: "f8a34af9-5e6b-49d4-9b9e-2945aed4a938"},
  {title: "Woo Cheong Pawnshop (HK) " ,id: "6a3b0b0c-ff83-4a4d-8939-6e7c9bf1f7be"},
  {title: "Longzhou Natural Landscape Park (China)" ,id: "c8dc4d95-5bf8-4f41-acb6-b015edfd7bb4"},
  {title: "Malayan Railway Administration Building (Malaysia) " ,id: "aa1c9ba3-6e7e-48f7-a078-c8b94c3d0667"},
  {title: "Sung Wong Toi Garden (HK)" ,id: "c753608a-4b23-4239-bc29-584c8149b299"},
  {title: "Xu Zhimo's Former Residence (China)" ,id: "4af53068-4179-44fa-915e-e96f8e08f0d7"},
  {title: "Chater Garden (HK) " ,id: "a8b839b7-15eb-471b-b0f1-11a494e89007"},
  {title: "Kuanzhai Alley (China) " ,id: "85fa79de-7599-4a4b-89c0-72cdb2b6ed59"},
  {title: "Hampton Court Palace (UK)" ,id: "ae458bf5-de0f-4ae1-a270-e0056043fad4"},
  {title: "Pak Tsz Lane Park (HK) " ,id: "28be96f1-2c48-4019-8f62-cf008c14a04a"},
  {title: "Former Residence of Zhao Puchu (China) " ,id: "b02db343-8522-4c29-9a33-fe4e3751099c"},
  {title: "Sun Yat Sen Memorial Park (HK) " ,id: "623c14ba-e0f0-4f05-87d1-0a794c586121"},
  {title: "Mausoleum of Genghis Khan (China)" ,id: "b07f7054-88cf-4308-9ede-0c77e1098347"},
  {title: "Law Uk Folk Museum (HK)" ,id: "5e1be623-a032-4dc0-8b3b-c542711506cd"},
  {title: "Yellow Crane Tower (China) " ,id: "0632cf44-04ff-4476-9bbc-74775e9c3853"},
  {title: "Centotaph (HK) " ,id: "c889ad76-4cfc-49ab-b065-13e8d419b527"},
  {title: "Baixiangju (China) " ,id: "f4fbb876-8a69-467b-8d46-3580d8b07486"},
  {title: "7 Mallory Street (HK)" ,id: "6f063c1f-8386-42ad-85f1-3c4b6720e2b4"},
  {title: "Hero Mountain (China)" ,id: "b0aef0c1-478f-42df-8940-0af4c5945378"},
  {title: "Island House (HK)" ,id: "40241bd6-2a62-4758-9681-febdebc5dff0"},
  {title: "Pokfulam Village (HK)" ,id: "93bbaf34-74f4-447f-91cf-70829afeab1f"},
  {title: "The Mills (HK) " ,id: "fe2d4a06-cfa5-4b09-9291-7226a17b753f"},
  {title: "Rosary Church (HK) " ,id: "d29a066c-2aa3-413d-b388-36495afdb7cd"},
  {title: "Lin Fa Kung (HK) " ,id: "abca9260-2374-49e1-9dc7-e7eedbf1f2af"},
  {title: "Bank of China (HK) " ,id: "e8ebb025-d7c3-4424-b3a7-d825135e98ef"},
  {title: "Yim Tin Tsai (HK)" ,id: "40ba0b29-5719-4996-bbf3-f3cb4def43c7"},
  {title: "Signal Hill Tower (HK) " ,id: "5f5ab970-4eb5-4fcb-b69d-15043051691c"},
  {title: "Sai Wan Ho Pier (HK) " ,id: "e0a15185-3bd1-4f9c-b772-e3fff6c5e034"},
  {title: "Lui Seng Chun (HK) " ,id: "351d0a8a-b1f6-427f-b86f-4efe0de71ff0"},
  {title: "Lei Cheng Uk Han Tomb (HK) " ,id: "acd084ce-00a2-4108-9351-0ec92d043862"},
  {title: "Former Kowloon British School (HK) " ,id: "d9b3193b-f349-421b-b608-83c63bba1f79"},
  {title: "Old Central Police Station / Tai Kwun (HK) " ,id: "77c7f8ee-c17a-40b6-9fa2-fe3dbcd1046f"},
];

const STORY_TITLES = STORIES.reduce((x, s) => ({ ...x, [s.id]: s.title }), {});

export default function Page() {
  const { data, loading, error } = useQuery(QUERY, {
    variables: { ids: STORIES.map((s) => s.id) },
  });
  const storyMap = data
    ? data.stories.reduce((x, s) => ({ ...x, [s.id]: s }), {})
    : null;

  useEffect(() => {
    document.body.style.overscrollBehaviorY = "auto";

    return () => {
      document.body.style.overscrollBehaviorY = "";
    };
  }, []);

  return (
    <Container>
      <h1
        style={{
          fontWeight: "400",
          fontSize: 38,
          lineHeight: 1,
          marginBottom: 6,
        }}
      >
        Discover
      </h1>

      {loading ? (
        <Loading />
      ) : error ? (
        <div>Network Errror</div>
      ) : (
        <div>
          <div
            style={{
              marginBottom: 25,
              fontSize: 16,
              color: "#666",
              marginLeft: 4,
            }}
          >
            Click image to view VR scenes
          </div>
          <Row>
            {STORIES.map(({ id }) => {
              const story = storyMap[id];

              return (
                <Col md={6} key={story.id}>
                  <a
                    href={`/watch?id=${story.id}`}
                    target="_blank"
                    className="showcase-item"
                  >
                    <AspectRatio ratio="2x1">
                      <img
                        alt={STORY_TITLES[story.id]}
                        src={imageURL(story.scenes[0].image.url, {
                          width: 600,
                          height: 600,
                        })}
                      />
                    </AspectRatio>
                    <div className="showcase-item-title">
                      {STORY_TITLES[story.id] || story.title}
                    </div>
                  </a>
                </Col>
              );
            })}
          </Row>
        </div>
      )}

      <div
        style={{
          marginTop: 4,
          paddingTop: 24,
          marginBottom: 36,
          color: "#666",
          fontSize: 12,
          borderTop: "1px solid #eaeaea",
        }}
      >
        CCMIR ◎ HKU Culture Computing and Multimodal Information Research
      </div>
    </Container>
  );
}

export const QUERY = gql`
  query Showcase($ids: [ID!]!) {
    stories(ids: $ids) {
      id
      title
      scenes {
        id
        image {
          key
          url
        }
      }
    }
  }
`;
