import React, { useState } from "react";
import { createPortal } from "react-dom";
import { gql, useMutation } from "@apollo/client";
import { BGM } from "bgm";
import { Button, FileUploaderButton } from "components";
import { uploadFile } from "src/utils";
import { captureException } from "src/sentry";
import { analytics } from "src/analytics";
import { MusicGeneration } from "./MusicGeneration";

export const AudioInput = ({ onChange, story, scene }) => {
  const [step, setStep] = useState(0);
  const [createSong] = useMutation(CREATE_SONG);

  const handleBGMChange = (song) => {
    return fetch(song.stream_url)
      .then((resp) => resp.blob())
      .then((blob) => {
        return uploadFile("SONG_AUDIO", blob, console.log);
      })
      .then((data) => {
        return createSong({
          variables: {
            input: {
              source: "jamendo",
              sourceID: song.id + "",
              title: song.title,
              duration: song.duration,
              audio: data.key,
              payload: song,
            },
          },
        });
      })
      .then((resp) => {
        onChange(resp.data.createSong.audio);
        setStep(0);
      })
      .catch((err) => {
        captureException(err);
      });
  };

  return (
    <div>
      {step === 0 ? (
        <div>
          <Button
            onClick={() => {
              analytics.track("Click Add Music Audio", {
                story_id: story.id,
                scene_id: scene.id,
              });
              setStep(1);
            }}
          >
            Add Music Audio
          </Button>
        </div>
      ) : step === 1 ? (
        <div className="flex flex-wrap gap-3">
          <Button
            onClick={() => {
              setStep(3);
              analytics.track("Click Add Music Search", {
                story_id: story.id,
                scene_id: scene.id,
              });
            }}
            className="flex-1"
          >
            Search
          </Button>

          <Button
            onClick={() => {
              setStep(4);
              analytics.track("Click Music Generation", {
                story_id: story.id,
                scene_id: scene.id,
              });
            }}
            className="flex-1"
          >
            Generate
          </Button>

          <FileUploaderButton
            className="flex-1"
            accept="audio/mpeg,audio/ogg,audio/wav"
            onChange={(e) => {
              const file = e.target.files[0];
              // TODO: file size limit
              uploadFile("SCENE_MUSIC_AUDIO", file, console.log)
                .then((audio) => {
                  onChange(audio);
                })
                .catch((err) => {
                  captureException(err);
                });
            }}
          >
            Upload
          </FileUploaderButton>

          <Button
            variant="secondary"
            onClick={() => {
              setStep(0);
              analytics.track("Click Add Music Cancel", {
                story_id: story.id,
                scene_id: scene.id,
              });
            }}
          >
            Cancel
          </Button>
        </div>
      ) : null}

      {step === 3
        ? createPortal(
            <BGM
              onChange={handleBGMChange}
              onCancel={() => setStep(1)}
              story={story}
              scene={scene}
            />,
            document.getElementById("root"),
          )
        : null}

      {step === 4
        ? createPortal(
            <MusicGeneration
              story={story}
              scene={scene}
              onChange={(url) => {
                return fetch(url)
                  .then((resp) => resp.blob())
                  .then((blob) => {
                    return uploadFile("SONG_AUDIO", blob, console.log);
                  })
                  .then((data) => {
                    onChange({ key: data.key, url: data.url });
                    setStep(0);
                  })
                  .catch((err) => {
                    captureException(err);
                  });
              }}
              onCancel={() => setStep(1)}
            />,
            document.getElementById("root"),
          )
        : null}
    </div>
  );
};

const CREATE_SONG = gql`
  mutation CreateSong($input: CreateSongInput!) {
    createSong(input: $input) {
      id
      audio {
        key
        url
      }
    }
  }
`;
