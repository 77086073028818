import React, { Fragment } from "react";
import { Placeholder } from "components";
import EntityForm from "./EntityForm";

export default function Inspector({
  entities,
  entity,
  scene,
  scenes,
  setActiveEntityID,
  onEntityClick,

  updateEntity,
  deleteActiveEntity,
}) {
  function handleSubmit(values, { action }) {
    updateEntity(entity.id, values);
    setActiveEntityID(null);
  }

  function handleDelete() {
    if (window.confirm(`Are you sure to delete ${entity.title}?`)) {
      deleteActiveEntity();
    }
  }

  return (
    <Fragment>
      {entity ? (
        <EntityForm
          entity={entity}
          scene={scene}
          scenes={scenes}
          setActiveEntityID={setActiveEntityID}
          onSubmit={handleSubmit}
          onDelete={handleDelete}
        />
      ) : (
        <Fragment>
          <div className="divide-y border-b">
            {entities.map((entity) => (
              <div
                key={entity.id}
                className="flex items-center p-2 cursor-pointer hover:bg-gray-200"
                onClick={() => onEntityClick(entity)}
              >
                <div className="flex-1">{entity.title}</div>
                <div className="text-xs rounded px-1 py-0.5 bg-gray-200 uppercase">
                  {entity.type === "scene" ? "Tour Button" : entity.type}
                </div>
              </div>
            ))}
          </div>

          <div className="w-[200px] mx-auto">
            <Placeholder title="Double click on spherical panorama to add new object" />
          </div>
        </Fragment>
      )}
    </Fragment>
  );
}
