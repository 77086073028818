AFRAME.registerComponent("canvas-material", {
  schema: {
    width: {
      type: "int",
      default: 0,
    },
    height: {
      type: "int",
      default: 0,
    },
  },

  update: function () {
    if (!this.canvas) {
      this.canvas = document.createElement("canvas");
    }
    this.canvas.width = this.data.width;
    this.canvas.height = this.data.height;
    this.getContext = () => {
      var ctx = this.canvas.getContext("2d");
      return ctx;
    };
    this.updateTexture = function () {
      texture.needsUpdate = true;
    };
    var texture = new THREE.Texture(this.canvas);
    var material = new THREE.MeshBasicMaterial({
      map: texture,
      transparent: true,
    });
    //HACK:adding timeout because child[0] not immediately available
    setTimeout(() => {
      this.el.object3D.children[0].material = material;
    }, 100);
  },
});
