import React from "react";
import clsx from "clsx";

export default function Container({ className, fluid, ...props }) {
  return (
    <div
      className={clsx(
        "max-w-full w-[640px] mx-auto px-3",
        fluid ? "w-full" : "",
        className
      )}
      {...props}
    />
  );
}

Container.defaultProps = {
  fluid: false,
};
