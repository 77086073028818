import styles from "./EntityPreview.module.scss";
import React from "react";
import Modal from "react-modal";
import CloseIcon from "src/icons/close.svg";
import { Audio } from "components";

export default function EntityPreview({
  entity,
  width,
  height,
  setActiveEntityID,
}) {
  return (
    <Modal
      isOpen={entity ? true : false}
      className={styles.modal}
      overlayClassName={styles.overlay}
      style={{
        overlay: {
          width,
          height,
        },
      }}
    >
      <div className={styles.close} onClick={() => setActiveEntityID(null)}>
        <CloseIcon width="30" color="#ffffff" />
      </div>

      {entity ? (
        <div className={styles.content}>
          {entity.text ? (
            <div className={styles.text}>{entity.text}</div>
          ) : entity.imageURL ? (
            <img src={entity.imageURL} />
          ) : entity.audioURL ? (
            <Audio src={entity.audioURL} volume={entity.volume} autoPlay />
          ) : null}
        </div>
      ) : null}
    </Modal>
  );
}
