import styles from "./Upload.module.scss";
import React, { Fragment } from "react";

const Upload = ({ inputProps, children, component, ...props }) => {
  return React.createElement(
    component,
    {
      className: styles.upload,
      ...props,
    },
    <Fragment>
      {children}
      <input {...inputProps} type="file" className={styles.input} />
    </Fragment>
  );
};

Upload.defaultProps = {
  component: "span",
};

export default Upload;
