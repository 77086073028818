import React from "react";
import { gql, useQuery } from "@apollo/client";
import { Loading, Table, Link } from "components";
import { useAnalyticsPage } from "src/analytics";
import { truncate } from "lodash-es";

export default function Page({ params: { id } }) {
  const { loading, data, error } = useQuery(QUERY, { variables: { id: id } });
  useAnalyticsPage("Assignment Submissions", { assignment_id: id });

  if (loading) return <Loading />;
  if (error) return "Network error";

  return (
    <Table
      keyExtractor={(row) => row.id}
      data={data.assignment.stories}
      columns={[
        { key: "user.name", title: "Name" },
        { key: "user.email", title: "Email" },
        {
          key: "title",
          title: "Title",
          render: (row) => (
            <Link href={`/watch?id=${row.id}`}>{row.title}</Link>
          ),
        },
        {
          key: "script",
          title: "Script",
          render: (row) =>
            truncate(row.script, { length: 10, omission: "..." }),
        },
        { key: "submittedAt", title: "SubmittedAt" },
      ]}
    />
  );
}

const QUERY = gql`
  query Assignment($id: ID!) {
    assignment(id: $id) {
      id
      title
      details
      stories {
        id
        title
        script
        submittedAt
        user {
          id
          name
          email
        }
      }
    }
  }
`;
