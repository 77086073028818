import "./output.css";
import "./global.scss";
import React from "react";
import { createRoot } from "react-dom/client";
import { gql, ApolloProvider } from "@apollo/client";
import { AuthProvider } from "@swiftcarrot/react-auth";
import { client } from "src/client";
import Modal from "react-modal";
import * as Sentry from "@sentry/react";
import { captureException } from "src/sentry";
import { analytics } from "./analytics";
import { Toaster } from "components";
// import Routes from "/Users/zuowang/go/src/github.com/swiftcarrot/react-router/src/loader?debug=true!src/routes";
// eslint-disable-next-line import/no-webpack-loader-syntax
import Routes from "@swiftcarrot/react-router/src/loader!src/routes";

Modal.setAppElement("#root");

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn: "https://6ba859ddbdfe4b9882e1623266310813@o1052063.ingest.sentry.io/6120340",
  });
}

function getCurrentUser() {
  if (!localStorage.getItem("token")) {
    return Promise.resolve(null);
  }

  return client
    .query({
      query: gql`
        query Viewer {
          viewer {
            id
            name
            email
            isAdmin
            avatar {
              key
              url
            }
          }
        }
      `,
    })
    .then((resp) => {
      const { viewer } = resp.data;

      analytics.identify(viewer.id);

      return viewer;
    })
    .catch((err) => {
      captureException(err);

      // TODO: only clear on 400
      if (err.networkError && err.networkError.statusCode === 401) {
        localStorage.removeItem("token");
        // window.location.href = `/login?redirect=${window.location.pathname}`;
        window.location.reload();
      }
    });
}

function App() {
  return (
    <ApolloProvider client={client}>
      <AuthProvider getCurrentUser={getCurrentUser}>
        <Routes />
        <Toaster position="bottom-center" />
      </AuthProvider>
    </ApolloProvider>
  );
}

const root = createRoot(document.getElementById("root"));
root.render(<App />);
