import styles from "./Statistics.module.scss";
import React from "react";
import { gql, useQuery } from "@apollo/client";
import { cx, Loading, Progress } from "components";
import { isNumber, flatten } from "lodash-es";

export default function ClassProgress({ story, ...props }) {
  const { data, loading, error } = useQuery(QUERY, {
    variables: { id: story.id },
  });

  // TODO: move to below
  if (loading) return <Loading />;
  if (error) return <div>Network error</div>;

  const entities = flatten(data.story.scenes.map((d) => d.entities));
  const hasScript = data.story.script.length > 0;
  const hasMusic = data.story.scenes.filter((d) => d.musicURL).length > 0;
  const hasNarration =
    data.story.scenes.filter((d) => d.narrationURL).length > 0;
  const hasImage = data.story.scenes.filter((d) => d.imageURL).length > 0;
  const list = [
    {
      title: "Upload a spherical panorama",
      progress: data.story.assignment.stats.progress1 * 100,
      you: hasImage,
      average: "",
    },
    {
      title: "Input a narration script",
      progress: data.story.assignment.stats.progress2 * 100,
      you: hasScript,
      average: "",
    },
    {
      title: "Add music audio",
      progress: data.story.assignment.stats.progress3 * 100,
      you: hasMusic,
      average: "",
    },
    {
      title: "Add narration audio",
      progress: data.story.assignment.stats.progress4 * 100,
      you: hasNarration,
      average: "",
    },
    {
      title: "Add story scenes",
      you: story.scenes.length,
      average: data.story.assignment.stats.average1,
    },
    {
      title: "Add media objects",
      you: entities.filter((d) => d.type === "media").length,
      average: data.story.assignment.stats.average2,
    },
    {
      title: "Add tour buttons",
      you: entities.filter((d) => d.type === "scene").length,
      average: data.story.assignment.stats.average3,
    },
  ];

  return (
    <div className="px-3">
      <table className={styles.table}>
        <thead>
          <tr>
            <th className="text-left">Components</th>
            <th width="330" className="text-center">
              Class progess
            </th>
            <th width="70" className="text-center">
              You
            </th>
          </tr>
        </thead>
        <tbody>
          {list.map((d, index) => (
            <tr key={index}>
              <td>{d.title}</td>
              <td>
                {isNumber(d.progress) ? (
                  <Progress
                    percentage={d.progress}
                    className={styles.progress}
                    showPercentage
                  />
                ) : (
                  <div>
                    <div className={styles.average}>
                      Average: {d.average.toFixed(2)}
                    </div>
                  </div>
                )}
              </td>
              <td
                className={cx(
                  "text-center",
                  d.you ? "" : "text-red-500 font-semibold"
                )}
              >
                {isNumber(d.you) ? d.you : d.you ? "Done" : "Not yet"}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

const QUERY = gql`
  query Story($id: ID!) {
    story(id: $id) {
      id
      script
      scenes {
        id
        musicURL
        narrationURL
        imageURL
        entities {
          id
          type
        }
      }
      assignment {
        id
        stats {
          progress1
          progress2
          progress3
          progress4
          average1
          average2
          average3
        }
      }
    }
  }
`;
