import React from "react";
import { gql, useQuery } from "@apollo/client";
import { Loading, Table, Link } from "components";
import { useAnalyticsPage } from "src/analytics";

export default function Page({ params: { id } }) {
  const { loading, data, error } = useQuery(QUERY, { variables: { id: id } });
  useAnalyticsPage("Assignment Reviews", { assignment_id: id });

  if (loading) return <Loading />;
  if (error) return "Network error";

  return (
    <Table
      keyExtractor={(row) => row.id}
      data={data.assignment.reviews}
      columns={[
        { key: "user.name", title: "Review Author Name" },
        { key: "user.email", title: "Review Author Email" },
        {
          key: "story.title",
          title: "Story Title",
          render: ({ story }) => (
            <Link href={`/watch?id=${story.id}`}>{story.title}</Link>
          ),
        },
        { key: "story.user.name", title: "Story Author Name" },
        { key: "story.user.email", title: "Story Author Email" },
        { key: "content.rating1", title: "Content Rating" },
        { key: "content.comment1", title: "Content Comments" },
        { key: "content.rating2", title: "Knowledge Application Rating" },
        { key: "content.comment2", title: "Knowledge Application Comments" },
        { key: "content.rating3", title: "Delivery Rating" },
        { key: "content.comment3", title: "Delivery Comments" },
        { key: "content.rating4", title: "Mechanics Rating" },
        { key: "content.comment4", title: "Mechanics Comments" },
        { key: "content.rating5", title: "Overall Rating" },
        { key: "content.comment5", title: "Overall Comments" },
        { key: "createdAt", title: "CreatedAt" },
      ]}
    />
  );
}

const QUERY = gql`
  query Assignment($id: ID!) {
    assignment(id: $id) {
      id
      title
      details
      reviews {
        id
        user {
          id
          name
          email
        }
        story {
          id
          title
          user {
            id
            name
            email
          }
        }
        content {
          rating1
          comment1
          rating2
          comment2
          rating3
          comment3
          rating4
          comment4
          rating5
          comment5
        }
        createdAt
      }
    }
  }
`;
