import React, { useEffect, useCallback, useRef } from "react";
import { isNumber } from "lodash-es";
import { useAudio } from "react-use";
import { useButton } from "react-aria";
import {
  PlayCircle,
  PauseCircle,
  VolumeHigh,
} from "@swiftcarrot/react-ionicons";

export function Audio({ volume, onVolumeChange, ...props }) {
  props.controls = false; // disable default controls
  const [audio, state, controls, ref] = useAudio(props); // TODO: volume
  const shouldTrigger = useRef(false);

  const handleVolumeChange = useCallback(() => {
    const audio = ref.current;
    if (onVolumeChange && shouldTrigger.current) {
      onVolumeChange(audio.volume);
    }
  }, [onVolumeChange, ref, shouldTrigger]);

  useEffect(() => {
    const audio = ref.current;
    if (isNumber(volume)) {
      audio.volume = volume;
      setTimeout(() => {
        shouldTrigger.current = true;
      }, 10);
    } else {
      shouldTrigger.current = true;
    }
  }, [volume, ref]);

  useEffect(() => {
    const audio = ref.current;
    audio.addEventListener("volumechange", handleVolumeChange);
    return () => {
      audio.removeEventListener("volumechange", handleVolumeChange);
    };
  }, [handleVolumeChange, ref]);

  return (
    <div className="bg-gray-200 p-1 flex items-center rounded">
      {audio}

      {state.paused ? (
        <Button onPress={controls.play}>
          <PlayCircle className="ionicon fill-current w-6 h-6" />
        </Button>
      ) : (
        <Button onPress={controls.pause}>
          <PauseCircle className="ionicon fill-current w-6 h-6" />
        </Button>
      )}

      <div className="flex items-center text-xs">
        <div className="w-[30px] text-right">
          {formatDuration(Math.round(state.time))}
        </div>
        <div className="mx-1">/</div>
        <div className="w-[30px] text-left">
          {formatDuration(Math.round(state.duration))}
        </div>
      </div>

      <input
        type="range"
        className="flex-1"
        min="0"
        max={state.duration}
        value={state.time}
        onChange={(e) => {
          controls.seek(e.target.value);
        }}
      />
      <VolumeHigh className="ionicon fill-current w-5 h-5 mx-1" />
      <input
        type="range"
        className="w-[40px]"
        min="0"
        max="1"
        step="0.01"
        value={state.volume}
        onChange={(e) => {
          controls.volume(e.target.value);
        }}
      />
    </div>
  );
}

const formatDuration = (duration) => {
  const mins = parseInt(duration / 60, 10);
  let seconds = duration - mins * 60;
  if (seconds < 10) seconds = `0${seconds}`;

  return `${mins}:${seconds}`;
};

// TODO: Button with react-aria?
const Button = (props) => {
  let ref = props.buttonRef;
  let { buttonProps } = useButton(props, ref);

  return (
    <button {...buttonProps} ref={ref} style={props.style}>
      {props.children}
    </button>
  );
};
