import styles from "./AvatarInput.module.scss";
import React, { useState, useRef } from "react";
import Upload from "./Upload";
import Dialog from "./Dialog";
import Editor from "./Editor";
import { Button } from "components";
import { getImageDataURL } from "./utils";

export default function AvatarInput({
  value,
  onChange,
  renderAvatar,
  uploadAvatar,
  editor,
  render,
  allowImageLink,
}) {
  const [image, setImage] = useState(null);
  const [open, setOpen] = useState(false);
  const [scale, setScale] = useState(editor.scale[1]);
  const [saving, setSaving] = useState(false);
  const crop = useRef();

  function close() {
    setOpen(false);
    setSaving(false);
  }

  function save() {
    const canvas = crop.current.getImageScaledToCanvas();

    if (saving) {
      return;
    }

    setSaving(true);
    return canvas.toBlob((blob) => {
      return uploadAvatar(blob)
        .then((value) => {
          onChange(value);
          setSaving(false);
          setOpen(false);
        })
        .catch((err) => {
          console.error(err);
          setSaving(false);
          alert("Save failure");
        });
    });
  }

  function handleFileChange(e) {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onload = (e) => {
      try {
        setImage(e.target.result);
        setOpen(true);
      } catch (err) {
        console.error(err);
        alert("Load failure");
      }
    };
    reader.readAsDataURL(file);
  }

  function loadImage() {
    const link = window.prompt("输入网络图片链接");

    if (link) {
      return getImageDataURL(link)
        .then((image) => {
          setImage(image);
          setOpen(true);
        })
        .catch((err) => {
          console.error(err);
          alert("无法加载图片");
        });
    }
  }

  return (
    <div>
      <Upload
        inputProps={{
          type: "file",
          accept: "image/*",
        }}
        style={{ display: "block" }}
        onChange={handleFileChange}
      >
        {render({ value })}
      </Upload>

      {allowImageLink ? (
        <div>
          <Button type="button" onClick={loadImage}>
            点击选择网络图片
          </Button>
        </div>
      ) : null}

      <Dialog
        saving={saving}
        isOpen={open}
        onRequestClose={close}
        onClose={close}
      >
        {image ? (
          <Editor
            ref={crop}
            {...editor}
            image={image}
            scale={scale}
            rotate={0}
            style={{
              border: "1px solid #eaeaea",
              borderRadius: 4,
            }}
          />
        ) : null}

        <input
          style={{
            display: "block",
            marginTop: 20,
            marginBottom: 20,
            width: 350,
          }}
          type="range"
          min={editor.scale[0]}
          max={editor.scale[2]}
          value={scale}
          onChange={(e) => setScale(parseFloat(e.target.value))}
          step="0.01"
        />

        {/*<button onClick={() => setScale(editor.scale[1])}>reset</button>*/}

        <div className={styles.submit}>
          <Button
            variant="primary"
            onClick={save}
            className={styles.submitButton}
          >
            {saving ? "Uploading..." : "Upload"}
          </Button>
        </div>
      </Dialog>
    </div>
  );
}

AvatarInput.defaultProps = {
  allowImageLink: false,
};
