import styles from "./Dialog.module.scss";
import React from "react";
import Modal from "react-modal";

export default function Dialog({
  title,
  children,
  onSave,
  onClose,
  saving,
  ...props
}) {
  return (
    <Modal
      className={styles.content}
      overlayClassName={styles.overlay}
      appElement={
        typeof document !== "undefined" ? document.getElementById("root") : null
      }
      {...props}
    >
      <div className={styles.header}>
        <div className={styles.title}>{title}</div>
        <button onClick={onClose} className={styles.close}>
          &times;
        </button>
      </div>
      <div className={styles.body}>{children}</div>
    </Modal>
  );
}

Dialog.defaultProps = {
  title: "Crop Image",
};
