import React, { useState, useEffect, Fragment } from "react";
import { isEmpty } from "lodash-es";
import clsx from "clsx";
import { Loading, Placeholder } from "components";
import { SearchResult } from "./SearchResult";
import { getScriptTags } from "ccch9051/src/text";
import { analytics } from "ccch9051/src/analytics";
import { captureException } from "ccch9051/src/sentry";

export const Recommendation = ({ story, scene, onChange }) => {
  const [{ loading, data, error }, setState] = useState({
    loading: true,
    data: null,
    error: null,
  });
  const [selected, setSelected] = useState({});

  const toggleTag = ({ key, category }) => {
    if (selected[key]) {
      const { [key]: _, ...newSelected } = selected;
      setSelected(newSelected);

      analytics.track("BGM Recommendation Unselect Tag", {
        story_id: story.id,
        scene_id: scene.id,
        tag: { key, category },
      });
    } else {
      setSelected({ ...selected, [key]: category });
      analytics.track("BGM Recommendation Select Tag", {
        story_id: story.id,
        scene_id: scene.id,
        tag: { key, category },
      });
    }
  };

  const params = {
    q: "",
    genres: new Set(
      Object.keys(selected).filter((k) => selected[k] === "genre")
    ),
    moods: new Set(Object.keys(selected).filter((k) => selected[k] === "mood")),
    instruments: new Set(
      Object.keys(selected).filter((k) => selected[k] === "instrument")
    ),
    themes: new Set(
      Object.keys(selected).filter((k) => selected[k] === "theme")
    ),
  };

  useEffect(() => {
    getScriptTags(story.script)
      .then((data) => {
        analytics.track("BGM Recommendation Loaded", {
          story_id: story.id,
          scene_id: scene.id,
          data,
        });

        setState({ loading: false, data, error: null });
      })
      .catch((error) => {
        captureException(error);
      });
  }, [story]);

  return loading ? (
    <Loading />
  ) : error ? (
    <div>Network Error</div>
  ) : data.length ? (
    <Fragment>
      <div className="bg-sky-100 p-3 rounded mb-3">
        <div>
          Based on your narration script, the system recommends following{" "}
          <b>tags</b> for background music:
        </div>

        {data.map((d, i) => (
          <Suggestion key={i}>
            <div>
              {d.tags.map((tag) => (
                <SuggestionOption
                  key={tag.key}
                  onClick={() => toggleTag(tag)}
                  active={selected[tag.key]}
                >
                  {tag.category}: {tag.key}
                </SuggestionOption>
              ))}
              <i>{d.sentence}</i>
            </div>
          </Suggestion>
        ))}
      </div>
      {isEmpty(selected) ? (
        <Placeholder title="Click recommended tags to search music" />
      ) : (
        <SearchResult
          source="Recommendation"
          params={params}
          story={story}
          scene={scene}
          onChange={onChange}
        />
      )}
    </Fragment>
  ) : (
    <Placeholder title="No recommendation is generated for your narration script." />
  );
};

const Suggestion = ({ children }) => {
  return (
    <div className="flex my-2">
      <div className="flex-1">{children}</div>
    </div>
  );
};

Suggestion.defaultProps = {
  selected: false,
};

const SuggestionOption = ({ children, active, ...props }) => {
  return (
    <div
      className={clsx(
        "inline-block bg-white border rounded-full text-sm font-bold px-3 mr-1 cursor-pointer",
        active ? "bg-gray-300" : ""
      )}
      {...props}
    >
      {children}
    </div>
  );
};
