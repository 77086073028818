import React, { useState } from "react";
import { EllipseOutline, CheckmarkCircle } from "@swiftcarrot/react-ionicons";
import { gql, useMutation } from "@apollo/client";
import checklist from "./checklist.yml";
import { captureException } from "src/sentry";
import { analytics } from "src/analytics";

// TODO: flatten list
// TODO: recursive fix
checklist.forEach((list, i) => {
  list.id = i + 1 + "";
  if (list.tasks) {
    list.tasks.forEach((task, j) => {
      task.id = [list.id, j + 1].join("-");
      if (task.tasks) {
        task.tasks.forEach((sub, k) => {
          sub.id = [list.id, task.id, k + 1].join("-");
        });
      } else {
        task.tasks = [];
      }
    });
  } else {
    list.tasks = [];
  }
});

// TODO: use react-sidesheet?
// TODO: add animation for open toggle
export default function CheckList({ story, setStory }) {
  // TODO: load checklist in component
  const [checked, setChecked] = useState(story.checklist);
  const [updateStory] = useMutation(UPDATE_STORY);

  // TODO: improve this data structure
  function toggle(id) {
    return () => {
      const ck = checked[id] ? false : true;
      const updated = {
        ...checked,
        [id]: ck,
      };

      analytics.track(ck ? "Checklist Check" : "Checklist Uncheck", {
        story_id: story.id,
        key: id,
      });

      setChecked(updated);

      return updateStory({
        variables: { input: { id: story.id, checklist: updated } },
      })
        .then(() => {
          setStory({ ...story, checklist: updated });
        })
        .catch((err) => {
          captureException(err);
        });

      // TODO:
      // const list = findList(id);
      // if (list && ck) {
      //   list.tasks.forEach((task) => {
      //     updated[task.id] = true;
      //     task.subtasks.forEach((sub) => {
      //       updated[sub.id] = true;
      //     });
      //   });
      // }
    };
  }

  // TODO: add assignment due date
  return (
    <div className="flex flex-col p-3 space-y-3">
      {checklist.map((list) => (
        <div key={list.id} className="relative">
          <div className="flex mb-1 cursor-pointer" onClick={toggle(list.id)}>
            {list.tasks.length ? null : <Checkbox value={checked[list.id]} />}
            <div className="flex-1">
              <div className="font-semibold text-lg">{list.title}</div>
            </div>
          </div>
          <div>
            {list.tasks.map((task) => (
              <div key={task.id} className="pl-4">
                <div className="flex cursor-pointer" onClick={toggle(task.id)}>
                  {task.tasks.length ? null : (
                    <Checkbox value={checked[task.id]} />
                  )}
                  <div>{task.title}</div>
                </div>
                <div>
                  {task.tasks.map((sub) => (
                    <div
                      key={sub.id}
                      className="flex pl-4 cursor-pointer"
                      onClick={toggle(sub.id)}
                    >
                      <Checkbox size={20} value={checked[sub.id]} />
                      <div className="">{sub.title}</div>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
}

// TODO: move to components
function Checkbox({ size, value, onChange, ...props }) {
  return (
    <div className="mr-1" {...props}>
      {value ? (
        <CheckmarkCircle width={size} className="ionicon text-gray-700" />
      ) : (
        <EllipseOutline width={size} className="ionicon text-gray-700" />
      )}
    </div>
  );
}

Checkbox.defaultProps = {
  size: 25,
};

const UPDATE_STORY = gql`
  mutation UpdateStory($input: UpdateStoryInput!) {
    updateStory(input: $input) {
      id
    }
  }
`;
