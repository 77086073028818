import styles from "./Loading.module.scss";
import React from "react";

// TODO: use <Spinner />
export default function Loading() {
  return (
    <div className={styles.container}>
      <div className={styles.spinner}></div>
    </div>
  );
}
