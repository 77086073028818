import { useState, useEffect } from "react";
import { useWindowSize } from "react-use";
import { capitalize } from "lodash-es";
import { analytics } from "src/analytics";

// TODO: make me more smart and general
export const EDITOR_LAYOUT = {
  header: { height: 36 },
  left: [
    { key: "settings", title: "Settings", width: 380 },
    { key: "scenes", title: "Scenes", width: 240 },
    { key: "narration", title: "Narration Script", width: 560 },
    { key: "reflection", title: "Reflection", width: 660 },

    { key: "checklist", title: "Checklist", width: 560, la: true },
    { key: "progress", title: "Progress", width: 560, la: true },
    { key: "statistics", title: "Statistics", width: 560, la: true },
  ],
  right: [
    { key: "objects", title: "Objects", width: 350 },
    { key: "audio", title: "Audio Manager", width: 350 },
  ],
};

const EDITOR_LEFT_SIDEBAR = EDITOR_LAYOUT.left.map((d) => d.key);
const EDITOR_RIGHT_SIDEBAR = EDITOR_LAYOUT.right.map((d) => d.key);

const INITIAL_SIDEBAR = {
  settings: false,
  scenes: false,
  narration: false,
  reflection: false,
  checklist: false,
  progress: false,
  statistics: false,
  objects: false,
  audio: false,
};

export function useEditorLayout(story) {
  const { width: windowWidth, height: windowHeight } = useWindowSize();
  const [sidebar, setSidebar] = useState(INITIAL_SIDEBAR);
  const sidebarLeft = EDITOR_LAYOUT.left.filter((d) => sidebar[d.key])[0];
  const sidebarRight = EDITOR_LAYOUT.right.filter((d) => sidebar[d.key])[0];
  const sidebarLeftWidth = sidebarLeft ? sidebarLeft.width : 0;
  const sidebarRightWidth = sidebarRight ? sidebarRight.width : 0;
  const canvasWidth = windowWidth - sidebarLeftWidth - sidebarRightWidth;
  const headerHeight = EDITOR_LAYOUT.header.height;
  const canvasHeight = windowHeight - headerHeight;

  const openSidebar = (...keys) => {
    // TODO: fix events

    for (const key of keys) {
      if (!sidebar[key]) {
        toggleSidebar(key);
      }
    }
  };

  const toggleSidebar = (key) => {
    const event = `${sidebar[key] ? "Close" : "Open"} ${capitalize(key)}`;
    analytics.track(event, { story_id: story.id });

    const newSidebar = { ...sidebar };
    if (EDITOR_LEFT_SIDEBAR.indexOf(key) >= 0) {
      EDITOR_LEFT_SIDEBAR.forEach((k) => {
        newSidebar[k] = false;
      });
    } else {
      EDITOR_RIGHT_SIDEBAR.forEach((k) => {
        newSidebar[k] = false;
      });
    }
    setSidebar({ ...newSidebar, [key]: !sidebar[key] });
  };

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "";
    };
  }, []);

  return {
    windowWidth,
    windowHeight,
    canvasWidth,
    canvasHeight,
    headerHeight,
    sidebarLeftWidth,
    sidebarRightWidth,
    sidebar,
    setSidebar,
    toggleSidebar,
    openSidebar,
  };
}
