import React from "react";
import Upload, { formatProgress } from "./Upload";
import { CloudUploadOutline } from "@swiftcarrot/react-ionicons";

export default function EditorImageUpload({ scene, updateSceneImage }) {
  function handleChange(image) {
    updateSceneImage(scene, image);
  }

  return (
    <Upload
      className="w-full h-full bg-gray-200"
      type="SCENE_BACKGROUND_IMAGE"
      accept="image/png,image/jpg"
      onChange={handleChange}
    >
      {({ upload, progress }) => (
        <div className="w-full h-full flex items-center justify-center text-2xl font-semibold gap-4">
          <CloudUploadOutline className="ionicon w-12" />
          {progress ? (
            formatProgress(progress)
          ) : (
            <div>Click to upload spherical panorama</div>
          )}
        </div>
      )}
    </Upload>
  );
}
