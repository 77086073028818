import styles from "./Entity.module.scss";
import React from "react";
import cx from "clsx";

// TODO: require from src/images
const IMAGES = {
  text: "/images/plane_entity.png",
  image: "/images/plane_image.png",
  audio: "/images/plane_audio.png",
  scene: "/images/ball_tour_white.png",
};

// TODO: replace image icon with svg component
export default React.memo(function Entity({ entity, editing, ...props }) {
  return (
    <div
      {...props}
      className={cx(styles.container, { [styles.editing]: editing })}
    >
      <div className={styles.icon}>
        <img
          alt="pin"
          src={IMAGES[entity.type] || require("src/images/navigate-circle.svg")}
          draggable={false}
        />
      </div>
    </div>
  );
});
