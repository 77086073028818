import React from "react";
import { Button, Form, FormGroup, FormSubmit } from "components";

export default function AssignmentForm(props) {
  return (
    <Form {...props}>
      <FormGroup name="title" label="Title" />
      <FormGroup name="details" label="Details" multiline minRows={4} />
      {/* <FormGroup name="deadline" label="Deadline" type="date" /> */}
      <div className="form-submit">
        <FormSubmit component={Button} />
      </div>
    </Form>
  );
}
