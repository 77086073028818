import clsx from "clsx";
import React, { useLayoutEffect } from "react";
import { useScroll } from "react-use";

// interface ScrollViewProps extends HTMLAttributes<HTMLElement> {
//   children: ReactNode;
//   scrollDirection?: "horizontal" | "vertical" | "both";
//   onScrollStart?: () => void;
//   onScrollEnd?: () => void;

//   // TODO: https://reactnative.dev/docs/flatlist#onendreached
//   onEndReached?: (info: { distanceFromEnd: number }) => void;
//   onEndReachedThreshold?: number;
// }

// TODO: https://github.com/radix-ui/primitives/blob/main/packages/react/scroll-area/src/ScrollArea.tsx
export function ScrollView(props) {
  const scrollRef = React.useRef(null);
  const { x, y } = useScroll(scrollRef);
  let {
    children,
    className,
    onScrollStart,
    onScrollEnd,
    onEndReached,
    onEndReachedThreshold,
    scrollTop,
    ...otherProps
  } = props;

  useLayoutEffect(() => {
    const el = scrollRef.current;
    setTimeout(() => {
      el.scrollTop = 0;
    }, 0);
  }, [scrollTop]);

  // TODO: add state.contentSize(width, height)

  useLayoutEffect(() => {
    const el = scrollRef.current;
    if (onEndReached && el.scrollHeight - el.scrollTop - el.clientHeight < 10) {
      onEndReached();
    }
  }, [x, y]);

  return (
    <div className={clsx(className)} ref={scrollRef}>
      {children}
    </div>
  );
}

ScrollView.defaultProps = {
  onEndReachedThreshold: 0.5,
};

// const ScrollViewForwardRef = React.forwardRef(ScrollView);
// export { ScrollViewForwardRef as ScrollView };
