import styles from "./RatingInput.module.scss";
import React from "react";
import { range } from "lodash-es";
import cx from "clsx";

// TODO: tailwind
export default function RatingInput({ value, onChange, min, max }) {
  return (
    <div className={styles.container}>
      {range(min, max + 1).map((i) => (
        <div
          key={i}
          className={cx(styles.box, { [styles.active]: value === i })}
          onClick={() => onChange(i)}
        >
          {i}
        </div>
      ))}
    </div>
  );
}

RatingInput.defaultProps = {
  min: 1,
  max: 10,
};
