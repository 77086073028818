import React from "react";
import cx from "clsx";

export default function Progress({
  className,
  percentage,
  showPercentage,
  ...props
}) {
  return (
    <div
      className={cx(
        "w-full h-[17px] rounded bg-gray-200 overflow-hidden",
        className
      )}
      {...props}
    >
      <div
        className="flex items-center justify-center h-full bg-gray-700 text-white text-xs font-semibold"
        style={{ width: `${percentage}%` }}
      >
        {showPercentage && percentage > 0 ? `${percentage.toFixed(1)}%` : ""}
      </div>
    </div>
  );
}

Progress.defaultProps = {
  percentage: 50,
  showPercentage: false,
};
