import React, { useState } from "react";
import { Item, useAsyncList } from "react-stately";
import { facets } from "../utils";
import { SearchField } from "./TextField";
import { PopoverTrigger } from "./Popover";
import { Dialog } from "./Dialog";
import { ListBox } from "./ListBox";
import clsx from "clsx";
import { Loading } from "components";

const CATEGORIES = [
  { title: "Genre", key: "genres" },
  { title: "Mood", key: "moods" },
  { title: "Instrument", key: "instruments" },
  { title: "Theme", key: "themes" },
];

export const SearchInput = ({ params, setParams }) => {
  // TODO: use a global state library
  const [text, setText] = useState(params.q);

  // TODO: SearchField -> ComboBox
  // TODO: add search icon in search field
  // TODO: popover without overlay
  return (
    <div className="px-3 py-2 bg-gray-200">
      <div className="flex items-center">
        <SearchField
          placeholder="Search track by keywords"
          className="flex-1"
          value={text}
          onChange={setText}
          onSubmit={() => setParams({ ...params, q: text })}
        />

        {/* <label className="text-sm text-gray-900">
          <input type="checkbox" />
          &nbsp;Lyrics
        </label> */}
      </div>

      <div className="mt-2 space-y-2">
        {CATEGORIES.map((category) => (
          <div key={category.key} className="flex">
            <div className="text-sm w-[66px] text-right mr-3">
              {category.title}
            </div>
            <div className="flex flex-wrap gap-1 flex-1">
              <Tags
                selectedKeys={params[category.key]}
                setSelectedKeys={(selection) => {
                  setParams({ ...params, [category.key]: selection });
                }}
              />
              <PopoverTrigger
                label={"Add"}
                // isOpen={category.key === "genres"}
                className={clsx(
                  "bg-white rounded-full shadow-sm text-sm text-gray-900 px-3 py-1 cursor-pointer outline-none"
                )}
              >
                <Dialog>
                  <Panel
                    category={category}
                    params={params}
                    selectedKeys={params[category.key]}
                    setSelectedKeys={(selection) => {
                      setParams({ ...params, [category.key]: selection });
                    }}
                  />
                </Dialog>
              </PopoverTrigger>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const Tags = ({ selectedKeys, setSelectedKeys }) => {
  return selectedKeys.size > 0 ? (
    <React.Fragment>
      {Array.from(selectedKeys).map((tag) => (
        <div
          key={tag}
          className="bg-sky-100 text-sky-600 border-sky-600 text-sm rounded-full px-3 py-1"
        >
          {tag}
          <button
            onClick={() => {
              selectedKeys.delete(tag);
              setSelectedKeys(new Set(selectedKeys));
            }}
            className="w-[14px]"
          >
            &times;
          </button>
        </div>
      ))}
      {selectedKeys.size > 1 ? (
        <button
          className="text-gray-400 bg-white rounded-full px-3 py-1 text-sm border"
          onClick={() => {
            setSelectedKeys(new Set());
          }}
        >
          Clear all
        </button>
      ) : null}
    </React.Fragment>
  ) : null;
};

const Panel = ({ category, params, selectedKeys, setSelectedKeys }) => {
  let list = useAsyncList({
    async load({ signal, filterText, selectedKeys }) {
      let res = await facets(category.key, {
        q: params.q,
        genres: Array.from(params.genres),
        moods: Array.from(params.moods),
        instruments: Array.from(params.instruments),
        themes: Array.from(params.themes),
      });
      return {
        items: res.facets,
      };
    },
  });

  // TODO: combobox
  return (
    <div className="shadow bg-white rounded w-[250px]">
      {/* <div className="px-3 py-1 flex border-b">
        <input
          type="text"
          placeholder={`Search ${category.key}`}
          className="text-sm text-gray-900 outline-none flex-1"
        />
        <button onClick={close} className="font-semibold">
          Done
        </button>
      </div> */}

      {list.isLoading ? (
        <Loading />
      ) : (
        <>
          <div className="max-h-[400px] overflow-y-auto divide-y">
            <ListBox
              aria-label="facets"
              items={list.items.filter((item) => !selectedKeys.has(item.value))}
              selectionMode="multiple"
              selectedKeys={selectedKeys}
              onSelectionChange={setSelectedKeys}
            >
              {(item) => (
                <Item key={item.value}>
                  <div className="flex-1">{item.value}</div>
                  <div className=" text-gray-400">{item.count}</div>
                </Item>
              )}
            </ListBox>
          </div>
        </>
      )}
    </div>
  );
};
