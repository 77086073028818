import React from "react";

export default function Placeholder({ title, children }) {
  return (
    <div className="flex min-h-[150px] justify-center items-center">
      {title ? <div className="text-center text-gray-500">{title}</div> : null}
      {children}
    </div>
  );
}
