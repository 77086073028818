import React from "react";
import { gql, useMutation } from "@apollo/client";
import { useLocation, useSearchParams } from "@swiftcarrot/react-router";
import { Form, FormSubmit, FormGroup, Button } from "components";
import { Container, Card } from "components";
import { captureException } from "src/sentry";
import { analytics, useAnalyticsPage } from "src/analytics";

export default function Page() {
  const [, navigate] = useLocation();
  const [createStory] = useMutation(CREATE_STORY);
  const [params] = useSearchParams();
  const assignmentID = params.get("assignment_id");

  useAnalyticsPage("New Story");

  function handleSubmit(values) {
    return createStory({ variables: { input: { ...values, assignmentID } } })
      .then((resp) => {
        analytics.track("Create Story", { story_id: resp.data.createStory.id });
        navigate(`/editor?id=${resp.data.createStory.id}`, { replace: true });
      })
      .catch((err) => {
        alert("Network error");
        captureException(err);
      });
  }

  function validate(values) {
    const errors = {};
    if (!values.title.trim()) {
      errors.title = "Story title cannot be blank";
    }
    return errors;
  }

  return (
    <Container>
      <Card title="Create New Story">
        <Form
          validate={validate}
          initialValues={{ title: "" }}
          onSubmit={handleSubmit}
        >
          <FormGroup name="title" label="Story Title" />
          {/* <FormGroup name="location" label="Location" component={LocationInput} /> */}
          <div className="form-submit">
            <FormSubmit component={Button} variant="primary">
              Create Story
            </FormSubmit>
          </div>
        </Form>
      </Card>
    </Container>
  );
}

const CREATE_STORY = gql`
  mutation CreateStory($input: CreateStoryInput!) {
    createStory(input: $input) {
      id
    }
  }
`;
