import React from "react";
import { Form, FormGroup, FormSubmit, Button } from "components";
import AvatarInput from "react-avatar-input/src/AvatarInput";
import { uploadFile } from "src/utils";
import UserAvatar from "src/components/UserAvatar";

export default function ProfileForm(props) {
  function validate(values) {
    const errors = {};
    if (!values.name.trim()) {
      errors.name = "Name cannot be blank";
    }
    return errors;
  }

  return (
    <Form {...props} validate={validate}>
      <FormGroup
        name="avatar"
        label="Avatar"
        component={AvatarInput}
        uploadAvatar={(file) => uploadFile("USER_AVATAR", file)}
        render={({ value }) => (
          <div>
            <div>Click to select image</div>
            {value ? (
              <div>
                <UserAvatar user={{ avatar: value }} />
              </div>
            ) : null}
          </div>
        )}
        editor={{
          width: 250,
          height: 250,
          border: 50,
          borderRadius: 125,
          color: [0, 0, 0, 0.5],
          scale: [0.1, 1, 2],
        }}
      />

      <FormGroup name="name" label="Name" />
      <FormGroup
        name="email"
        label="Email"
        disabled
        tips="Please contact us to update your account email"
      />
      <div className="form-submit">
        <FormSubmit component={Button}>Update Profile</FormSubmit>
      </div>
    </Form>
  );
}
