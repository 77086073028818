import styles from "./Button.module.scss";
import cx from "clsx";
import React from "react";

export const FileUploaderButton = ({
  variant,
  className,
  children,
  ...props
}) => {
  return (
    <label className={cx(styles.button, styles[variant], className)}>
      {children}
      <input type="file" className="hidden" {...props} />
    </label>
  );
};

FileUploaderButton.defaultProps = {
  variant: "primary",
};

export const FileUploaderZone = ({ chidren, className, ...props }) => {
  return <div className={cx(className)}>{chidren}</div>;
};
