import React from "react";
import { useSearchParams } from "@swiftcarrot/react-router";
import Editor from "src/components/Editor";
import { gql, useQuery } from "@apollo/client";
import { Loading, Placeholder } from "components";
import { useAuth } from "@swiftcarrot/react-auth";
import { useAnalyticsPage } from "src/analytics";

export default function Page() {
  const { currentUser } = useAuth();
  const [params] = useSearchParams();
  const id = params.get("id");
  const { data, loading, error } = useQuery(GET_STORY, { variables: { id } });

  useAnalyticsPage("Editor", { story_id: id });

  if (loading) return <Loading />;
  if (error) {
    return <div>Network Error</div>;
  }

  if (data.story.submittedAt) {
    return <Placeholder title="Already submitted" />;
  }

  if (data.story.user.id !== currentUser.id) {
    return <Placeholder title="Not found" />;
  }

  return (
    <Editor
      story={data.story}
      laEnabled={data.story.assignment.classroom.viewerHasLaEnabled}
    />
  );
}

const GET_STORY = gql`
  query Story($id: ID!) {
    story(id: $id) {
      id
      title
      script
      isPublished
      isShowcase
      checklist
      submittedAt
      user {
        id
      }
      assignment {
        id
        title
        classroom {
          id
          name
          viewerHasLaEnabled
          viewer {
            id
            role
          }
        }
      }
      scenes {
        id
        isHome
        imageURL
        musicURL
        musicVolume
        musicCredit
        narrationURL
        narrationVolume
        entities {
          id
          type
          title
          point {
            x
            y
            z
          }
          text
          image {
            key
            url
          }
          audio {
            key
            url
          }
          scene
          volume
        }
      }
    }
  }
`;
