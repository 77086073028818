import React from "react";
import { gql, useQuery, useMutation } from "@apollo/client";
import {
  Container,
  Link,
  Loading,
  TextInput,
  PageHeader,
  Placeholder,
  Table,
} from "components";
import AssignmentForm from "src/components/AssignmentForm";
import GroupForm from "src/components/GroupForm";
import { captureException } from "src/sentry";
import cx from "clsx";
import { useSearchParams } from "@swiftcarrot/react-router";
import { analytics, useAnalyticsPage } from "src/analytics";

const JOIN_URL = "https://lavrplatform.com/register"; // TODO: env
const TABS = [
  { key: "assignments", title: "Assignments" },
  { key: "students", title: "Students" },
  { key: "admins", title: "Admins" },
  { key: "groups", title: "Groups" },
  { key: "settings", title: "Settings" },
];

export default function Page({ params: { id } }) {
  const { loading, data, error } = useQuery(QUERY, { variables: { id } });
  const [createAssignment] = useMutation(CREATE_ASSIGNMENT);
  const [createGroup] = useMutation(CREATE_GROUP);
  const [params] = useSearchParams();
  useAnalyticsPage("Classroom Admin", { classroom_id: id });
  const tab = params.get("tab") || "assignments";
  // TODO: check isAdmin
  const isAdmin = data ? data.classroom.viewer.role === "ADMIN" : false;

  function handleSubmit(values) {
    return createAssignment({
      variables: { input: { ...values, classroomID: data.classroom.id } },
    })
      .then((resp) => {
        window.location.reload();
      })
      .catch((err) => {
        captureException(err);
      });
  }

  function handleGroupSubmit(values) {
    return createGroup({
      variables: { input: { ...values, classroomID: data.classroom.id } },
    })
      .then((resp) => {
        analytics.track("Create Group", { group_id: resp.data.createGroup.id });
        window.location.reload();
      })
      .catch((err) => {
        captureException(err);
      });
  }

  if (loading) return <Loading />;
  if (error) return "error";
  if (!isAdmin) return null; // TODO: access control on frontend

  return (
    <Container>
      <PageHeader
        title={data.classroom.name}
        subtitle={data.classroom.details}
      />

      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginBottom: 20,
        }}
      >
        <div style={{ marginRight: 15, flex: 1, whiteSpace: "nowrap" }}>
          Classroom invitation link
        </div>
        <TextInput value={`${JOIN_URL}?token=${data.classroom.token}`} />
      </div>

      <div
        style={{
          borderLeft: "1px solid #eeeeee",
          borderRight: "1px solid #eeeeee",
          borderTop: "1px solid #eeeeee",
        }}
      >
        <div className="tabs">
          {TABS.map((d) => (
            <div
              key={d.key}
              className={cx("tab", { tabActive: tab === d.key })}
              onClick={() => {
                window.location.href = `/admin/classrooms/${id}?tab=${d.key}`;
              }}
            >
              {d.title}
            </div>
          ))}
        </div>
      </div>

      {tab === "students" ? (
        <div>
          <Table
            keyExtractor={(row) => row.id}
            data={data.classroom.users.filter((user) => user.role === "MEMBER")}
            columns={[
              { key: "user.name", title: "Name" },
              { key: "user.email", title: "Email" },
              {
                key: "user.groupUsers",
                title: "Groups",
                render({ user }) {
                  return user.groupUsers
                    .map(({ group }) => group.name)
                    .join(",");
                },
              },
            ]}
          />
        </div>
      ) : tab === "groups" ? (
        <div>
          <Table
            keyExtractor={(row) => row.id}
            data={data.classroom.groups}
            columns={[
              { key: "name", title: "Name" },
              { key: "details", title: "Details" },
              {
                key: "canReview",
                title: "Can Review",
                render: (row) => (row.canReview ? "Yes" : "No"),
              },
              {
                key: "laEnabled",
                title: "LA Enabled",
                render: (row) => (row.laEnabled ? "Yes" : "No"),
              },
            ]}
          />
          <h2>Create Group</h2>
          <GroupForm
            initialValues={{ name: "", canReview: false }}
            onSubmit={handleGroupSubmit}
          />
        </div>
      ) : tab === "assignments" ? (
        <div>
          {data.classroom.assignments.length ? (
            <Table
              keyExtractor={(row) => row.id}
              data={data.classroom.assignments}
              columns={[
                { key: "title", title: "Title" },
                { key: "details", title: "Details" },
                {
                  key: "submissions",
                  title: "Submissions",
                  render(row) {
                    return (
                      <Link href={`/admin/assignments/${row.id}/submissions`}>
                        Submissions
                      </Link>
                    );
                  },
                },
                {
                  key: "stories",
                  title: "Story Assets",
                  render(row) {
                    return (
                      <Link href={`/admin/assignments/${row.id}/stories`}>
                        Stories
                      </Link>
                    );
                  },
                },
              ]}
            />
          ) : null}
          <h2>Create Assignment</h2>
          <AssignmentForm
            initialValues={{ title: "", details: "" }}
            onSubmit={handleSubmit}
          />
        </div>
      ) : tab === "admins" ? (
        <div>
          <Table
            keyExtractor={(row) => row.id}
            data={data.classroom.users.filter((user) => user.role === "ADMIN")}
            columns={[
              { key: "user.name", title: "Name" },
              { key: "user.email", title: "Email" },
            ]}
          />
        </div>
      ) : tab === "settings" ? (
        <Placeholder>Classroom Settings</Placeholder>
      ) : null}
    </Container>
  );
}

const QUERY = gql`
  query ClassRoom($id: ID!) {
    classroom(id: $id) {
      id
      name
      details
      createdAt
      token
      viewer {
        id
        role
      }
      assignments {
        id
        title
        details
      }
      groups {
        id
        name
        details
        canReview
        laEnabled
      }
      users {
        id
        role
        createdAt
        user {
          id
          name
          email
          groupUsers(classroomID: $id) {
            group {
              id
              name
            }
          }
        }
      }
    }
  }
`;

const CREATE_ASSIGNMENT = gql`
  mutation CreateAssignment($input: CreateAssignmentInput!) {
    createAssignment(input: $input) {
      id
    }
  }
`;

const CREATE_GROUP = gql`
  mutation CreateGroup($input: CreateGroupInput!) {
    createGroup(input: $input) {
      id
    }
  }
`;
