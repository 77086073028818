import React, { useRef, useEffect } from "react";
import { useSearchParams } from "@swiftcarrot/react-router";
import { useAuth } from "@swiftcarrot/react-auth";
import { Loading, Placeholder } from "components";
import { gql, useQuery } from "@apollo/client";
import { Player } from "player";
import { useAnalyticsPage } from "src/analytics";

export default function Page() {
  const { loading: authLoading, currentUser } = useAuth();
  const [params] = useSearchParams();
  const id = params.get("id");
  const { data, loading, error } = useQuery(GET_STORY, { variables: { id } });

  useAnalyticsPage("Watch", { story_id: id });

  // TODO: loading and player splash
  if (authLoading || loading) return <Loading />;
  if (error) return <Placeholder title="Network Error" />;

  if (!currentUser && !data.story.isShowcase) {
    return <Placeholder title="Story not found" />;
  }

  return <LAVRPlayer story={data.story} />;
}

const LAVRPlayer = ({ story }) => {
  const div = useRef();

  useEffect(() => {
    if (div.current) {
      window._player = new Player(div.current, story);
    }
  }, [div, story]);

  return <div className="absolute w-full h-full" ref={div} />;
};

const GET_STORY = gql`
  query Story($id: ID!) {
    story(id: $id) {
      id
      title
      isShowcase
      scenes {
        id
        imageURL
        musicURL
        narrationURL
        musicVolume
        narrationVolume
        entities {
          id
          type
          point {
            x
            y
            z
          }
          text
          imageURL
          audioURL
          volume
          scene
        }
      }
    }
  }
`;
