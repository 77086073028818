import { analytics } from "ccch9051/src/analytics";
import { ASSETS, createAssetElement, createStoryAssets } from "./assets";
import { Splash } from "./Splash";

const { radToDeg } = window.THREE.MathUtils;
const AFRAME = window.AFRAME;

// TODO: https://github.com/aframevr/aframe/issues/4796
const fixCanvasText = (str) => {
  return str.replace(/;/g, ",");
};

export class Player {
  constructor($root, story) {
    this.$root = $root;
    this.story = story;
    this.sceneIndex = 0;
    this.radius = 500; // TODO: add options

    // TODO: return from api
    let index = 0;
    story.scenes.forEach((scene, i) => {
      scene.index = i;
      scene.entities.forEach((entity) => {
        entity.index = index++;
      });
    });

    this.splash = new Splash(this.story);
    this.$root.append(this.splash.$container);

    this.$scene = this.createScene();
    this.$camera = this.createCamera();
    this.$sky = this.createSky();

    this.createSounds();

    this.$assets = this.createAssets();
    this.$scene.append(this.$assets, this.$camera, this.$sky);

    this.$root.append(this.$scene);

    this.$scene.addEventListener("loaded", () => {
      document
        .querySelector(".a-enter-vr-button")
        .addEventListener("click", (e) => {
          analytics.track("Open VR Player", { story_id: this.story.id });
        });
    });
  }

  createCameraCursor() {
    const $cursor = document.createElement("a-cursor");
    $cursor.id = "cursor";
    $cursor.setAttribute("fuse", true);
    $cursor.setAttribute("objects", ".clickable");
    $cursor.setAttribute("position", "0 0 -2");
    $cursor.setAttribute(
      "material",
      "color: white; opacity: 0.7; side:double; shader:flat;"
    );
    $cursor.setAttribute(
      "geometry",
      "primitive: circle; radius: 0.06;  thetaLength: 0.1; thetaStart: 90;"
    );
    $cursor.setAttribute(
      "animation__fusing",
      "startEvents: fusing; property: geometry.thetaLength; from: 0.1; to: 360; dur: 1500; loop:1; dir: normal;"
    );
    $cursor.setAttribute(
      "animation__leave",
      "startEvents: mouseleave; property: geometry.thetaLength; to:0.1; dur:1;"
    );
    $cursor.setAttribute(
      "animation__click",
      "property: geometry.thetaLength; startEvents: click; dur: 100; to:0.1; from: 1;"
    );

    return $cursor;
  }

  createCamera() {
    const $camera = document.createElement("a-camera");
    $camera.id = "camera";
    $camera.setAttribute("fov", 60); // TODO: screen size?
    $camera.setAttribute("position", "0 0 0");
    // TODO: fix look-controls with mouse
    $camera.setAttribute("wasd-controls-enabled", false);

    const $cursor = this.createCameraCursor();
    $camera.appendChild($cursor);

    const $entity = document.createElement("a-entity");
    $entity.setAttribute("position", "0 0 -2.001");
    $entity.setAttribute("geometry", "primitive: circle; radius: 0.015;");
    $entity.setAttribute("material", "color: white; shader: flat;");
    $entity.setAttribute("transparent", "true");
    $camera.appendChild($entity);

    return $camera;
  }

  createSky() {
    const $sky = document.createElement("a-sky");
    $sky.setAttribute("radius", this.radius);
    return $sky;
  }

  // "scene" is an aframe scene, not the story scene
  createScene() {
    const $scene = document.createElement("a-scene");
    $scene.setAttribute("device-orientation-permission-ui", "enabled:true");
    $scene.setAttribute(
      "vr-mode-ui",
      "enabled: true; enterARButton: #myEnterARButton; cardboardModeEnabled: true;"
    );

    // replace default loading screen with custom splash screen
    $scene.setAttribute("loading-screen", "enabled:false");

    const $arButton = document.createElement("a");
    $arButton.id = "myEnterARButton";
    $arButton.style.display = "none";
    $arButton.href = "#";
    $scene.append($arButton);

    return $scene;
  }

  // extract all assets from story into <a-assets />
  // TODO: audio effects etc..
  createAssets() {
    const $assets = document.createElement("a-assets");
    $assets.setAttribute("timeout", "1000"); // TODO: timeout failed for partial content
    const storyAssets = createStoryAssets(this.story);
    this.assets = ASSETS.concat(storyAssets);

    this.loadedAssets = 0;
    // only preloading images in splash
    this.totalAssets = this.assets.filter((a) => a[0] === "img").length;
    for (const asset of this.assets) {
      const $asset = createAssetElement(asset);
      const eventName =
        asset[0] === "a-asset-item"
          ? "loaded"
          : asset[0] === "audio"
          ? "loadeddata"
          : "load";
      $asset.addEventListener(eventName, () => {
        this.loadedAssets++;
        this.splash.updateProgress(this.loadedAssets / this.totalAssets);

        if (this.loadedAssets >= this.totalAssets && this.assetsLoaded) {
          this.finishLoading();
        }
      });

      $assets.append($asset);
    }

    this.splash.$button.addEventListener("click", () => {
      this.start();
    });

    this.finishLoading = () => {
      this.splash.finish();
    };

    // TODO: handle loading error
    // loaded or timeout
    $assets.addEventListener("loaded", () => {
      this.assetsLoaded = true;
      if (this.loadedAssets >= this.totalAssets) {
        this.finishLoading();
      }
    });

    // this.$assets.addEventListener("timeout", () => {
    //   console.log("assets timeout");
    // });

    return $assets;
  }

  start() {
    this.splash.remove();
    this.switchScene(this.story.scenes[0]);
  }

  switchScene(scene) {
    this.scene = scene;
    this.$sky.setAttribute("src", "#scene-image-" + scene.index);

    if (scene.musicURL) {
      if (this.$musicSound) {
        this.$musicSound.remove();
      }

      this.$musicSound = document.createElement("a-sound");
      this.$musicSound.setAttribute("loop", true);
      this.$scene.append(this.$musicSound);
      this.$musicSound.setAttribute("src", "#scene-music-" + scene.index);
      if (scene.musicVolume) {
        this.$musicSound.setAttribute("volume", scene.musicVolume);
      }
      this.$musicSound.setAttribute("autoplay", true);
    }

    if (scene.narrationURL) {
      if (this.$narrationSound) {
        this.$narrationSound.remove();
      }

      this.$narrationSound = document.createElement("a-sound");
      this.$scene.append(this.$narrationSound);
      this.$narrationSound.setAttribute(
        "src",
        "#scene-narration-" + scene.index
      );
      if (scene.narrationVolume) {
        this.$narrationSound.setAttribute("volume", scene.narrationVolume);
      }
      this.$narrationSound.setAttribute("autoplay", true);
    }

    this.$scene.querySelectorAll(".entity").forEach(($el) => {
      $el.remove();
    });
    scene.entities.forEach((entity) => {
      const $entity = this.createStoryEntity(entity);
      $entity.classList.add("clickable");
      this.$scene.append($entity);
    });
  }

  createTourButton(entity) {
    const $entity = document.createElement("a-entity");
    $entity.setAttribute("geometry", "primitive: sphere; radius: 0.2;");
    $entity.setAttribute("material", "color: #ffffff; src: #tourTexture");
    $entity.setAttribute("position", this.getPosition(entity.point));
    $entity.setAttribute(
      "animation",
      "resumeEvents: mouseleave; pauseEvents: mouseenter; property: rotation; to: 0 360 0 ; loop: true; dur: 3000"
    );

    return $entity;
  }

  getPosition = (point) => {
    return [point.x * 0.015, point.y * 0.015, point.z * 0.015].join(" ");
  };

  openEntity(entity) {
    const { point } = entity;

    if (entity.type === "scene") {
      const scene = this.story.scenes.filter((s) => s.id === entity.scene)[0];
      if (scene) {
        this.switchScene(scene);

        analytics.track("Switch Player Scene", {
          story_id: this.story.id,
          scene_id: this.scene.id,
          object_id: entity.id,
          object: entity,
        });
      }
    } else {
      analytics.track("Open Entity", {
        story_id: this.story.id,
        scene_id: this.scene.id,
        object_id: entity.id,
        object: entity,
      });

      this.$scene.querySelectorAll("a-entity.entity").forEach(($el) => {
        $el.setAttribute("visible", false); // TODO: not working
        $el.classList.remove("clickable");
      });

      const $plane = document.createElement("a-plane");
      let layout = 1;

      if (entity.text && entity.imageURL) {
        $plane.setAttribute("width", 6);
        $plane.setAttribute("height", 3);
        layout = 2;
      } else {
        $plane.setAttribute("width", 3);
        $plane.setAttribute("height", 3);
      }

      const position = this.getPosition(point);
      $plane.setAttribute(
        "rotation",
        [
          radToDeg(this.$camera.object3D.rotation.x),
          radToDeg(this.$camera.object3D.rotation.y),
          radToDeg(this.$camera.object3D.rotation.z),
        ].join(" ")
      );
      $plane.setAttribute("position", position);

      if (entity.text) {
        const $text = document.createElement("a-plane");

        if (entity.imageURL) {
          $text.setAttribute("position", "1.5 0 0.01");
        } else {
          $text.setAttribute("position", "0 0 0.01");
        }
        $text.setAttribute("width", 2.9);
        $text.setAttribute("height", 2.9);
        $text.setAttribute("canvas-material", "width:512; height:512");
        $text.setAttribute(
          "canvas-text",
          `text: ${fixCanvasText(
            entity.text
          )}; font: 32px Arial; fillStyle: #333333;`
        );
        $plane.append($text);
      }

      if (entity.imageURL) {
        const { width, height } = document.getElementById(
          "entity-image-" + entity.index
        );
        const $image = document.createElement("a-image");
        $image.setAttribute("src", "#entity-image-" + entity.index);

        if (entity.text) {
          $image.setAttribute("position", "-1.5 0 0.01");
        } else {
          $image.setAttribute("position", "0 0 0.01");
        }

        const r = width / height;
        if (r >= 1) {
          $image.setAttribute("width", 3);
          $image.setAttribute("height", 3 / r);
        } else {
          $image.setAttribute("width", 3 * r);
          $image.setAttribute("height", 3);
        }

        $plane.append($image);
      }

      if (entity.audioURL) {
        if (this.$objectSound) {
          this.$objectSound.remove();
        }

        this.$objectSound = document.createElement("a-sound");
        this.$scene.append(this.$objectSound);

        if (this.$narrationSound) {
          this.$narrationSound.components.sound.pauseSound();
        }

        if (entity.volume) {
          this.$objectSound.setAttribute("volume", entity.volume);
        }
        this.$objectSound.setAttribute("src", "#entity-audio-" + entity.index);
        this.$objectSound.setAttribute("autoplay", true);

        const $icon = document.createElement("a-image");
        $icon.setAttribute("src", "#audioIcon");
        $icon.setAttribute("width", 0.6);
        $icon.setAttribute("height", 0.6);

        if (entity.text || entity.imageURL) {
          $icon.setAttribute("position", "0 2 0.02");
        } else {
          $icon.setAttribute("position", "0 0 0.02");
        }
        $plane.append($icon);
      }

      const $close = document.createElement("a-image");
      $close.setAttribute("src", "#closeIcon");
      $close.setAttribute("color", "#ffffff");
      if (layout === 1) {
        $close.setAttribute("position", "1.3 -1.3 0.1");
      } else if (layout === 2) {
        $close.setAttribute("position", "2.8 -1.3 0.1");
      }
      $close.setAttribute("width", 0.4);
      $close.setAttribute("height", 0.4);
      $close.classList.add("clickable");
      $close.addEventListener("click", () => {
        this.closeEntity(entity);
        $plane.remove();
      });
      $plane.append($close);

      this.$scene.append($plane);
    }
  }

  closeEntity(entity) {
    analytics.track("Close Entity", {
      story_id: this.story.id,
      scene_id: this.scene.id,
      object_id: entity.id,
      object: entity,
    });

    this.$scene.querySelectorAll("a-entity.entity").forEach(($el) => {
      $el.setAttribute("visible", true);
      $el.classList.add("clickable");
    });

    if (this.$narrationSound) {
      this.$narrationSound.components.sound.playSound();
    }
    if (this.$objectSound) {
      this.$objectSound.remove();
      this.$objectSound = null;
    }
  }

  createButton(entity) {
    const $entity = document.createElement("a-entity");
    // entity.id = "entityButton" + (elIndex + 1);
    // entity.classList.add("scene" + s.id);
    $entity.setAttribute(
      "geometry",
      "primitive: box; width: 0.3; height: 0.3; depth:0.3;"
    );
    $entity.setAttribute("material", "color: #ffffff; src: #entityTexture"); // TODO: custom color
    $entity.setAttribute("position", this.getPosition(entity.point));
    $entity.setAttribute("rotation", "0 0 0");
    $entity.setAttribute(
      "animation",
      "resumeEvents: mouseleave; pauseEvents: mouseenter; property: rotation; to: 0 360 0; loop: true; dur: 3000;"
    );
    $entity.setAttribute("over", true);

    return $entity;
  }

  // story entity is also known as object, a-entity is part of the ECS pattern
  createStoryEntity(entity) {
    let $entity = null;

    if (entity.type === "scene") {
      $entity = this.createTourButton(entity);
    } else {
      $entity = this.createButton(entity);
    }

    $entity.classList.add("entity");

    $entity.addEventListener("click", () => {
      this.openEntity(entity);
    });

    return $entity;
  }

  // TODO: sound effects
  createSounds() {
    // TODO:
    // const $clickSound = document.createElement("a-sound");
    // $clickSound.setAttribute("src", "#click");
    // $clickSound.id = "asoundclick";
    // const $hoverSound = document.createElement("a-sound");
    // $hoverSound.setAttribute("src", "#hover");
    // $hoverSound.id = "asoundhover";
    // AFRAME.registerComponent("over", {
    //   init: function () {
    //     this.el.addEventListener("mouseenter", function (e) {
    //       document.querySelector("#asoundhover").components.sound.playSound();
    //     });
    //   },
    // });
    // AFRAME.registerComponent("click", {
    //   init: function () {
    //     this.el.addEventListener("click", function (e) {
    //       document.querySelector("#asoundclick").components.sound.playSound();
    //     });
    //   },
    // });
  }
}
