function isDataURL(str) {
  if (str === null) {
    return false;
  }
  const regex =
    /^\s*data:([a-z]+\/[a-z]+(;[a-z-]+=[a-z-]+)?)?(;base64)?,[a-z0-9!$&',()*+;=\-._~:@/?%\s]*\s*$/i;
  return !!str.match(regex);
}

export function loadImageURL(imageURL, crossOrigin) {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.onload = () => resolve(image);
    image.onerror = reject;
    if (isDataURL(imageURL) === false && crossOrigin) {
      image.crossOrigin = crossOrigin;
    }
    image.src = imageURL;
  });
}

export function loadImageFile(imageFile) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      try {
        const image = loadImageURL(e.target.result);
        resolve(image);
      } catch (e) {
        reject(e);
      }
    };
    reader.readAsDataURL(imageFile);
  });
}

export function getImageDataURL(url) {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.setAttribute("crossOrigin", "anonymous");

    img.onload = function () {
      const canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;
      const ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0);
      const dataURL = canvas.toDataURL("image/png");

      resolve(dataURL);
    };

    img.onerror = function () {
      reject(new Error("image load failure"));
    };

    img.src = url;
  });
}

export const makeCancelable = (promise) => {
  let hasCanceled_ = false;

  const wrappedPromise = new Promise((resolve, reject) => {
    /* eslint-disable prefer-promise-reject-errors */
    promise.then(
      (val) => (hasCanceled_ ? reject({ isCanceled: true }) : resolve(val)),
      (error) => (hasCanceled_ ? reject({ isCanceled: true }) : reject(error))
    );
    /* eslint-enable */
  });

  return {
    promise: wrappedPromise,
    cancel() {
      hasCanceled_ = true;
    },
  };
};

export const isTouchDevice = !!(
  typeof window !== "undefined" &&
  typeof navigator !== "undefined" &&
  ("ontouchstart" in window || navigator.msMaxTouchPoints > 0)
);

export const isFileAPISupported = typeof File !== "undefined";

export const isPassiveSupported = () => {
  // https://developer.mozilla.org/en-US/docs/Web/API/EventTarget/addEventListener
  let passiveSupported = false;
  try {
    const options = Object.defineProperty({}, "passive", {
      get: function () {
        passiveSupported = true;
      },
    });

    window.addEventListener("test", options, options);
    window.removeEventListener("test", options, options);
  } catch (err) {
    passiveSupported = false;
  }
  return passiveSupported;
};

const draggableEvents = {
  touch: {
    react: {
      down: "onTouchStart",
      mouseDown: "onMouseDown",
      drag: "onTouchMove",
      move: "onTouchMove",
      mouseMove: "onMouseMove",
      up: "onTouchEnd",
      mouseUp: "onMouseUp",
    },
    native: {
      down: "touchstart",
      mouseDown: "mousedown",
      drag: "touchmove",
      move: "touchmove",
      mouseMove: "mousemove",
      up: "touchend",
      mouseUp: "mouseup",
    },
  },
  desktop: {
    react: {
      down: "onMouseDown",
      drag: "onDragOver",
      move: "onMouseMove",
      up: "onMouseUp",
    },
    native: {
      down: "mousedown",
      drag: "dragStart",
      move: "mousemove",
      up: "mouseup",
    },
  },
};
export const deviceEvents = isTouchDevice
  ? draggableEvents.touch
  : draggableEvents.desktop;

// Draws a rounded rectangle on a 2D context.
export const drawRoundedRect = (context, x, y, width, height, borderRadius) => {
  if (borderRadius === 0) {
    context.rect(x, y, width, height);
  } else {
    const widthMinusRad = width - borderRadius;
    const heightMinusRad = height - borderRadius;
    context.translate(x, y);
    context.arc(
      borderRadius,
      borderRadius,
      borderRadius,
      Math.PI,
      Math.PI * 1.5
    );
    context.lineTo(widthMinusRad, 0);
    context.arc(
      widthMinusRad,
      borderRadius,
      borderRadius,
      Math.PI * 1.5,
      Math.PI * 2
    );
    context.lineTo(width, heightMinusRad);
    context.arc(
      widthMinusRad,
      heightMinusRad,
      borderRadius,
      Math.PI * 2,
      Math.PI * 0.5
    );
    context.lineTo(borderRadius, height);
    context.arc(
      borderRadius,
      heightMinusRad,
      borderRadius,
      Math.PI * 0.5,
      Math.PI
    );
    context.translate(-x, -y);
  }
};
