module.exports = [
	{
		"title": "VR creation (1) - Capture",
		"tasks": [
			{
				"title": "Identify an immovable CH"
			},
			{
				"title": "Take photos"
			},
			{
				"title": "Stitch photos into panoramas"
			},
			{
				"title": "Stitch panoramas into spherical panorama"
			},
			{
				"title": "[Optional] Edit and/or polish spherical panorama(s)"
			},
			{
				"title": "Upload spherical panorama(s)"
			},
			{
				"title": "Write reflection (on Moodle)"
			}
		]
	},
	{
		"title": "VR creation (2) - Authoring",
		"tasks": [
			{
				"title": "Prepare components of the VR story",
				"tasks": [
					{
						"title": "Add text object"
					},
					{
						"title": "Add image object"
					},
					{
						"title": "Write *draft* narration script"
					},
					{
						"title": "Upload *draft* narration script"
					},
					{
						"title": "Add audio narration"
					},
					{
						"title": "[Optional] Add background music"
					},
					{
						"title": "[Optional] Add sound effect(s)"
					},
					{
						"title": "[Important] Check copyrights of all materials!"
					}
				]
			},
			{
				"title": "Address these questions in the VR story",
				"tasks": [
					{
						"title": "What kind of cultural heritage is it?"
					},
					{
						"title": "What is the history of this cultural heritage?"
					},
					{
						"title": "What are the values of this cultural heritage?"
					},
					{
						"title": "How is this cultural heritage preserved and/or promoted now?"
					},
					{
						"title": "[Optional] If relevant, how has the COVID-19 pandemic impacted this cultural heritage?"
					},
					{
						"title": "Any other information you deem essential and/or relevant to this cultural heritage"
					}
				]
			},
			{
				"title": "Apply course knowledge and concepts",
				"tasks": [
					{
						"title": "Definition of cultural heritage"
					},
					{
						"title": "Types of cultural heritage"
					},
					{
						"title": "Values of cultural heritage"
					},
					{
						"title": "Forms and methods of cultural heritage management"
					},
					{
						"title": "Stakeholders involved cultural heritage management"
					},
					{
						"title": "Pros and cons of digitizing cultural heritage"
					},
					{
						"title": "Ethical issues related to cultural heritage"
					},
					{
						"title": "Any other relevant knowledge and concepts"
					}
				]
			},
			{
				"title": "Double-check grading rubric (on Moodle) for requirements on:",
				"tasks": [
					{
						"title": "Content"
					},
					{
						"title": "Knowledge application"
					},
					{
						"title": "Delivery"
					},
					{
						"title": "Mechanics"
					}
				]
			},
			{
				"title": "Submit draft list of information sources (on Moodle)"
			},
			{
				"title": "Write reflection"
			}
		]
	},
	{
		"title": "VR creation (3) - Peer Evaluation",
		"tasks": [
			{
				"title": "Evaluate three assigned peers' draft VR stories",
				"tasks": [
					{
						"title": "View assigned peers' draft VR stories and narration scripts"
					},
					{
						"title": "Give feedback on assigned peers' drafts"
					},
					{
						"title": "Reflect on your own draft"
					},
					{
						"title": "Improve your draft (if needed)"
					}
				]
			},
			{
				"title": "[Optional] Evaluate other peers' draft VR stories and narration scripts",
				"tasks": [
					{
						"title": "View other peers' draft VR stories"
					},
					{
						"title": "Give feedback on other peers' drafts"
					},
					{
						"title": "Reflect on your own draft"
					},
					{
						"title": "Improve your draft (if needed)"
					}
				]
			},
			{
				"title": "Finalize your draft VR story and narration script based on peers' feedback",
				"tasks": [
					{
						"title": "Read feedback given by peers"
					},
					{
						"title": "Integrate constructive peer feedback into your VR story"
					},
					{
						"title": "Finalize your VR story"
					}
				]
			},
			{
				"title": "Double-check grading rubric (on Moodle) for requirements on:",
				"tasks": [
					{
						"title": "Content"
					},
					{
						"title": "Knowledge application"
					},
					{
						"title": "Delivery"
					},
					{
						"title": "Mechanics"
					}
				]
			},
			{
				"title": "Submit finalized list of information sources"
			},
			{
				"title": "Write reflection"
			}
		]
	},
	{
		"title": "[Important] SUBMIT the final version of your VR story"
	}
]