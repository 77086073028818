import cx from "clsx";
import React from "react";
import TextareaAutosize from "react-textarea-autosize";

const TextInput = ({ onChange, multiline, className, ...props }) => {
  return React.createElement(multiline ? TextareaAutosize : "input", {
    ...props,
    className: cx(
      "w-full appearance-none outline-0 resize-none bg-white border border-gray-300 rounded focus:border-gray-700 p-2 disabled:cursor-not-allowed disabled:bg-gray-200",
      className
    ),
    onChange: (e) => onChange(e.target.value),
  });
};

TextInput.defaultProps = {
  type: "text",
  multiline: false,
};

export default TextInput;
