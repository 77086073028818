import React from "react";
import { Audio, Button, FileUploaderButton } from "components";
import { AudioInput } from "./AudioInput";
import { uploadFile } from "src/utils";
import { captureException } from "src/sentry";

export function AudioManager({
  story,
  scene,
  entities,

  updateSceneMusicAudio,
  deleteSceneMusicAudio,
  updateSceneMusicVolume,

  updateSceneNarrationAudio,
  deleteSceneNarrationAudio,
  updateSceneNarrationVolume,

  updateEntityAudioVolume,
}) {
  const entityAudios = entities.filter((e) => e.audio);

  // TODO: add play all button for preview
  return (
    <div className="p-3 space-y-3">
      <div>Adjust volumes to make sure they play together nicely.</div>

      <div className="space-y-3">
        {scene.musicURL ? (
          <div className="space-y-2">
            <div className="flex items-center">
              <div className="font-semibold mr-auto">Music Audio</div>
              <Button
                variant="secondary-mini"
                onClick={() => deleteSceneMusicAudio(scene)}
              >
                Delete
              </Button>
            </div>

            <Audio
              src={scene.musicURL}
              volume={scene.musicVolume}
              loop={true}
              onVolumeChange={(volume) => {
                updateSceneMusicVolume(scene, volume);
              }}
            />

            {scene.musicCredit ? (
              <div className="italic text-sm">Credit: {scene.musicCredit}</div>
            ) : null}
          </div>
        ) : (
          <AudioInput
            story={story}
            scene={scene}
            onChange={(audio) => {
              updateSceneMusicAudio(scene, audio);
            }}
          />
        )}

        {scene.narrationURL ? (
          <div className="space-y-2">
            <div className="flex items-center">
              <div className="font-semibold mr-auto">Narration Audio</div>
              <Button
                variant="secondary-mini"
                onClick={() => deleteSceneNarrationAudio(scene)}
              >
                Delete
              </Button>
            </div>

            <Audio
              src={scene.narrationURL}
              volume={scene.narrationVolume}
              loop
              onVolumeChange={(volume) => {
                updateSceneNarrationVolume(scene, volume);
              }}
            />
          </div>
        ) : (
          <FileUploaderButton
            accept="audio/mpeg,audio/ogg,audio/wav"
            onChange={(e) => {
              const file = e.target.files[0];
              // TODO: file size limit
              uploadFile("SCENE_NARRATION_AUDIO", file, console.log)
                .then((audio) => {
                  updateSceneNarrationAudio(scene, audio);
                })
                .catch((err) => {
                  captureException(err);
                });
            }}
          >
            Upload Narration Audio
          </FileUploaderButton>
        )}
      </div>

      <div>List of audio objects</div>

      {entityAudios.map((entity) => (
        <div key={entity.id} className="space-y-2">
          <div className="font-semibold">{entity.title}</div>
          <Audio
            src={entity.audio.url}
            volume={entity.volume}
            onVolumeChange={(volume) => {
              updateEntityAudioVolume(entity, volume);
            }}
          />
        </div>
      ))}
    </div>
  );
}
