import { Analytics } from "@swiftcarrot/analytics";
import { useEffect } from "react";

export const analytics = new Analytics(
  process.env.COLLECTOR_ENDPOINT || "http://localhost:8000"
);

export function useAnalyticsPage(page, properties) {
  useEffect(() => {
    analytics.page(page, properties);
    // TODO: fix warning, should only trigger once in page
  }, []);
}
