import cx from "clsx";
import React, { Fragment, useRef } from "react";
import Draggable from "react-draggable";
import { Button } from "components";
import { useRenderer } from "./Renderer";
import Entity from "./Entity";
import EditorImageUpload from "./EditorImageUpload";
import EditorHeader from "./EditorHeader";
import { useEditor } from "src/editor";
import { useEditorLayout } from "src/useEditorLayout";
import Upload, { formatProgress } from "./Upload";
import { analytics } from "src/analytics";
import SceneList from "./SceneList";
import Checklist from "./Checklist";
import ClassProgress from "./ClassProgress";
import Statistics from "./Statistics";
import Inspector from "./Inspector";
import { AudioManager } from "./AudioManager";
import Overview from "./Overview";
import { NarrationScript } from "./NarrationScript";
import Reflection from "./Reflection";

export default function Editor({ story, laEnabled }) {
  const divRef = useRef();
  const dragging = useRef(false);

  // TODO: move useEditorLayout to separate components
  const {
    windowWidth,
    windowHeight,
    canvasWidth,
    canvasHeight,
    headerHeight,
    sidebarLeftWidth,
    sidebarRightWidth,
    sidebar,
    openSidebar,
    toggleSidebar,
  } = useEditorLayout(story);

  // TODO: <EditorContext story={story} />
  const editor = useEditor({ story });
  const {
    scene,
    setActiveEntityID,
    activeEntityID,
    entities,
    points,
    setPoints,
  } = editor;

  const renderer = useRenderer(divRef, scene.imageURL, entities, {
    width: canvasWidth,
    height: canvasHeight,
    onNewPoint: (id, point) => {
      editor.addEntity(id, point);
      openSidebar("objects");
    },
    onPointsChange: setPoints,
  });

  return (
    <div style={{ width: windowWidth, height: windowHeight }}>
      <EditorHeader
        story={story}
        style={{ height: headerHeight }}
        sidebar={sidebar}
        toggleSidebar={toggleSidebar}
        laEnabled={laEnabled}
      />
      <div className="flex" style={{ height: canvasHeight }}>
        <div
          className="h-full overflow-x-hidden overflow-y-auto shadow"
          style={{ width: sidebarLeftWidth }}
        >
          {sidebar.settings ? <Overview {...editor} /> : null}
          {sidebar.scenes ? <SceneList {...editor} /> : null}
          {sidebar.narration ? (
            <NarrationScript laEnabled={laEnabled} {...editor} />
          ) : null}
          {sidebar.reflection ? <Reflection {...editor} /> : null}
          {sidebar.checklist ? (
            <Checklist story={editor.story} setStory={editor.setStory} />
          ) : null}
          {sidebar.progress ? <ClassProgress story={editor.story} /> : null}
          {sidebar.statistics ? <Statistics story={editor.story} /> : null}
        </div>

        <div
          className="relative overflow-hidden"
          style={{ width: canvasWidth, height: canvasHeight }}
        >
          {scene.imageURL ? (
            <Fragment>
              <div ref={divRef} />
              <div className="absolute right-[8px] bottom-[8px]">
                <Upload
                  type="SCENE_BACKGROUND_IMAGE"
                  accept="image/png,image/jpg"
                  onChange={(image) => {
                    editor.updateSceneImage(scene, image);
                  }}
                >
                  {({ loading, progress }) => (
                    <Button>
                      {loading
                        ? progress
                          ? formatProgress(progress)
                          : "Uploading"
                        : "Change Image"}
                    </Button>
                  )}
                </Upload>
              </div>
            </Fragment>
          ) : (
            <EditorImageUpload {...editor} />
          )}

          {editor.entities.map((d) =>
            points[d.id] && points[d.id].screen ? (
              <Draggable
                key={d.id}
                position={points[d.id].screen}
                onDrag={() => {
                  dragging.current = true;
                }}
                onStop={(e, data) => {
                  if (dragging.current) {
                    dragging.current = false;

                    renderer.current.updateEntityPosition(d.id, data.x, data.y);
                    editor.updateEntity(d.id, {
                      point: renderer.current.points[d.id].point,
                    });
                  } else {
                    analytics.track("Select Object", {
                      story_id: story.id,
                      scene_id: scene.id,
                      object_id: d.id,
                    });

                    openSidebar("objects");
                    setActiveEntityID(d.id);
                  }
                }}
              >
                <div
                  className={cx(
                    "absolute top-0 left-0 cursor-pointer select-none",
                    d.id === activeEntityID ? "z-10" : "z-0"
                  )}
                >
                  <Entity entity={d} editing={d.id === activeEntityID} />
                </div>
              </Draggable>
            ) : null
          )}

          {editor.entity ? (
            <div
              className="absolute top-0 left-0 right-0 bottom-0 bg-black opacity-40"
              onClick={() => {
                setActiveEntityID(null);
              }}
            />
          ) : null}
        </div>

        <div
          className="h-full overflow-x-hidden overflow-y-auto shadow"
          style={{ width: sidebarRightWidth }}
        >
          {sidebar.objects ? (
            <Inspector
              {...editor}
              onEntityClick={(entity) => {
                editor.setActiveEntityID(entity.id);
                renderer.current.lookAtEntity(entity.id);
              }}
            />
          ) : null}
          {sidebar.audio ? (
            <AudioManager
              onEntityClick={(entity) => {
                editor.setActiveEntityID(entity.id);
                renderer.current.lookAtEntity(entity.id);
              }}
              {...editor}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
}
