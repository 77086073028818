import styles from "./ReviewForm.module.scss";
import React from "react";
import { Button, FormGroup, SimpleFormat } from "components";
import { gql, useMutation } from "@apollo/client";
import { Form, FormSubmit } from "@swiftcarrot/react-form";
import RatingInput from "src/components/RatingInput";
import { captureException } from "src/sentry";

export default function ReviewForm({ story, onCancel, onSubmit, ...props }) {
  const [createReview] = useMutation(CREATE_REVIEW);

  // TODO: validation
  function validate(values) {
    const errors = {};

    if (!values.rating1) {
      errors.rating1 = "Rating cannot be blank";
    }
    if (!values.comment1) {
      errors.comment1 = "Comments cannot be blank";
    }
    if (!values.rating2) {
      errors.rating2 = "Rating cannot be blank";
    }
    if (!values.comment2) {
      errors.comment2 = "Comments cannot be blank";
    }
    if (!values.rating3) {
      errors.rating3 = "Rating cannot be blank";
    }
    if (!values.comment3) {
      errors.comment3 = "Comments cannot be blank";
    }
    if (!values.rating4) {
      errors.rating4 = "Rating cannot be blank";
    }
    if (!values.comment4) {
      errors.comment4 = "Comments cannot be blank";
    }
    if (!values.rating5) {
      errors.rating5 = "Rating cannot be blank";
    }
    if (!values.comment5) {
      errors.comment5 = "Comments cannot be blank";
    }
    return errors;
  }

  function handleSubmit(values) {
    return createReview({
      variables: { input: { storyID: story.id, content: values } },
    })
      .then((resp) => {
        alert("Review updated");
        onSubmit(resp.data.createReview);
      })
      .catch((err) => {
        alert("Network error");
        captureException(err);
      });
  }

  return (
    <div className={styles.container}>
      <div className="h-[50px] px-3 flex items-center justify-center font-semibold text-lg shadow">
        Your feedback for {story.user.name}'s VR story
      </div>

      <Form
        {...props}
        initialValues={story.viewerReview ? story.viewerReview.content : {}}
        validate={validate}
        onSubmit={handleSubmit}
      >
        <div className={styles.content}>
          <div className={styles.title}>Narration Script</div>
          <SimpleFormat text={story.script} />

          <div className={styles.title}>Content</div>
          <FormGroup name="rating1" label="Rating" component={RatingInput} />
          <FormGroup name="comment1" label="Comments" multiline minRows={3} />
          <div className={styles.title}>Knowledge Application</div>
          <FormGroup name="rating2" label="Rating" component={RatingInput} />
          <FormGroup name="comment2" label="Comments" multiline minRows={3} />
          <div className={styles.title}>Delivery</div>
          <FormGroup name="rating3" label="Rating" component={RatingInput} />
          <FormGroup name="comment3" label="Comments" multiline minRows={3} />
          <div className={styles.title}>Mechanics</div>
          <FormGroup name="rating4" label="Rating" component={RatingInput} />
          <FormGroup name="comment4" label="Comments" multiline minRows={3} />
          <div className={styles.title}>Overall</div>
          <FormGroup
            name="rating5"
            label="Overall Rating"
            component={RatingInput}
          />
          <FormGroup
            name="comment5"
            label="Other Comments"
            multiline
            minRows={3}
          />
        </div>
        <div className="px-3 flex items-center justify-end border-t h-[60px] gap-2">
          <Button variant="secondary" onClick={onCancel}>
            Cancel
          </Button>
          <FormSubmit component={Button} variant="primary" />
        </div>
      </Form>
    </div>
  );
}

const CREATE_REVIEW = gql`
  mutation CreateReview($input: CreateReviewInput!) {
    createReview(input: $input) {
      id
      content {
        rating1
        comment1
        rating2
        comment2
        rating3
        comment3
        rating4
        comment4
        rating5
        comment5
      }
    }
  }
`;
