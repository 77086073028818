import React, { HTMLAttributes, ReactNode } from "react";
import { useDialog } from "react-aria";

interface DialogProps extends HTMLAttributes<HTMLElement> {
  children: ReactNode;
  title?: string;
}

export const Dialog = (props: DialogProps) => {
  let { title, children, ...otherProps } = props;
  let ref = React.useRef();
  let { dialogProps, titleProps } = useDialog(props, ref);

  return (
    <div {...dialogProps} ref={ref} className="outline-0">
      {title ? (
        <h3 {...titleProps} className="mt-0">
          {title}
        </h3>
      ) : null}
      {children}
    </div>
  );
};
