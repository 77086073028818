import React, { useEffect, useState } from "react";
import { gql, useQuery, useMutation } from "@apollo/client";
import {
  Button,
  TextInput,
  FormSubmit,
  FormControl,
  Form,
  Loading,
} from "components";
import ReactMarkdown from "react-markdown";
import clsx from "clsx";
import { useAuth } from "@swiftcarrot/react-auth";

export default function Page({ params: { key } }) {
  const { currentUser } = useAuth();
  const { loading, data, error } = useQuery(GET_CHAT_SESSION, {
    variables: { key },
  });
  const [createChatMessage] = useMutation(CREATE_CHAT_MESSAGE);
  const [waiting, setWaiting] = useState(false);
  const [messages, setMessages] = useState([]);

  useEffect(() => {
    if (data) {
      setMessages(data.chatSession.chatMessages);
    }
  }, [data]);

  const validate = (values) => {
    if (!values.content.trim()) {
      return { content: "Please enter some text" };
    }
  };

  const handleSubmit = (values, { setValues }) => {
    setMessages((messages) => [
      ...messages,
      { role: "user", content: values.content },
    ]);
    setValues({ content: "" });

    setWaiting(true);

    return createChatMessage({
      variables: {
        input: {
          chatSessionKey: key,
          content: values.content,
        },
      },
    })
      .then((resp) => {
        const message = resp.data.createChatMessage;
        setMessages((messages) => [...messages, message]);
        setWaiting(false);
        setValues({ content: "" });
      })
      .catch((err) => {
        alert("Network Error");
        console.error(err);
        setWaiting(false);
      });
  };

  return loading ? (
    <Loading />
  ) : error ? (
    <div>Network Error</div>
  ) : (
    <div className="pb-[80px]">
      {messages.map((message, i) => (
        <div
          key={i}
          className={clsx(
            "py-5 border-b",
            message.role === "user" ? "bg-white" : "bg-gray-200"
          )}
        >
          <div className="w-[500px] px-3 max-w-full mx-auto">
            <ChatMessage text={message.content} />
          </div>
        </div>
      ))}

      {waiting ? <Loading /> : null}

      {currentUser.id === data.chatSession.user.id ? (
        <Form
          initialValues={{ content: "" }}
          onSubmit={handleSubmit}
          validate={validate}
        >
          <div className="fixed bottom-3 left-0 right-0 px-3">
            <div className="flex gap-2 w-[600px] max-w-full mx-auto">
              <FormControl
                component={TextInput}
                placeholder="Ask me anything"
                name="content"
                multiline={false}
              />
              <FormSubmit component={Button}>Send</FormSubmit>
            </div>
          </div>
        </Form>
      ) : null}
    </div>
  );
}

const ChatMessage = ({ text }) => {
  return (
    <div className="chat-message">
      <ReactMarkdown children={text} />
    </div>
  );
};

const GET_CHAT_SESSION = gql`
  query GetChatSession($key: String!) {
    chatSession(key: $key) {
      id
      user {
        id
      }
      chatMessages {
        id
        role
        content
        createdAt
      }
    }
  }
`;

const CREATE_CHAT_MESSAGE = gql`
  mutation CreateChatMessage($input: CreateChatMessageInput!) {
    createChatMessage(input: $input) {
      id
      role
      content
    }
  }
`;
