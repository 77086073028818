import React, { useEffect } from "react";
import { useAnalyticsPage } from "src/analytics";
import { useAuth } from "@swiftcarrot/react-auth";
import { Link } from "@swiftcarrot/react-router";
import { Button, Container } from "components";
import {
  ArrowForwardOutline,
  PersonAddOutline,
} from "@swiftcarrot/react-ionicons";

export default function Page() {
  const { loading, currentUser } = useAuth();

  useEffect(() => {
    document.body.classList.add("dots");
    return () => {
      document.body.classList.remove("dots");
    };
  }, []);

  useAnalyticsPage("Home");

  return (
    <Container>
      <div className="flex flex-col justify-center h-screen pb-5">
        <div className="mb-1">
          <img src="/images/logo.png" alt="LAVR Logo" className="h-[110px]" />
        </div>
        <h1 className="mx-0 font-bold text-xl">Welcome to LAVR platform.</h1>
        <div className="mt-1 mb-5 text-lg">
          <strong>LAVR</strong> is <strong>L</strong>earning <strong>A</strong>
          nalytics enabled <strong>V</strong>irtual <strong>R</strong>eality
          content creation platform.
        </div>

        {loading ? (
          <div></div>
        ) : currentUser ? (
          <div>
            <Button component={Link} href="/dashboard">
              Open Dashboard
            </Button>
            {/*<UserAvatar user={currentUser} />*/}
          </div>
        ) : (
          <div className="flex space-x-2">
            <Button component={Link} href="/register">
              <PersonAddOutline width="16px" style={{ marginRight: 5 }} />
              Create Account
            </Button>
            <Button component={Link} href="/login" variant="secondary">
              <ArrowForwardOutline width="16px" style={{ marginRight: 5 }} />
              Login
            </Button>
          </div>
        )}
        <div className="mt-5 text-gray-500 text-sm">
          CCMIR ◎ HKU Culture Computing and Multimodal Information Research
        </div>
      </div>
    </Container>
  );
}
