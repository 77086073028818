import React from "react";
import { Pause, Play } from "@swiftcarrot/react-ionicons";
import { AspectRatio } from "components";
import { Button } from "./Button";
import { formatDuration } from "../utils/index.js";

// TODO: props.paused
export const SongItem = ({ song, isActive, onPause, onPlay }) => {
  return (
    <div className="flex text-gray-900 bg-white px-3 py-2 rounded">
      <div className="mr-2 w-[40px] h-[40px]">
        <AspectRatio>
          <img
            src={`https://usercontent.jamendo.com/?type=album&id=${song.album_id}&width=100&trackid=${song.id}`}
            alt={song.title}
          />
        </AspectRatio>
      </div>
      <div className="flex-1 min-w-0">
        <div className="text-sm font-semibold break-words mb-1">
          {song.title}
        </div>
        <div className="flex flex-wrap gap-1">
          {song.genres.map((tag) => (
            <SongTag key={tag} tag={tag} />
          ))}
          {song.moods.map((tag) => (
            <SongTag key={tag} tag={tag} />
          ))}
          {song.instruments.map((tag) => (
            <SongTag key={tag} tag={tag} />
          ))}
          {song.themes.map((tag) => (
            <SongTag key={tag} tag={tag} />
          ))}
        </div>
      </div>
      <div className="text-gray-500 text-sm mx-3 flex items-center">
        {formatDuration(song.duration)}
      </div>
      {isActive ? (
        <Button onPress={onPause}>
          <Pause className="fill-current w-4 h-4" />
        </Button>
      ) : (
        <Button onPress={onPlay}>
          <Play className="fill-current w-4 h-4" />
        </Button>
      )}
    </div>
  );
};

const SongTag = ({ tag }) => {
  return (
    <div className="rounded-full border text-gray-500 text-sm px-2">{tag}</div>
  );
};
