import styles from "./Preview.module.scss";
import React, { useEffect, useRef, useState } from "react";
import { cx } from "components";
import Draggable from "react-draggable";
import { useRenderer } from "./Renderer";
import { useAudio } from "react-use";
import Entity from "./Entity";
import EntityPreview from "./EntityPreview";

// TODO: show put preview in Editor with readonly mode
export default function Preview({ story, width, height }) {
  const [points, setPoints] = useState({});
  const [activeSceneID, setActiveSceneID] = useState(story.scenes[0].id);
  const scene = story.scenes.filter((d) => d.id === activeSceneID)[0];
  const [activeEntityID, setActiveEntityID] = useState(null);
  const entity = scene.entities.filter((d) => d.id === activeEntityID)[0];
  const divRef = useRef();
  useRenderer(divRef, scene.imageURL, scene.entities, {
    width,
    height,
    onPointsChange: setPoints,
  });
  const audioRef = useRef();

  useEffect(() => {
    console.log(audioRef.current);

    if (scene.audioURL) {
      audioRef.current.src = scene.audioURL;
      if (scene.volume) {
        audioRef.current.volume = scene.volume;
      }
      audioRef.current.play();
    } else {
      audioRef.current.pause();
      audioRef.current.src = "";
    }
  }, [scene]);

  return (
    <div style={{ width, height }}>
      <audio ref={audioRef} />

      <div ref={divRef} />

      {scene.entities.map((d) =>
        points[d.id] ? (
          <Draggable key={d.id} disabled={true} position={points[d.id].screen}>
            <div
              className={cx(styles.entity, {
                [styles.active]: d.id === activeEntityID,
              })}
            >
              <Entity
                entity={d}
                editing={false}
                onClick={() => {
                  if (d.type === "scene") {
                    setActiveSceneID(d.scene);
                  } else {
                    setActiveEntityID(d.id);
                  }
                }}
              />
            </div>
          </Draggable>
        ) : null
      )}

      <EntityPreview
        entity={entity}
        width={width}
        height={height}
        setActiveEntityID={setActiveEntityID}
      />
    </div>
  );
}
