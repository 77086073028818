import React from "react";
import { Container } from "components";
import { Link, useLocation } from "@swiftcarrot/react-router";
import { useAuth } from "@swiftcarrot/react-auth";

export default function Header() {
  const { currentUser, setCurrentUser } = useAuth();
  const [, navigate] = useLocation();
  const enableAI = currentUser.email !== 'demo@lavrplatform.com'

  return (
    <div className="mb-5 shadow">
      <Container className="flex items-center h-[50px]">
        <Link
          href="/dashboard"
          className="inline-flex items-center h-full mr-auto"
        >
          <img src="/images/logo.png" className="h-[40px]" alt="LAVR Logo" />
        </Link>

        <div className="flex items-center space-x-2">
          <NavItem href="/dashboard">Home</NavItem>
          {enableAI ? <NavItem href="/chatgpt">ChatGPT</NavItem>: null}
          {enableAI ? <NavItem href="/text-to-image">Text to Image</NavItem>: null}
          {/* TODO: add popover  */}
          <NavItem href="/profile">Profile</NavItem>
          <NavItem
            elementType="button"
            onClick={() => {
              setCurrentUser(null);
              window.localStorage.removeItem("token");
              // TODO: analytics logout user???
              navigate("/");
            }}
          >
            Logout
          </NavItem>
        </div>
      </Container>
    </div>
  );
}

const NavItem = ({ elementType, ...props }) => {
  return React.createElement(elementType, {
    ...props,
    className: "hover:bg-slate-300 rounded text-sm px-1.5 py-0.5",
  });
};

NavItem.defaultProps = {
  elementType: Link,
};
