import React from "react";
import { gql, useMutation } from "@apollo/client";
import { useAuth } from "@swiftcarrot/react-auth";
import { Container, Card } from "components";
import ProfileForm from "src/components/ProfileForm";
import { captureException } from "src/sentry";
import { analytics, useAnalyticsPage } from "src/analytics";

export default function Page() {
  const { currentUser, setCurrentUser } = useAuth();
  const [updateUserProfile] = useMutation(UPDATE_USER_PROFILE);
  useAnalyticsPage("Profile");

  function handleSubmit({ avatar, email, ...values }) {
    return updateUserProfile({
      variables: { input: { ...values, avatar: avatar ? avatar.key : "" } },
    })
      .then((resp) => {
        setCurrentUser({ ...currentUser, ...resp.data.updateUserProfile });
        analytics.track("Update Profile");
        alert("Profile Updated");
      })
      .catch((err) => {
        captureException(err);
        alert("Network error");
      });
  }

  return (
    <Container>
      <Card title="Profile">
        <ProfileForm
          initialValues={{
            name: currentUser.name,
            email: currentUser.email,
            avatar: currentUser.avatar,
          }}
          onSubmit={handleSubmit}
        />
      </Card>
    </Container>
  );
}

const UPDATE_USER_PROFILE = gql`
  mutation UpdateUserProfile($input: UpdateUserProfileInput!) {
    updateUserProfile(input: $input) {
      id
      name
      avatar {
        key
        url
      }
    }
  }
`;
