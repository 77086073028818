import React, { Fragment } from "react";

export const SimpleFormat = ({ text }) => {
  return text ? (
    <Fragment>
      {text.split("\n").map((x, i) => (
        <Fragment key={i}>
          {x}
          <br />
        </Fragment>
      ))}
    </Fragment>
  ) : null;
};
