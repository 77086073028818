import { gql } from "@apollo/client";
import { client } from "src/client";

// const IMAGE_HOST = "http://127.0.0.1:8787";
const IMAGE_HOST = "https://lavrplatform.com/cf-images";

export function imageURL(url, { width, height }) {
  return `${IMAGE_HOST}/?width=${width}&height=${height}&fit=scale-down&image=${encodeURIComponent(
    url
  )}`;
}

export async function uploadFile(type, file, onProgressChange) {
  const resp = await client.mutate({
    mutation: CREATE_UPLOAD,
    variables: { input: { type } },
  });
  const data = resp.data.createUpload;
  const req = new XMLHttpRequest();

  return new Promise((resolve, reject) => {
    req.open("PUT", data.uploadURL);

    req.upload.addEventListener("progress", (e) => {
      if (e.lengthComputable) {
        const progress = (e.loaded / e.total) * 100;

        if (onProgressChange) {
          onProgressChange(progress);
        }
      }
    });

    // TODO: req.upload failed here
    req.addEventListener("load", () => {
      resolve(data);
    });
    req.upload.addEventListener("error", () => {
      console.log("error");
    });
    req.upload.addEventListener("abort", () => {
      console.log("abort");
    });

    req.send(file);
  });
}

const CREATE_UPLOAD = gql`
  mutation CreateUpload($input: CreateUploadInput!) {
    createUpload(input: $input) {
      key
      type
      url
      uploadURL
    }
  }
`;
