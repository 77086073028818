import React, { useEffect } from 'react';
import { useLocation } from '@swiftcarrot/react-router';
import { useAuth } from '@swiftcarrot/react-auth';
import { Card, Loading } from 'components';
import LoginForm from 'src/components/LoginForm';
import { useAnalyticsPage } from 'src/analytics';

export default function Page() {
  const { loading, currentUser } = useAuth();
  const [, navigate] = useLocation();

  useAnalyticsPage('Login');

  useEffect(() => {
    if (!loading && currentUser) {
      navigate('/dashboard', { replace: true });
    }
  }, [loading, currentUser, navigate]);

  if (loading) {
    return <Loading />;
  }

  return (
    <Card title="Log in">
      <LoginForm />
    </Card>
  );
}
