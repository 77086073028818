import React from "react";
import clsx from "clsx";
import { useLocation } from "@swiftcarrot/react-router";
import { Home, Play } from "@swiftcarrot/react-ionicons";
import { EDITOR_LAYOUT } from "src/useEditorLayout"; // TODO: read from hook

export default function EditorHeader({
  story,
  laEnabled,
  sidebar,
  toggleSidebar,
  ...props
}) {
  const [, navigate] = useLocation();

  const renderButtons = (buttons) => {
    return buttons
      .filter(({ la }) => laEnabled || !la)
      .map((button) => (
        <HeaderButton
          key={button.key}
          title={button.title}
          icon={button.icon}
          onClick={() => toggleSidebar(button.key)}
          active={sidebar[button.key]}
        />
      ));
  };

  return (
    <div className="relative flex items-center shadow px-2" {...props}>
      <div className="flex space-x-2 flex-1">
        <HeaderButton icon={Home} onClick={() => navigate("/dashboard")} />
        {renderButtons(EDITOR_LAYOUT.left)}
      </div>
      <div className="flex space-x-2">
        {renderButtons(EDITOR_LAYOUT.right)}
        <a
          className={clsx(
            "flex items-center cursor-pointer rounded-md px-2 py-0.5 select-none",
            "hover:bg-gray-200"
          )}
          href={`/watch?id=${story.id}`}
          target="_blank"
        >
          <Play className="ionicon w-3 h-3" />
          <div className="text-sm ml-1">Preview</div>
        </a>
      </div>
    </div>
  );
}

const HeaderButton = ({ title, icon, active, ...props }) => {
  return (
    <div
      className={clsx(
        "flex items-center cursor-pointer rounded-md px-2 py-0.5 select-none",
        active ? "text-white bg-gray-900" : "hover:bg-gray-200"
      )}
      {...props}
    >
      {icon
        ? React.createElement(icon, { className: "ionicon w-3 h-3" })
        : null}
      {title ? <div className="text-sm ml-1">{title}</div> : null}
    </div>
  );
};
