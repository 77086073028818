import React, { Fragment } from "react";
import Header from "src/components/Header";
import Footer from "src/components/Footer";

export default function Layout({ children }) {
  return (
    <Fragment>
      <Header />
      <div style={{ minHeight: 680 }}>{children}</div>
      <Footer />
    </Fragment>
  );
}
