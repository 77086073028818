import styles from "./SceneSelect.module.scss";
import React from "react";
import { cx, AspectRatio } from "components";
import { imageURL } from "src/utils";

export default function SceneSelect({ value, scenes, onChange, withHome }) {
  return (
    <div className={styles.container}>
      {scenes.map((scene) => (
        <div
          key={scene.id}
          className={cx(styles.scene, {
            [styles.active]: scene.id === value,
          })}
        >
          <div
            className={styles.image}
            onClick={() => {
              onChange(scene.id);
            }}
          >
            <AspectRatio ratio="16x9">
              {scene.imageURL ? (
                <img
                  src={imageURL(scene.imageURL, { width: 400, height: 400 })}
                />
              ) : (
                <div className={styles.placeholder} />
              )}
            </AspectRatio>
          </div>
        </div>
      ))}
    </div>
  );
}
