import styles from "./SceneList.module.scss";
import React from "react";
import { cx, AspectRatio, Button } from "components";
import { Home, AddCircleOutline } from "@swiftcarrot/react-ionicons";
import { imageURL } from "src/utils";

export default function SceneList({
  scenes,
  activeSceneID,
  setActiveSceneID,

  addScene,
  removeScene,
  setSceneHome,
  style,
}) {
  return (
    <div className="p-2 space-y-2" style={style}>
      {scenes.map((scene, index) => (
        <div
          key={scene.id}
          className={cx(
            "relative cursor-pointer rounded overflow-hidden",
            scene.id === activeSceneID ? "border-2 shadow border-gray-700" : ""
          )}
        >
          <div
            className={styles.image}
            onClick={() => {
              setActiveSceneID(scene.id);
            }}
          >
            <AspectRatio ratio="16x9">
              {scene.imageURL ? (
                <img
                  src={imageURL(scene.imageURL, { width: 400, height: 400 })}
                />
              ) : (
                <div className="bg-gray-200 w-full h-full" />
              )}
            </AspectRatio>
          </div>

          {scene.isHome ? (
            <div className="absolute top-[4px] right-[4px] w-[20px]">
              <Home />
            </div>
          ) : null}

          {scene.id === activeSceneID ? (
            <div className="flex gap-1 absolute bottom-[4px] right-[4px]">
              {!scene.isHome ? (
                <Button
                  variant="primary-mini"
                  onClick={() => setSceneHome(scene)}
                >
                  Set Home
                </Button>
              ) : null}
              {scenes.length > 1 ? (
                <Button
                  variant="primary-mini"
                  onClick={() => removeScene(scene)}
                >
                  Delete
                </Button>
              ) : null}
            </div>
          ) : null}

          <div className={styles.index}>{index + 1}</div>
        </div>
      ))}
      <AspectRatio ratio="16x9">
        <div
          className="bg-gray-200 flex flex-col items-center justify-center cursor-pointer"
          onClick={addScene}
        >
          <AddCircleOutline width="30px" />
          <div className="font-bold">Add New Scene</div>
        </div>
      </AspectRatio>
    </div>
  );
}
