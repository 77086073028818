import React, { useEffect } from 'react';
import { useLocation, useSearchParams } from '@swiftcarrot/react-router';
import { useAuth } from '@swiftcarrot/react-auth';
import { Card, Loading } from 'components';
import RegisterForm from 'src/components/RegisterForm';
import { useAnalyticsPage } from 'src/analytics';

export default function Page() {
  const [params] = useSearchParams();
  const { loading, currentUser } = useAuth();
  const [, navigate] = useLocation();

  useAnalyticsPage('Register');

  useEffect(() => {
    if (!loading && currentUser) {
      navigate('/dashboard', { replace: true });
    }
  }, [loading, currentUser, navigate]);

  if (loading) {
    return <Loading />;
  }

  return (
    <Card title="Create Account">
      <RegisterForm token={params.get('token')} />
    </Card>
  );
}
