import React, { useEffect, useState, Fragment } from "react";
import { gql, useQuery } from "@apollo/client";
import { Form, Button, FormGroup, FormSubmit, Loading } from "components";
import { useInterval } from "react-use";
import { analytics } from "src/analytics";

export const MusicGeneration = ({ onCancel, onChange, story, scene }) => {
  const { loading, data, error } = useQuery(AUTHORIZE_CHAT);

  return (
    <div className="fixed top-[36px] right-0 bottom-0 w-[350px] border flex flex-col z-10 bg-white overflow-y-auto">
      <div
        className="absolute top-0 left-[-30px] w-[30px] h-[30px] text-lg text-center leading-[30px] bg-gray-700 text-white cursor-pointer"
        onClick={onCancel}
      >
        &times;
      </div>

      {loading ? (
        <Loading />
      ) : (
        <App
          token={data.authorizeChat.token}
          onCancel={onCancel}
          onChange={onChange}
          story={story}
          scene={scene}
        />
      )}
    </div>
  );
};

const App = ({ token, onChange, story, scene }) => {
  const [state, setState] = useState({
    loading: true,
    data: [],
    error: null,
  });

  const createJob = (prompt, duration) => {
    return fetch(`https://api.modalityml.com/v1/jobs`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      method: "POST",
      body: JSON.stringify({
        task: "text-to-music",
        model: "musicgen",
        input: { prompt, duration },
      }),
    })
      .then((r) => r.json())
      .then((job) => {
        setState({ loading: false, data: [job, ...state.data], error: null });
      })
      .catch((err) => {
        alert("Network error"); // TODO: error
        console.error(err);
      });
  };

  const handleSubmit = (values, { setValues }) => {
    return createJob(values.prompt, 5).then(() => {
      setValues({ prompt: "" });
    });
  };

  const handleExtend = (prompt) => {
    analytics.track("Music Generation Extend", {
      story_id: story.id,
      scene_id: scene.id,
      prompt,
    });
    createJob(prompt, 60);
  };

  useEffect(() => {
    fetch(`https://api.modalityml.com/v1/jobs?task=text-to-music`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((r) => r.json())
      .then((jobs) => {
        setState({ loading: false, data: jobs, error: false });
      })
      .catch((err) => {
        console.error(err);
        alert("Network error");
      });
  }, []);

  return (
    <div className="p-2">
      <Form initialValues={{ prompt: "" }} onSubmit={handleSubmit}>
        <FormGroup
          name="prompt"
          multiline
          minRows={3}
          placeholder="Describe the music you want to generate"
        />
        <div className="flex justify-end">
          <FormSubmit component={Button} variant="primary">
            Generate Preview
          </FormSubmit>
        </div>
      </Form>

      <div className="flex-1">
        {state.loading ? (
          <Loading />
        ) : state.data.length ? (
          <Fragment>
            <div className="bold mb-3">Recent Generations</div>
            <div className="space-y-3">
              {state.data.map((job) => (
                <MusicGenerationJob
                  key={job.id}
                  job={job}
                  userToken={token}
                  onExtend={() => handleExtend(job.input.prompt)}
                  onChange={onChange}
                  story={story}
                  scene={scene}
                />
              ))}
            </div>
          </Fragment>
        ) : null}
      </div>
    </div>
  );
};

const AUTHORIZE_CHAT = gql`
  query AuthorizeChat {
    authorizeChat {
      token
    }
  }
`;

export const MusicGenerationJob = ({
  job,
  onExtend,
  userToken,
  onChange,
  story,
  scene,
}) => {
  const [state, setState] = useState({
    loading: job.status !== "COMPLETED",
    data: job,
    error: null,
  });
  const [submitting, setSubmitting] = useState(false);

  useInterval(
    () => {
      fetch(`https://api.modalityml.com/v1/jobs/${job.id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      })
        .then((r) => r.json())
        .then((job) => {
          if (job.status === "COMPLETED") {
            setState({ loading: false, data: job, error: null });
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    state.loading ? 3000 : null,
  );

  return (
    <div className="relative border rounded p-3 space-y-3 overflow-hidden">
      <div>{state.data.input.prompt}</div>
      {state.loading ? (
        <div>Generating...</div>
      ) : (
        <div>
          {state.data.result.music && (
            <audio
              src={`https://modalityml-hk.oss-cn-hongkong.aliyuncs.com/${state.data.result.music}`}
              controls
              loop
            />
          )}

          <div className="flex mt-3 gap-3">
            {state.data.input.duration < 60 ? (
              <Button onClick={onExtend} variant="primary-mini">
                Extend
              </Button>
            ) : null}

            <Button
              onClick={() => {
                if (submitting) return;

                analytics.track("Music Generation Select", {
                  story_id: story.id,
                  scene_id: scene.id,
                  job: state.data,
                });

                setSubmitting(true);
                onChange(
                  `https://modalityml-hk.oss-cn-hongkong.aliyuncs.com/${state.data.result.music}`,
                ).then(() => {
                  setSubmitting(false);
                });
              }}
              variant="primary-mini"
            >
              {submitting ? "Uploading..." : "Select"}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};
