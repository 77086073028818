import React, { useEffect } from "react";

export default function PageHeader({ title, subtitle, extra }) {
  useEffect(() => {
    document.title = title;
  }, [title]);

  return (
    <div className="mb-5">
      <div className="flex items-center mb-3">
        <h1 className="text-2xl font-medium flex-1">{title}</h1>
        {extra}
      </div>
      <div className="text-lg text-gray-400">{subtitle}</div>
    </div>
  );
}
