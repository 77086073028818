import React from "react";
import { gql, useQuery, useMutation } from "@apollo/client";
import {
  Link,
  Loading,
  Container,
  Button,
  Card,
  Placeholder,
  PageHeader,
} from "components";
import { useAnalyticsPage } from "src/analytics";
import { useAuth } from "@swiftcarrot/react-auth";
import { captureException } from "src/sentry";

export default function Page() {
  const { data, loading, error, refetch } = useQuery(GET_STORIES);
  const [submitStory] = useMutation(SUBMIT_STORY);
  const { currentUser } = useAuth();

  useAnalyticsPage("User Home");

  const submitAssignment = (story) => {
    // TODO: use alert dialog
    if (
      window.confirm(`Are you sure to submit the story?
You will not be able to edit the story after submission.`)
    ) {
      submitStory({ variables: { input: { id: story.id } } })
        .then((resp) => {
          refetch();
        })
        .catch((err) => {
          alert("Network error");
          captureException(err);
        });
    }
  };

  return loading ? (
    <Loading />
  ) : error ? (
    <div>Network Error</div>
  ) : (
    <Container>
      <PageHeader
        title="My Classrooms"
        extra={
          currentUser.isAdmin ? (
            <Button component={Link} href="/new-classroom">
              Create Classroom
            </Button>
          ) : null
        }
      />

      {data.viewer.classrooms.length ? (
        <div className="space-y-3">
          {data.viewer.classrooms.map((classroom) => (
            <Card
              key={classroom.id}
              title={
                <div>
                  {classroom.name}
                  <div className="text-sm text-gray-500 font-normal">
                    {classroom.details}
                  </div>
                </div>
              }
              extra={
                classroom.viewer.role === "ADMIN" ? (
                  <Button
                    component={Link}
                    href={`/admin/classrooms/${classroom.id}`}
                  >
                    Admin
                  </Button>
                ) : null
              }
            >
              <div className="space-y-4">
                {classroom.assignments.map((assignment) => (
                  <div key={assignment.id}>
                    <div className="flex">
                      <div className="flex-1 text-lg font-semibold">
                        {assignment.title}
                      </div>
                      {assignment.viewerStory ? (
                        assignment.viewerStory.submittedAt ? (
                          <Button
                            component="a"
                            href={`/watch?id=${assignment.viewerStory.id}`}
                            variant="secondary-mini"
                          >
                            View Story
                          </Button>
                        ) : (
                          <Button
                            component={Link}
                            href={`/editor?id=${assignment.viewerStory.id}`}
                            variant="secondary-mini"
                          >
                            Edit Story
                          </Button>
                        )
                      ) : (
                        <Button
                          component={Link}
                          href={`/new-story?assignment_id=${assignment.id}`}
                          variant="primary-mini"
                        >
                          Create Story
                        </Button>
                      )}
                    </div>
                    <div className="text-sm text-gray-500">
                      {assignment.details}
                    </div>

                    {assignment.viewerStory ? (
                      <div className="flex items-center space-x-2 mt-2">
                        {assignment.viewerStory.submittedAt ? (
                          <div>Submitted</div>
                        ) : (
                          <Button
                            variant="danger-mini"
                            onClick={() =>
                              submitAssignment(assignment.viewerStory)
                            }
                          >
                            Submit Assignment
                          </Button>
                        )}
                        {assignment.viewerStory.isPublished ? (
                          <Button
                            component={Link}
                            variant="secondary-mini"
                            href={`/assignments/${assignment.id}/peer`}
                          >
                            Peer Evaluation
                          </Button>
                        ) : null}
                      </div>
                    ) : null}
                  </div>
                ))}
              </div>
            </Card>
          ))}
        </div>
      ) : (
        <Card>
          <Placeholder title="Not available" />
        </Card>
      )}
    </Container>
  );
}

const GET_STORIES = gql`
  query GetStories {
    viewer {
      classrooms {
        id
        name
        details
        viewer {
          id
          role
        }
        assignments {
          id
          title
          details
          viewerStory {
            id
            title
            submittedAt
            isPublished
          }
        }
      }
    }
  }
`;

const SUBMIT_STORY = gql`
  mutation SubmitStory($input: SubmitStoryInput!) {
    submitStory(input: $input) {
      id
      assignment {
        id
      }
    }
  }
`;
