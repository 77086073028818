import React from "react";
import { Form, FormSubmit } from "@swiftcarrot/react-form";
import { gql, useMutation } from "@apollo/client";
import { useAuth } from "@swiftcarrot/react-auth";
import { useLocation } from "@swiftcarrot/react-router";
import { FormGroup, Button } from "components";
import { captureException } from "src/sentry";
import { analytics } from "src/analytics";

export default function RegisterForm({ token }) {
  const [, navigate] = useLocation();
  const { setCurrentUser } = useAuth();
  const [register] = useMutation(REGISTER);

  function validate(values) {
    const errors = {};

    if (!values.name.trim()) {
      errors.name = "Name cannot be blank";
    }

    if (!values.email.trim()) {
      errors.email = "Email cannot be blank";
    }

    if (!values.token.trim()) {
      errors.token = "Token cannot be blank";
    }

    if (!values.password.trim()) {
      errors.Password = "Password cannot be blank";
    }

    if (values.confirm_password !== values.password) {
      errors.confirm_password = "Confirm password is not same as password";
    }

    return errors;
  }

  function handleSubmit({ confirm_password, ...values }) {
    return register({ variables: { input: values } })
      .then((resp) => {
        const { token, user } = resp.data.register;
        analytics.identify(user.id);
        analytics.track("Register", {
          name: values.name,
          email: values.email,
          token: values.token,
        });
        window.localStorage.setItem("token", token);
        setCurrentUser(user);
        navigate("/dashboard", { replace: true });
      })
      .catch((err) => {
        captureException(err);
        alert("Register failed");
      });
  }

  // TODO: add password confirm input
  return (
    <Form
      initialValues={{
        name: "",
        email: "",
        password: "",
        confirm_password: "",
        token: token || "",
      }}
      onSubmit={handleSubmit}
      validate={validate}
    >
      <FormGroup name="name" label="Name" />
      <FormGroup name="email" label="Email" />
      <FormGroup name="password" label="Password" type="password" />
      <FormGroup
        name="confirm_password"
        label="Confirm Password"
        type="password"
      />
      <FormGroup name="token" label="Invitation" type="password" />
      <div className="form-submit">
        <FormSubmit component={Button} variant="primary">
          Create Account
        </FormSubmit>
      </div>
    </Form>
  );
}

const REGISTER = gql`
  mutation Register($input: RegisterInput!) {
    register(input: $input) {
      user {
        id
        name
        email
        isAdmin
        avatarURL
      }
      token
    }
  }
`;
