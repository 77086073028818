import React from "react";
import {
  DismissButton,
  Overlay,
  usePopover,
  useOverlayTrigger,
} from "react-aria";
import { useOverlayTriggerState } from "react-stately";
import { Button } from "./Button";

export const Popover = ({ children, state, offset = 8, ...props }) => {
  let ref = React.useRef();
  let { popoverProps, underlayProps } = usePopover(
    {
      ...props,
      offset,
      popoverRef: ref,
    },
    state
  );

  return (
    <Overlay>
      <div {...underlayProps} className="fixed inset-0" />
      <div
        {...popoverProps}
        ref={ref}
        className="z-10 shadow-lg border border-gray-300 bg-white rounded-md mt-2"
      >
        <DismissButton onDismiss={state.close} />
        {children}
      </div>
    </Overlay>
  );
};

export const PopoverTrigger = ({ label, children, className, ...props }) => {
  let ref = React.useRef();
  let state = useOverlayTriggerState(props);
  let { triggerProps, overlayProps } = useOverlayTrigger(
    { type: "dialog" },
    state,
    ref
  );

  return (
    <>
      <Button {...triggerProps} buttonRef={ref} className={className}>
        {label}
      </Button>
      {state.isOpen && (
        <Popover {...props} triggerRef={ref} state={state}>
          {React.cloneElement(children, overlayProps)}
        </Popover>
      )}
    </>
  );
};
