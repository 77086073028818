import React from "react";
import { useSearchParams } from "@swiftcarrot/react-router";
import { gql, useQuery } from "@apollo/client";
import { Loading } from "components";
import Review from "src/components/Review";
import { useAnalyticsPage } from "src/analytics";

// TODO: remove this page
export default function Page() {
  const [params] = useSearchParams();
  const id = params.get("id");
  const { data, loading, error } = useQuery(GET_STORY, { variables: { id } });
  useAnalyticsPage("Review", { story_id: id });

  if (loading) return <Loading />;
  if (error) {
    return <div>Network Error</div>;
  }

  return <Review story={data.story} />;
}

const GET_STORY = gql`
  query Story($id: ID!) {
    story(id: $id) {
      id
      title
      viewerReview {
        id
        content {
          rating1
          comment1
          rating2
          comment2
          rating3
          comment3
          rating4
          comment4
          rating5
          comment5
        }
      }
      user {
        id
        name
      }
      scenes {
        id
        imageURL
        audioURL
        volume
        entities {
          id
          type
          title
          point {
            x
            y
            z
          }
          text
          imageURL
          audioURL
          scene
          volume
        }
      }
    }
  }
`;
