import React, { useEffect } from "react";
import { gql, useQuery } from "@apollo/client";
import { AspectRatio, Loading } from "components";
import { Container, Row, Col } from "react-grid";
import { imageURL } from "src/utils";
import { STORIES } from "./discover";

const STORY_TITLES = STORIES.reduce((x, s) => ({ ...x, [s.id]: s.title }), {});

export default function Page() {
  const { data, loading, error } = useQuery(QUERY, {
    variables: { ids: STORIES.map((s) => s.id) },
  });

  useEffect(() => {
    document.body.style.overscrollBehaviorY = "auto";

    return () => {
      document.body.style.overscrollBehaviorY = "";
    };
  }, []);

  return (
    <Container>
      <h1
        style={{
          fontWeight: "400",
          fontSize: 38,
          lineHeight: 1,
          marginBottom: 6,
        }}
      >
        Our Metaverse
      </h1>

      {loading ? (
        <Loading />
      ) : error ? (
        <div>Network Errror</div>
      ) : (
        <div>
          <div
            style={{
              marginBottom: 25,
              fontSize: 16,
              color: "#666",
              marginLeft: 4,
            }}
          >
            Click image to view VR scenes
          </div>
          <Row>
            {data.stories.map((story) => (
              <Col md={6} key={story.id}>
                <a
                  href={`/watch?id=${story.id}`}
                  target="_blank"
                  className="showcase-item"
                >
                  <AspectRatio ratio="2x1">
                    <img
                      alt={STORY_TITLES[story.id]}
                      src={imageURL(story.scenes[0].image.url, {
                        width: 600,
                        height: 600,
                      })}
                    />
                  </AspectRatio>
                  <div className="showcase-item-title">
                    {STORY_TITLES[story.id]}
                  </div>
                </a>
              </Col>
            ))}
          </Row>
        </div>
      )}

      <div
        style={{
          marginTop: 4,
          paddingTop: 24,
          marginBottom: 36,
          color: "#666",
          fontSize: 12,
          borderTop: "1px solid #eaeaea",
        }}
      >
        CCMIR ◎ HKU Culture Computing and Multimodal Information Research
      </div>
    </Container>
  );
}

export const QUERY = gql`
  query Showcase($ids: [ID!]!) {
    stories(ids: $ids) {
      id
      title
      scenes {
        id
        image {
          key
          url
        }
      }
    }
  }
`;
