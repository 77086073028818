import React from "react";
import { gql, useQuery } from "@apollo/client";
import { Loading } from "components";
import { ImageGeneration } from "@modalityml/react";
import { analytics } from "src/analytics";

export default function Page() {
  const { loading, data, error } = useQuery(AUTHORIZE_CHAT);

  return loading ? (
    <Loading />
  ) : error ? (
    <div>Network Error</div>
  ) : (
    <div className="px-3">
      <ImageGeneration
        userToken={data.authorizeChat.token}
        onDownload={(key) => analytics.track("Download Image", { key })}
      />
    </div>
  );
}

const AUTHORIZE_CHAT = gql`
  query AuthorizeChat {
    authorizeChat {
      token
    }
  }
`;
