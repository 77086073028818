import styles from "./Button.module.scss";
import cx from "clsx";
import React from "react";
import { cva } from "class-variance-authority";

// TODO:
const button = cva([], {
  variants: {
    variant: {
      "primary-mini": [],
    },
  },
});

export const Button = ({ component, variant, type, className, ...props }) => {
  return React.createElement(component, {
    ...props,
    type,
    className: cx(
      styles.button,
      styles[variant],
      button({ variant }),
      className
    ),
  });
};

Button.defaultProps = {
  type: "button",
  component: "button",
  variant: "primary",
};
