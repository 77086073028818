import React, { useRef, useState } from "react";
import { useDebounce } from "react-use";
import { gql, useMutation } from "@apollo/client";
import { isEqual, pick, debounce } from "lodash-es";
import { analytics } from "src/analytics";
import { captureException } from "src/sentry";
import { toast } from "components";

const ENTITY_FIELDS = [
  "id",
  "type",
  "title",
  "point.x",
  "point.y",
  "point.z",
  "text",
  "image.key",
  "audio.key",
  "volume",
  "scene",
];

// TODO: move to hook or analytics
const debouncedTrack = debounce((...args) => {
  analytics.track(...args);
}, 500);

function toastPromise(p) {
  return toast.promise(
    p
      .then(() => {
        // TODO: before leave check
      })
      .catch((err) => {
        // TODO: render sync error
        // alert("Sync error");
        captureException(err);
      }),
    {
      loading: "Saving story...",
      success: <b>Story Saved</b>,
      error: <b>Network Error</b>,
    }
  );
}

export function useEditor({ story: editorStory }) {
  const [updateEditorStory] = useMutation(UPDATE_STORY);
  const [updateScene] = useMutation(UPDATE_SCENE);
  const [createScene] = useMutation(CREATE_SCENE);
  const [deleteScene] = useMutation(DELETE_SCENE);
  const [updateSceneHome] = useMutation(UPDATE_SCENE_HOME);

  const input = useRef();
  const sceneRef = useRef();
  const [story, setStory] = useState(editorStory);

  // TODO: fix entity type
  // story.scenes.forEach((scene) => {
  //   scene.entities.forEach((entity) => {
  //     if (entity.type !== "scene") {
  //       entity.type = "media";
  //     }
  //   });
  // });

  const [activeEntityID, setActiveEntityID] = useState(null);
  const [allEntities, setAllEntities] = useState(
    story.scenes.reduce((x, scene) => {
      x[scene.id] = scene.entities;
      return x;
    }, {})
  );
  const [points, setPoints] = useState({});
  const [scenes, setScenes] = useState(story.scenes);
  const [activeSceneID, setActiveSceneID] = useState(story.scenes[0].id);
  const scene = scenes.filter((d) => d.id === activeSceneID)[0];
  const entities = allEntities[activeSceneID];
  const entity = entities.filter((d) => d.id === activeEntityID)[0];

  // TODO: catch error for toastpromise
  function updateStory(values) {
    return toastPromise(
      updateEditorStory({
        variables: { input: { ...values, id: story.id } },
      }).then(() => {
        setStory({ ...story, ...values });
      })
    );
  }

  function updateNarrationScript(values) {
    analytics.track("Update Narration Script", {
      ...values,
      story_id: story.id,
    });

    return toastPromise(
      updateEditorStory({
        variables: { input: { ...values, id: story.id } },
      }).then(() => {
        setStory({ ...story, ...values });
      })
    );
  }

  // TODO: add toast to all network request promises
  function addScene() {
    analytics.track("Add Scene", { story_id: story.id });

    return toastPromise(
      createScene({ variables: { input: { storyID: story.id } } }).then(
        (resp) => {
          const scene = resp.data.createScene;
          setScenes((scenes) => [...scenes, scene]);
          setAllEntities({ ...allEntities, [scene.id]: scene.entities });
          setActiveSceneID(scene.id);
        }
      )
    );
  }

  function updateSceneImage(scene, image) {
    analytics.track("Update Scene Image", {
      story_id: story.id,
      scene_id: scene.id,
      image: image.key,
    });

    setScenes((scenes) =>
      scenes.map((s) => (s.id === scene.id ? { ...s, imageURL: image.url } : s))
    );

    return toastPromise(
      updateScene({
        variables: { input: { id: scene.id, image: image.key } },
      })
    );
  }

  function updateSceneMusicAudio(scene, audio) {
    analytics.track("Update Scene Music Audio", {
      story_id: story.id,
      scene_id: scene.id,
      audio: audio.key,
    });

    setScenes((scenes) =>
      scenes.map((s) => (s.id === scene.id ? { ...s, musicURL: audio.url } : s))
    );

    return toastPromise(
      updateScene({
        variables: { input: { id: scene.id, musicAudio: audio.key } },
      })
    );
  }

  function updateSceneNarrationAudio(scene, audio) {
    analytics.track("Update Scene Narration Audio", {
      story_id: story.id,
      scene_id: scene.id,
      audio: audio.key,
    });

    setScenes((scenes) =>
      scenes.map((s) =>
        s.id === scene.id ? { ...s, narrationURL: audio.url } : s
      )
    );

    return toastPromise(
      updateScene({
        variables: {
          input: { id: scene.id, narrationAudio: audio.key },
        },
      })
    );
  }

  function updateSceneMusicVolume(scene, volume) {
    debouncedTrack("Update Scene Music Volume", {
      story_id: story.id,
      scene_id: scene.id,
      volume,
    });

    setScenes((scenes) =>
      scenes.map((s) => (s.id === scene.id ? { ...s, musicVolume: volume } : s))
    );
  }

  function updateSceneNarrationVolume(scene, volume) {
    debouncedTrack("Update Scene Narration Volume", {
      story_id: story.id,
      scene_id: scene.id,
      volume,
    });

    setScenes((scenes) =>
      scenes.map((s) =>
        s.id === scene.id ? { ...s, narrationVolume: volume } : s
      )
    );
  }

  function updateEntityAudioVolume(entity, volume) {
    debouncedTrack("Update Object Audio Volume", {
      story_id: story.id,
      scene_id: scene.id,
      object_id: entity.id,
      volume,
    });

    setAllEntities({
      ...allEntities,
      [activeSceneID]: entities.map((d) =>
        d.id === entity.id ? { ...d, volume } : d
      ),
    });
  }

  function deleteSceneMusicAudio(scene) {
    analytics.track("Delete Scene Music Audio", {
      story_id: story.id,
      scene_id: scene.id,
    });

    setScenes((scenes) =>
      scenes.map((s) => (s.id === scene.id ? { ...s, musicURL: null } : s))
    );

    return toastPromise(
      updateScene({
        variables: { input: { id: scene.id, musicAudio: "" } },
      })
    );
  }

  function deleteSceneNarrationAudio(scene) {
    analytics.track("Delete Scene Narration Audio", {
      story_id: story.id,
      scene_id: scene.id,
    });

    setScenes((scenes) =>
      scenes.map((s) => (s.id === scene.id ? { ...s, narrationURL: null } : s))
    );

    return toastPromise(
      updateScene({
        variables: { input: { id: scene.id, narrationAudio: "" } },
      })
    );
  }

  function removeScene(scene) {
    analytics.track("Delete Scene", { story_id: story.id, scene_id: scene.id });

    if (
      // TODO: use dialog
      window.confirm("Are you sure you want to permanently delete this scene?")
    ) {
      toastPromise(
        deleteScene({ variables: { input: { id: scene.id } } }).then(() => {
          const newScenes = scenes.filter((s) => s.id !== scene.id);
          setActiveSceneID(newScenes[0].id);
          setScenes(newScenes);
        })
      );
    }
  }

  function setSceneHome(scene) {
    analytics.track("Set Scene Home", {
      story_id: story.id,
      scene_id: scene.id,
    });

    return toastPromise(
      updateSceneHome({ variables: { input: { id: scene.id } } }).then(() => {
        const updated = scenes.map((s) => ({
          ...s,
          isHome: s.id === scene.id,
        }));
        updated.sort((a, b) => {
          if (a.isHome) return -1;
          if (b.isHome) return 1;
          return 0;
        });
        setScenes(updated);
      })
    );
  }

  function addEntity(id, point) {
    analytics.track("Add Object", {
      story_id: story.id,
      scene_id: scene.id,
      object_id: id,
      point: point,
    });

    const entity = {
      id,
      point,
      title: `Object #${entities.length + 1}`,
      type: "media",
    };
    setAllEntities({
      ...allEntities,
      [activeSceneID]: [...allEntities[activeSceneID], entity],
    });
    setActiveEntityID(entity.id);
  }

  function updateEntity(id, changes) {
    analytics.track("Update Object", {
      story_id: story.id,
      scene_id: scene.id,
      object_id: id,
      object: changes,
    });

    setAllEntities({
      ...allEntities,
      [activeSceneID]: entities.map((d) =>
        d.id === id ? { ...d, ...changes } : d
      ),
    });
  }

  function deleteActiveEntity() {
    analytics.track("Delete Object", {
      story_id: story.id,
      scene_id: scene.id,
      object_id: entity.id,
      object: entity,
    });

    setAllEntities({
      ...allEntities,
      [activeSceneID]: entities.filter((d) => d.id !== entity.id),
    });
  }

  useDebounce(
    () => {
      const data = entities.map((d) => {
        d = pick(d, ENTITY_FIELDS);
        d.image = d.image ? d.image.key : null;
        d.audio = d.audio ? d.audio.key : null;
        return d;
      });
      if (
        input.current &&
        input.current.sceneID === scene.id &&
        !isEqual(input.current.data, data)
      ) {
        toastPromise(
          updateScene({
            variables: { input: { id: activeSceneID, entities: data } },
          })
        );
      }
      input.current = { sceneID: scene.id, data };
    },
    500,
    [entities]
  );

  useDebounce(
    () => {
      const data = pick(scene, "musicVolume", "narrationVolume");

      if (
        sceneRef.current &&
        sceneRef.current.sceneID === scene.id &&
        !isEqual(sceneRef.current.data, data)
      ) {
        toastPromise(
          updateScene({
            variables: { input: { id: activeSceneID, ...data } },
          })
        );
      }

      sceneRef.current = { sceneID: scene.id, data };
    },
    500,
    [scene]
  );

  // TODO: return { state, methods }
  return {
    allEntities,
    scenes,
    scene,
    entities,
    entity,
    story,
    setStory,
    updateStory,
    updateNarrationScript,

    points,
    activeEntityID,
    setActiveEntityID,
    setAllEntities,
    setPoints,
    setScenes,
    activeSceneID,
    setActiveSceneID,

    addScene,
    removeScene,
    setSceneHome,

    updateSceneImage,

    updateSceneMusicAudio,
    deleteSceneMusicAudio,
    updateSceneMusicVolume,

    updateSceneNarrationAudio,
    deleteSceneNarrationAudio,
    updateSceneNarrationVolume,

    addEntity,
    updateEntity,
    deleteActiveEntity,
    updateEntityAudioVolume,
  };
}

const UPDATE_STORY = gql`
  mutation UpdateStory($input: UpdateStoryInput!) {
    updateStory(input: $input) {
      id
    }
  }
`;

const UPDATE_SCENE = gql`
  mutation UpdateScene($input: UpdateSceneInput!) {
    updateScene(input: $input) {
      id
    }
  }
`;

const CREATE_SCENE = gql`
  mutation CreateScene($input: CreateSceneInput!) {
    createScene(input: $input) {
      id
      imageURL
      musicURL
      narrationURL
      entities {
        id
      }
    }
  }
`;

const DELETE_SCENE = gql`
  mutation DeleteScene($input: DeleteSceneInput!) {
    deleteScene(input: $input) {
      id
    }
  }
`;

const UPDATE_SCENE_HOME = gql`
  mutation UpdateSceneHome($input: UpdateSceneHomeInput!) {
    updateSceneHome(input: $input) {
      id
    }
  }
`;
