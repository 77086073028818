import styles from "./StoryItem.module.scss";
import React from "react";
import { Button, Card } from "components";
import UserAvatar from "./UserAvatar";

export default function StoryItem({ story, canReview, children, onReview }) {
  return (
    <Card
      title={
        <div className={styles.head}>
          <UserAvatar user={story.user} />
          <div className={styles.user}>
            <div className={styles.name}>{story.user.name}</div>
          </div>
        </div>
      }
      extra={
        <div className={styles.buttons}>
          {canReview ? (
            <Button variant="secondary-small" onClick={onReview}>
              {story.viewerReview ? "Edit Review" : "Create Review"}
            </Button>
          ) : null}
          <Button
            variant="secondary-small"
            component="a"
            href={`/watch?id=${story.id}`}
            target="_blank"
          >
            Watch
          </Button>
        </div>
      }
    >
      {story.title}
      {children}
    </Card>
  );
}
