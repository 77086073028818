import styles from "./Select.module.scss";
import cx from "clsx";
import React, { Fragment } from "react";

export default function Select({
  className,
  onChange,
  includeBlank,
  children,
  ...props
}) {
  function handleChange(e) {
    if (onChange) {
      onChange(e.target.value);
    }
  }

  return (
    <div
      className={cx(
        "border bg-white w-full rounded relative inline-block",
        styles.container,
        className
      )}
    >
      <select className={styles.select} onChange={handleChange} {...props}>
        {includeBlank ? (
          <Fragment>
            <option value="" />
            {children}
          </Fragment>
        ) : (
          children
        )}
      </select>
      <Caret />
    </div>
  );
}

function Caret() {
  return (
    <svg viewBox="0 0 16 16" className={styles.caret}>
      <path
        d="M12 6.5c0-.28-.22-.5-.5-.5h-7a.495.495 0 0 0-.37.83l3.5 4c.09.1.22.17.37.17s.28-.07.37-.17l3.5-4c.08-.09.13-.2.13-.33z"
        fillRule="evenodd"
      />
    </svg>
  );
}
