// TODO: import from vendor
// import * as THREE from "three";
import { v4 as uuid } from "uuid";
import { useEffect, useRef } from "react";
import { forEach, mapValues } from "lodash-es";
import { captureException } from "src/sentry";
import { imageURL } from "src/utils";

const THREE = window.THREE;
const RADIUS = 500;

function loadImage(url) {
  return fetch(url, {
    headers:
      process.env.NODE_ENV === "production"
        ? {} // on production, it loads image from the same origin
        : {
            "cache-control": "no-cache", // disable cache for cors
          },
  })
    .then((resp) => resp.blob())
    .then((blob) => URL.createObjectURL(blob));
}

// TODO: improve data sync between react state and renderer
export function useRenderer(
  divRef,
  image,
  entities,
  { width, height, onNewPoint, onPointsChange }
) {
  const ref = useRef();

  useEffect(() => {
    if (ref.current) {
      ref.current.destroy();
    }

    if (image) {
      // TODO: add loading indicator
      loadImage(imageURL(image, { width: 3840, height: 2160 }))
        .then((src) => {
          const points = entities.reduce((x, d) => {
            x[d.id] = {
              point: d.point, // TODO: __typename
            };
            return x;
          }, {});

          const renderer = new Renderer({
            width,
            height,
            src,
            points,
            onNewPoint,
            onPointsChange,
          });

          renderer.attach(divRef.current);
          renderer.animate();
          renderer.updatePointsOnScreen();

          ref.current = renderer;
        })
        .catch((err) => {
          // TODO: handler error
          // alert("Network error");
          captureException(err);
        });
    }
  }, [image]);

  // TODO: fix renderer hook
  useEffect(() => {
    if (ref.current) {
      ref.current.onNewPoint = onNewPoint;
    }
  }, [onNewPoint]);

  useEffect(() => {
    if (ref.current) {
      // TODO: should throttle??
      ref.current.updateSize(width, height);
    }
  }, [width, height]);

  return ref;
}

export default class Renderer {
  constructor({ width, height, src, points, onNewPoint, onPointsChange }) {
    this.onNewPoint = onNewPoint;
    this.onPointsChange = () => onPointsChange({ ...this.points });
    this.width = width;
    this.height = height;
    this.src = src;

    const r = width / height;
    const camera = new THREE.PerspectiveCamera(50, r, 1, 2 * RADIUS);
    const scene = new THREE.Scene();
    const geometry = new THREE.SphereGeometry(RADIUS, 60, 40);
    // invert the geometry on the x-axis so that all of the faces point inward
    geometry.scale(-1, 1, 1);

    const loader = new THREE.TextureLoader();
    const texture = loader.load(src); // TODO: error handling
    const material = new THREE.MeshBasicMaterial({ map: texture });
    const sphere = new THREE.Mesh(geometry, material);
    scene.add(sphere);

    const raycaster = new THREE.Raycaster();
    const renderer = new THREE.WebGLRenderer();
    renderer.setPixelRatio(window.devicePixelRatio);
    renderer.setSize(width, height);

    this.scene = scene;
    this.camera = camera;
    this.renderer = renderer;
    this.raycaster = raycaster;
    this.mouse = new THREE.Vector2();

    this.isDragging = false;
    this.onPointerDownMouseX = 0;
    this.onPointerDownMouseY = 0;
    this.lng = 0;
    this.onPointerDownLng = 0;
    this.lat = 0;
    this.onPointerDownLat = 0;
    this.phi = 0;
    this.theta = 0;

    this.points = mapValues(points, (d) => ({
      point: d.point,
      screen: this.point2Screen(d.point),
    }));

    this.onPointsChange();
  }

  attach(container) {
    const canvas = this.renderer.domElement;
    container.appendChild(canvas);

    this.canvas = canvas;
    this.containerRect = container.getBoundingClientRect();

    container.style.touchAction = "none";

    // TODO: pointer events or touch events
    container.addEventListener("dblclick", this.handleClick);
    container.addEventListener("pointerdown", this.handleDragStart);

    // TODO: zoom control
    // document.addEventListener('wheel', onDocumentMouseWheel);
    // window.addEventListener("resize", onWindowResize);

    this.container = container;
  }

  // TODO: check memory leak
  destroy = () => {
    this.canvas.remove();
    this.container.removeEventListener("dblclick", this.handleClick);
    this.container.removeEventListener("pointerdown", this.handleDragStart);
    document.removeEventListener("pointermove", this.handleDrag);
    document.removeEventListener("pointerup", this.handleDragEnd);
  };

  updateSize = (width, height) => {
    this.width = width;
    this.height = height;
    this.renderer.setSize(width, height, false);
    this.camera.aspect = width / height;
    this.camera.updateProjectionMatrix();
    this.containerRect = this.container.getBoundingClientRect();

    if (this.canvas) {
      this.canvas.style.width = width + "px";
      this.canvas.style.height = height + "px";
    }

    this.updatePointsOnScreen();
  };

  handleClick = (e) => {
    if (this.isDragging) return;
    if (!this.onNewPoint) return;

    e.preventDefault();

    const containerRect = this.containerRect;
    const x = e.clientX - containerRect.left;
    const y = e.clientY - containerRect.top;

    this.mouse.x = (x / this.width) * 2 - 1;
    this.mouse.y = -(y / this.height) * 2 + 1;

    this.raycaster.setFromCamera(this.mouse, this.camera);
    const intersects = this.raycaster.intersectObjects(this.scene.children);

    if (intersects.length === 1) {
      const intersect = intersects[0];
      const id = uuid();
      const point = {
        x: intersect.point.x,
        y: intersect.point.y,
        z: intersect.point.z,
      };

      this.points[id] = {
        point,
        screen: this.point2Screen(point),
      };

      this.onNewPoint(id, point);
      this.onPointsChange();
    }
  };

  handleDragStart = (event) => {
    this.onPointerDownMouseX = event.clientX;
    this.onPointerDownMouseY = event.clientY;

    this.onPointerDownLng = this.lng;
    this.onPointerDownLat = this.lat;

    document.addEventListener("pointermove", this.handleDrag);
    document.addEventListener("pointerup", this.handleDragEnd);
  };

  handleDrag = (e) => {
    if (!this.isDragging) {
      this.isDragging = true;
    }

    this.lng =
      (this.onPointerDownMouseX - e.clientX) * 0.1 + this.onPointerDownLng;
    this.lat =
      (e.clientY - this.onPointerDownMouseY) * 0.1 + this.onPointerDownLat;
  };

  handleDragEnd = () => {
    this.isDragging = false;

    document.removeEventListener("pointermove", this.handleDrag);
    document.removeEventListener("pointerup", this.handleDragEnd);
  };

  animate = () => {
    requestAnimationFrame(this.animate);
    if (this.isDragging) {
      this.updatePointsOnScreen();
    }
    this.update();
  };

  update = () => {
    // TODO: auto mode
    //   if (this.isDragging === false) {
    //     lon += 0.1;
    //   }

    this.lat = Math.max(-85, Math.min(85, this.lat));
    const { x, y, z } = this.latLng2point(RADIUS, this.lat, this.lng);
    this.camera.lookAt(x, y, z);
    this.renderer.render(this.scene, this.camera);
  };

  updatePointsOnScreen = () => {
    forEach(this.points, (v) => {
      v.screen = this.point2Screen(v.point);
    });
    this.onPointsChange();
  };

  updateEntityPosition = (id, x, y) => {
    const p = this.points[id];
    p.point = this.screen2Point(x, y);
    p.screen = { x, y };
    this.onPointsChange();
  };

  lookAtEntity(id) {
    const p = this.points[id];
    const [lat, lng] = this.point2LatLng(p.point);

    this.lat = lat;
    this.lng = lng;
    this.update();
    this.updatePointsOnScreen();
  }

  point2Screen({ x, y, z }) {
    const p = new THREE.Vector3(x, y, z);
    const v = p.project(this.camera);
    if (v.z > 1) return null;

    return {
      x: ((v.x + 1) / 2) * this.width,
      y: -((v.y - 1) / 2) * this.height,
    };
  }

  screen2Point(x, y) {
    const v = new THREE.Vector2();
    v.x = (x / this.width) * 2 - 1;
    v.y = -(y / this.height) * 2 + 1;

    this.raycaster.setFromCamera(v, this.camera);
    const intersects = this.raycaster.intersectObjects(this.scene.children);

    if (intersects.length === 1) {
      const intersect = intersects[0];
      const { point } = intersect;

      return { x: point.x, y: point.y, z: point.z };
    }
    return null;
  }

  point2LatLng({ x, y, z }) {
    const v = new THREE.Vector3(x, y, z);
    v.normalize();
    let lng = -Math.atan2(-v.z, -v.x) - Math.PI / 2;
    if (lng < -Math.PI) lng += Math.PI * 2;
    const p = new THREE.Vector3(v.x, 0, v.z);
    p.normalize();
    let lat = Math.acos(p.dot(v));
    if (v.y < 0) lat *= -1;

    return [lat, lng];
  }

  latLng2point(radius, lat, lng) {
    const phi = THREE.MathUtils.degToRad(90 - lat);
    const theta = THREE.MathUtils.degToRad(lng);

    const x = radius * Math.sin(phi) * Math.cos(theta);
    const y = radius * Math.cos(phi);
    const z = radius * Math.sin(phi) * Math.sin(theta);
    return { x, y, z };
  }
}
