import React, { useState, Fragment, useRef } from "react";
import { Form, FormGroup, Button, FormSubmit, cx, useForm } from "components";
import { useInterval } from "react-use";
import { analytics } from "src/analytics";
import Feedback from "./Feedback";

export const NarrationScript = ({
  story,
  laEnabled,
  updateNarrationScript,
}) => {
  const [tab, setTab] = useState(0);

  function handleSubmit(values) {
    return updateNarrationScript(values);
  }

  const handlePaste = (e) => {
    const text = e.clipboardData.getData("text");

    analytics.track("Paste Narration Script", {
      story_id: story.id,
      text,
    });
  };

  return (
    <div className="p-3">
      <Form
        initialValues={{
          script: story.script,
        }}
        onSubmit={handleSubmit}
      >
        {laEnabled ? (
          <div className="tabs">
            {["Script", "Feedback"].map((d, i) => (
              <div
                key={d}
                className={cx("tab", { tabActive: tab === i })}
                onClick={() => setTab(i)}
              >
                {d}
              </div>
            ))}
          </div>
        ) : null}

        <div>
          {tab === 0 ? (
            <Fragment>
              <FormGroup
                name="script"
                multiline
                minRows={20}
                placeholder="Enter your narration script here"
                showWordCount
                onPaste={handlePaste}
              />
            </Fragment>
          ) : (
            <Feedback story={story} />
          )}
        </div>

        <Track story={story} />

        <FormSubmit
          component={Button}
          variant="primary"
          className="w-full mt-3"
        >
          Save
        </FormSubmit>
      </Form>
    </div>
  );
};

const Track = ({ story }) => {
  const { getValue } = useForm();
  const script = getValue("script");
  const ref = useRef(null);

  useInterval(() => {
    if (ref.current && ref.current !== script) {
      analytics.track("Change Narration Script", {
        script: script,
        story_id: story.id,
      });
    }
    ref.current = script;
  }, 1000);
};
