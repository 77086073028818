import clsx from "clsx";
import React from "react";
import { useTextField, useSearchField } from "react-aria";
import { useSearchFieldState } from "react-stately";

export const TextField = (props) => {
  let { label } = props;
  let ref = React.useRef();
  let { labelProps, inputProps, descriptionProps, errorMessageProps } =
    useTextField(props, ref);

  return (
    <div>
      <label {...labelProps}>{label}</label>
      <input {...inputProps} ref={ref} />
      {props.description && (
        <div {...descriptionProps} style={{ fontSize: 12 }}>
          {props.description}
        </div>
      )}
      {props.errorMessage && (
        <div {...errorMessageProps} style={{ color: "red", fontSize: 12 }}>
          {props.errorMessage}
        </div>
      )}
    </div>
  );
};

export const SearchField = (props) => {
  let { label } = props;
  let state = useSearchFieldState(props);
  let ref = React.useRef();
  let { labelProps, inputProps } = useSearchField(props, state, ref);

  return (
    <div className={clsx("flex flex-col", props.className)}>
      <label {...labelProps}>{label}</label>
      <input
        {...inputProps}
        ref={ref}
        className="w-full h-[30px] rounded-full px-4 shadow outline-sky-400 text-sm"
      />
    </div>
  );
};

export const TextArea = (props) => {
  let { label } = props;
  let ref = React.useRef();
  let { labelProps, inputProps } = useTextField(
    {
      ...props,
      inputElementType: "textarea",
    },
    ref
  );

  return (
    <div style={{ display: "flex", flexDirection: "column", width: 200 }}>
      <label {...labelProps}>{label}</label>
      <textarea {...inputProps} ref={ref} />
    </div>
  );
};
