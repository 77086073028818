import React, { useState } from "react";
import { gql, useQuery } from "@apollo/client";
import { Container, Loading, PageHeader, Link } from "components";
import StoryItem from "src/components/StoryItem";
import { analytics, useAnalyticsPage } from "src/analytics";
import ReviewContent from "src/components/ReviewContent";
import Modal from "react-modal";
import ReviewForm from "src/components/ReviewForm";

export default function Page({ params: { id } }) {
  const { loading, data, error, refetch } = useQuery(QUERY, {
    variables: { id },
  });
  const [story, setStory] = useState(null);

  useAnalyticsPage("Assignment Review Stories", { assignment_id: id });

  function reviewStory(story) {
    analytics.track("Click Review Story", { story_id: story.id });
    setStory(story);
  }

  function cancelReview() {
    analytics.track("Cancel Review Story", { story_id: story.id });
    setStory(null);
  }

  function handleSubmit() {
    analytics.track("Update Review Story", { story_id: story.id });
    setStory(null);
    refetch();
  }

  return loading ? (
    <Loading />
  ) : error ? (
    <div>Network Error</div>
  ) : (
    <Container>
      <Link href={`/assignments/${data.assignment.id}/peer`}>Back</Link>
      <PageHeader title="Review others' stories" />
      {data.assignment.stories.map((story) => (
        <StoryItem
          key={story.id}
          story={story}
          canReview
          onReview={() => reviewStory(story)}
        >
          {story.viewerReview ? (
            <ReviewContent review={story.viewerReview} />
          ) : null}
        </StoryItem>
      ))}

      <Modal
        isOpen={story ? true : false}
        className="dialog"
        overlayClassName="dialog-overlay"
      >
        {story ? (
          <ReviewForm
            story={story}
            onCancel={cancelReview}
            onSubmit={handleSubmit}
          />
        ) : null}
      </Modal>
    </Container>
  );
}

const QUERY = gql`
  query Assignment($id: ID!) {
    assignment(id: $id) {
      id
      title
      details
      stories: storiesReviewByViewer {
        id
        title
        updatedAt
        script
        user {
          id
          name
          avatarURL
        }
        viewerReview {
          id
          content {
            rating1
            comment1
            rating2
            comment2
            rating3
            comment3
            rating4
            comment4
            rating5
            comment5
          }
        }
      }
    }
  }
`;
