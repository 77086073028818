import clsx from "clsx";
import React from "react";

// TODO: tailwindcss aspect-ratio
export const AspectRatio = ({ children, ratio, ...props }) => {
  const child = React.Children.only(children);

  return (
    <div
      className="relative w-full"
      style={{
        paddingBottom: makeRatio(ratio),
      }}
      {...props}
    >
      {React.cloneElement(child, {
        ...child.props,
        className: clsx(
          child.props.className,
          "absolute w-full h-full top-0 left-0"
        ),
      })}
    </div>
  );
};

// TODO: support ratio as 16/9 ?
AspectRatio.defaultProps = {
  ratio: "1x1",
};

const RATIOS = {
  "21x9": "42.86%",
  "16x9": "56.25%",
  "4x3": "75%",
  "1x1": "100%",
};

const makeRatio = (str) => {
  if (RATIOS[str]) return RATIOS[str];

  const [p1, p2] = str.split("x");
  return `${parseFloat(((p2 / p1) * 100).toFixed(2))}%`;
};
