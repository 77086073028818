import React from "react";
import {
  FormLabel,
  FormControl,
  FormError,
  useForm,
} from "@swiftcarrot/react-form";
import TextInput from "./TextInput";
import { words } from "lodash-es";

export default function FormGroup({
  name,
  label,
  tips,
  showWordCount,
  ...props
}) {
  return (
    <div className="mb-3">
      {label ? (
        <FormLabel className="block mb-2 font-semibold" name={name}>
          {label}
        </FormLabel>
      ) : null}
      <FormControl name={name} {...props} />

      {showWordCount ? <TextWordCount name={name} /> : null}

      {tips ? <div className="mt-1 text-gray-500 text-sm">{tips}</div> : null}
      <FormError name={name} className="my-1 h-[12px] text-xs text-red-500" />
    </div>
  );
}

// TODO: may be slow
const TextWordCount = ({ name }) => {
  const { getValue } = useForm();

  const count = words(getValue(name)).length;
  return (
    <div className="text-sm text-right w-full">
      {count} {count === 1 ? "word" : "words"}
    </div>
  );
};

FormGroup.defaultProps = {
  component: TextInput,
  showWordCount: false,
};
