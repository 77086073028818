import React from "react";
import { gql, useQuery } from "@apollo/client";
import { Container, Loading, PageHeader, Link, Card, Button } from "components";
import { useAnalyticsPage } from "src/analytics";

export default function Page({ params: { id } }) {
  const { loading, data, error } = useQuery(QUERY, { variables: { id } });
  useAnalyticsPage("Assignment", { assignment_id: id });

  return loading ? (
    <Loading />
  ) : error ? (
    <div>Network Error</div>
  ) : (
    <Container>
      <Link href="/dashboard">Back to dashboard</Link>
      <PageHeader
        title={data.assignment.title}
        subtitle={data.assignment.details}
      />

      {data.assignment.viewerStory &&
      data.assignment.viewerStory.isPublished ? (
        <div className="space-y-4">
          <Card
            title="View others' stories"
            extra={
              <Button
                component={Link}
                href={`/assignments/${data.assignment.id}/stories`}
                variant="secondary-small"
              >
                View
              </Button>
            }
          >
            See others' published stories
          </Card>
          <Card
            title="Review others' stories"
            extra={
              <Button
                component={Link}
                href={`/assignments/${data.assignment.id}/review_stories`}
                variant="secondary-small"
              >
                Review
              </Button>
            }
          >
            Review others' stories from the same classroom
          </Card>
          <Card
            title="Reviews from others"
            extra={
              <Button
                component={Link}
                href={`/assignments/${data.assignment.id}/reviews`}
                variant="secondary-small"
              >
                View
              </Button>
            }
          >
            See others' reviews on my story
          </Card>
        </div>
      ) : null}
    </Container>
  );
}

const QUERY = gql`
  query Assignment($id: ID!) {
    assignment(id: $id) {
      id
      title
      details
      createdAt
      viewerStory {
        id
        title
        submittedAt
        isPublished
      }
      classroom {
        id
      }
    }
  }
`;
