import React from "react";
import { gql, useQuery } from "@apollo/client";
import { Container, Loading, PageHeader, Link } from "components";
import StoryItem from "src/components/StoryItem";
import { useAnalyticsPage } from "src/analytics";

export default function Page({ params: { id } }) {
  const { loading, data, error } = useQuery(QUERY, {
    variables: { id },
  });
  useAnalyticsPage("Assignment Stories", { assignment_id: id });

  if (loading) return <Loading />;
  if (error) return "error";

  return (
    <Container>
      <Link href={`/assignments/${data.assignment.id}/peer`}>Back</Link>
      <PageHeader title="View others' stories" />
      {data.assignment.stories.map((story) => (
        <StoryItem key={story.id} story={story} />
      ))}
    </Container>
  );
}

const QUERY = gql`
  query Assignment($id: ID!) {
    assignment(id: $id) {
      id
      title
      details
      stories: storiesForViewer {
        id
        title
        updatedAt
        user {
          id
          name
          avatarURL
        }
      }
    }
  }
`;
