import React from "react";

export default function Checkbox({ value, onChange }) {
  return (
    <input
      type="checkbox"
      checked={value}
      onChange={(e) => onChange(e.target.checked)}
    />
  );
}
