import React from "react";

// TODO: add link to instruction
export default function Footer() {
  return (
    <div className="mx-8 text-center text-gray-400 text-xs mt-5">
      CCMIR ◎ HKU Culture Computing and Multimodal Information Research
    </div>
  );
}
