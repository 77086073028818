import clsx from "clsx";
import React from "react";
import { useButton } from "react-aria";
import { cva } from "class-variance-authority";

const button = cva([], {
  variants: {
    type: {
      primary: [
        "text-white bg-cyan-600 font-medium rounded-lg text-base px-5 py-3 w-full text-center",
        "hover:bg-cyan-700 focus:ring-4 focus:ring-cyan-200",
        "sm:w-auto",
      ],
    },
  },
});

export const Button = (props) => {
  let ref = props.buttonRef;
  let { buttonProps } = useButton(props, ref);

  return (
    <button
      {...buttonProps}
      ref={ref}
      style={props.style}
      className={clsx(button({ type: props.type }), props.className)}
    >
      {props.children}
    </button>
  );
};

Button.defaultProps = {
  size: "default",
  type: "default",
  variant: "default",
  disabled: false,
};
