import styles from "./Statistics.module.scss";
import React from "react";
import { gql, useQuery } from "@apollo/client";
import { Loading } from "components";

function format(d) {
  return d.toFixed(2);
}

export default function Statistics({ story, ...props }) {
  const { data, loading, error } = useQuery(QUERY, {
    variables: { id: story.id },
  });

  return (
    <div className="p-3" {...props}>
      {loading ? (
        <Loading />
      ) : error ? (
        <div>Network error</div>
      ) : (
        <table className={styles.table}>
          <thead>
            <tr>
              <th width="300" className="text-left">
                Statistics of this VR story
              </th>
              <th className="text-center">This story</th>
              <th className="text-center">Average</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>No. of times this VR story has been viewed</td>
              <td className="text-center">{data.story.stats.d1}</td>
              <td className="text-center">{format(data.story.stats.a1)}</td>
            </tr>
            <tr>
              <td>No. of students who viewed this VR story</td>
              <td className="text-center">{data.story.stats.d2}</td>
              <td className="text-center">{format(data.story.stats.a2)}</td>
            </tr>
            <tr>
              <td>No. of students who reviewed this VR story</td>
              <td className="text-center">{data.story.stats.d3}</td>
              <td className="text-center">{format(data.story.stats.a3)}</td>
            </tr>
          </tbody>
        </table>
      )}
    </div>
  );
}

const QUERY = gql`
  query Story($id: ID!) {
    story(id: $id) {
      id
      script
      stats {
        d1
        a1
        d2
        a2
        d3
        a3
      }
    }
  }
`;
