import { ApolloClient, InMemoryCache, createHttpLink } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";

export const ENDPOINT = process.env.API_ENDPOINT || "http://localhost:9100";

const httpLink = createHttpLink({
  uri: ENDPOINT + "/graphql",
});

const authLink = setContext((_, { headers }) => {
  // TODO: use user cookie for authentication on server side
  const token =
    typeof localStorage === "undefined" ? "" : localStorage.getItem("token");
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

export const client = new ApolloClient({
  ssrMode: typeof window === "undefined",
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "network-only",
      errorPolicy: "none",
    },
    query: {
      fetchPolicy: "network-only",
      errorPolicy: "none",
    },
  },
});
