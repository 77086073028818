import React from "react";
import { get, isString } from "lodash-es";
import { Button } from "./Button";

function quote(str) {
  if (!isString(str)) return str;
  if (!str) return "";
  return `"${str.replaceAll('"', '""')}"`;
}

export default function Table({ keyExtractor, columns, data, ...props }) {
  function exportData() {
    const csv =
      columns.map((col) => col.title).join(",") +
      "\r\n" +
      data
        .map((row) => columns.map((col) => quote(get(row, col.key))).join(","))
        .join("\r\n");

    const href = "data:text/csv;charset=utf-8," + encodeURIComponent(csv);
    const link = document.createElement("a");
    link.setAttribute("href", href);
    link.setAttribute("download", "data.csv");
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  return (
    <div>
      <Button onClick={exportData}>Export Data</Button>
      <table {...props}>
        <thead>
          <tr>
            {columns.map(({ key, title, render, ...props }) => (
              <th key={key} {...props}>
                {title}
              </th>
            ))}
          </tr>
        </thead>

        <tbody>
          {data.map((row) => (
            <tr key={keyExtractor(row)}>
              {columns.map((col) => (
                <td key={col.key}>{renderCell(col, row)}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

function renderCell(col, row) {
  if (col.render) {
    return col.render(row);
  }

  return get(row, col.key);
}
