import React, { useState } from "react";
import { gql, useQuery, useMutation } from "@apollo/client";
import { Form, FormSubmit, FormControl } from "@swiftcarrot/react-form";
import { Button, Select, TextInput, Loading, toast } from "components";
import RatingInput from "src/components/RatingInput";
import { analytics } from "src/analytics";

// TODO: button fixed in the bottom
export default function Reflection({ story }) {
  const classroomID = story.assignment.classroom.id;
  const [name, setName] = useState("ccch9051-2024-week10");
  const { data, loading, error } = useQuery(QUERY, {
    variables: { classroomID, name },
  });
  const [createReflection] = useMutation(CREATE_REFLECTION);

  const handleSubmit = (values) => {
    return toast.promise(
      createReflection({
        variables: {
          input: {
            name,
            content: values,
            classroomID,
          },
        },
      }).then((resp) => {
        analytics.track("Update Reflection", {
          ...values,
          story_id: story.id,
          name,
          classroomID: classroomID,
        });
      }),
      {
        loading: "Saving...",
        success: <b>Reflection Saved</b>,
        error: <b>Network Error</b>,
      },
    );
  };

  return (
    <div className="p-3">
      <div className="mb-3">
        <Select value={name} onChange={setName}>
          {/* <option value="ccch9051-2024-week4">Week 4</option>
          <option value="ccch9051-2024-week5">Week 5</option> */}
          <option value="ccch9051-2024-week10">Week 10</option>
          <option value="ccch9051-2024-week11">Week 11</option>
        </Select>
      </div>

      {loading ? (
        <Loading />
      ) : error ? (
        <div>Network Error</div>
      ) : (
        <ReflectionForm
          form={REFLECTION_FORM[name]}
          initialValues={
            data.viewer.reflection ? data.viewer.reflection.content : {}
          }
          onSubmit={handleSubmit}
        />
      )}
    </div>
  );
}

const ReflectionForm = ({ form, ...props }) => {
  // TODO: old reflection form breaks
  let i = 0;

  return (
    <Form {...props}>
      {form.questions.map((question, index) => {
        if (question.type === "title") {
          return <div key={index}>{question.text}</div>;
        } else {
          i++;
        }

        const name = i + "";

        return (
          <div key={index} className="my-2">
            <div className="flex mb-2">
              <div className="w-5 h-5 pt-0.5 relative top-0.5 mr-1.5 rounded-full bg-gray-700 text-white text-xs font-semibold text-center">
                {i}
              </div>
              <div className="flex-1">{question.title}</div>
            </div>

            {question.type === "rating" ? (
              question.label ? (
                <div className="flex items-center px-6">
                  <div className="mr-auto max-w-[130px] text-sm">
                    {question.label}
                  </div>
                  <FormControl
                    name={name}
                    component={RatingInput}
                    min={question.min}
                    max={question.max}
                  />
                </div>
              ) : (
                <div className="space-y-4">
                  {question.labels.map((label, index) => (
                    <div key={index} className="flex items-center px-6">
                      <div className="mr-auto max-w-[330px] text-sm">
                        {label}
                      </div>
                      <FormControl
                        name={name + `_${index + 1}`}
                        component={RatingInput}
                        min={question.min}
                        max={question.max}
                      />
                    </div>
                  ))}
                </div>
              )
            ) : question.type === "select" ? (
              <FormControl
                name={name}
                component={MultipleSelect}
                options={question.options}
              />
            ) : (
              <FormControl
                name={name}
                component={TextInput}
                multiline
                minRows={3}
              />
            )}
          </div>
        );
      })}

      <FormSubmit component={Button} variant="primary" className="w-full mt-3">
        Save
      </FormSubmit>
    </Form>
  );
};

// TODO: this is "quick and dirty"
// TODO: use Checkbox
const MultipleSelect = ({ value, onChange, options }) => {
  return (
    <div className="space-y-1">
      {options.map((option) => (
        <label key={option} className="block">
          <input
            type="checkbox"
            checked={value ? value.indexOf(option) >= 0 : false}
            onChange={(e) => {
              const newValue = options.filter((o) =>
                o === option
                  ? e.target.checked
                  : value
                  ? value.indexOf(o) >= 0
                  : false,
              );
              onChange(newValue);
            }}
          />{" "}
          {option}
        </label>
      ))}
    </div>
  );
};

const QUERY = gql`
  query Reflection($classroomID: ID!, $name: String!) {
    viewer {
      id
      reflection(classroomID: $classroomID, name: $name) {
        id
        name
        content
        createdAt
        updatedAt
      }
    }
  }
`;

const CREATE_REFLECTION = gql`
  mutation CreateReflection($input: CreateReflectionInput!) {
    createReflection(input: $input) {
      id
      name
      content
    }
  }
`;

const REFLECTION_WEEK4 = {
  header: (
    <div>
      <b className="text-lg">General guidelines</b>
      <ul className="list-disc pl-7">
        <li>
          Please record both your activities (i.e., what you have done) and
          reflections (i.e., how you think and feel)
        </li>
        <li>Both positive and negative experiences are okay</li>
        <li>
          Total no. of words should be <b>at least 300 words</b>
        </li>
        <li>
          Make sure you have <b>high-quality input</b>, which would be
          considered when we assess your final VR story
        </li>
      </ul>
    </div>
  ),
  questions: [
    {
      type: "text",
      title: (
        <div>
          For <b>creating the draft VR story</b>, what have you done this week?
          <ul className="list-disc pl-7">
            <li>
              If you have changed your topic / choice of CH, please also mention
              it here and explain why.
            </li>
            <li>
              Please also name ALL <b>software, mobile apps</b>, and/or online
              tools used, and for what purpose(s). e.g.,{" "}
              <i>
                PhotoShop for photo-editing; Adobe Audition for recording
                narration; Grammarly for checking grammar
              </i>
            </li>
          </ul>
        </div>
      ),
    },
    {
      type: "text",
      title: (
        <div>
          How did you decide on the <b>textual</b>, <b>visual</b> and/or{" "}
          <b>narration</b> content of your draft VR story? (e.g.,{" "}
          <i>
            I have included [brieft summary of content], because [reasons, with
            elaboration])
          </i>
        </div>
      ),
    },
    {
      type: "text",
      title: (
        <div>
          When creating this draft VR story, have you made any other{" "}
          <b>design decisions</b>? You can give some explanation. (e.g.,{" "}
          <i>"I added [type of objects], with [purpose], because [reason].)</i>
        </div>
      ),
    },
    {
      type: "rating",
      label: (
        <div>
          Level of
          <br />
          difficulty
        </div>
      ),
      title: (
        <div>
          On a scale of 1 (very easy) to 10 (very difficult), how{" "}
          <b>easy/difficult</b> were the tasks you have done this week?
        </div>
      ),
    },
    {
      type: "rating",
      label: (
        <div>
          Level of
          <br />
          engagement
        </div>
      ),
      title: (
        <div>
          On a scale of 1 (highly disengaged) to 10 (highly engaged), how{" "}
          <b>engaged</b> were you during this experience?
        </div>
      ),
    },
    {
      type: "text",
      title: (
        <div>
          What have you learned from this experience, in terms of{" "}
          <b>cultural heritage</b>?
        </div>
      ),
    },
    {
      type: "text",
      title: (
        <div>
          What have you learned from this experience, in terms of{" "}
          <b>digital technologies</b>?
        </div>
      ),
    },
    {
      type: "text",
      title: (
        <div>
          What have you learned from this experience, in terms of{" "}
          <b>your skills/abilities</b>?
        </div>
      ),
    },
    {
      type: "text",
      title: (
        <div>
          Which aspect(s)/element(s) from this experience would be useful or
          helpful for you <b>outside this course</b> (e.g., your studies; your
          professional contexts; personal life)?
        </div>
      ),
    },
    {
      type: "text",
      title: (
        <div>
          Do you think what you have done would be helpful for the{" "}
          <b>community</b> and/or the <b>world</b>? If yes, how? If no, why not?
        </div>
      ),
    },
    {
      type: "text",
      title: <div>Overall, regarding this experience, what went well?</div>,
    },
    {
      type: "text",
      title: (
        <div>
          Overall, what could <b className="underline">you</b> have done better?
        </div>
      ),
    },
    {
      type: "select",
      title: (
        <div>
          Lastly, what was/were your <b>emotion(s)</b> during this experience?
        </div>
      ),
      options: [
        "Joy",
        "Frustration",
        "Boredom",
        "Confusion",
        "Pride",
        "Enjoyment",
        "Anxiety",
        "Shame",
        "Hopeless",
        "Curiosity",
        "Interested",
        "Calmness",
      ],
    },
    {
      type: "text",
      title: (
        <div>
          Acknowledgement: If you have used the ChatGPT (or any other generative
          AI tools) to facilitate your writing of this reflection, please
          acknowledge it here. (e.g., I used the [Tool Name] to ....)
        </div>
      ),
    },
  ],
};

const REFLECTION_WEEK5 = {
  header: (
    <div>
      <b className="text-lg">General guidelines</b>
      <ul className="list-disc pl-7">
        <li>
          Please record both your activities (i.e., what you have done) and
          reflections (i.e., how you think and feel)
        </li>
        <li>Both positive and negative experiences are okay</li>
        <li>
          Total no. of words should be <b>at least 300</b>
        </li>
        <li>
          Make sure you have <b>high-quality input</b>, which would be
          considered when we assess your final VR story
        </li>
      </ul>
    </div>
  ),
  questions: [
    {
      type: "select",
      title: (
        <div>
          Which of the following devices and/or equipment have you used to{" "}
          <b>*view*</b> your own and/or your peers' draft VR stories?
        </div>
      ),
      options: [
        "Computer (incl. Desktop and Laptop)",
        "Tablet (e.g., iPad)",
        "Mobile phone",
        "Google Cardboard",
        `VR glasses / goggles / "VR box"`,
        "Others (please specify)",
      ],
    },
    {
      type: "text",
      title: (
        <div>
          Can you describe <b>what</b> you have done, particularly{" "}
          <b>
            <i>after the peer evaluation tutorial</i>
          </b>
          ?
        </div>
      ),
    },
    {
      type: "rating",
      label: (
        <div>
          Level of
          <br />
          difficulty
        </div>
      ),
      title: (
        <div>
          On a scale of 1 (very easy) to 10 (very difficult), how{" "}
          <b>easy/difficult</b> were the tasks you have done this week?
        </div>
      ),
    },
    {
      type: "rating",
      label: (
        <div>
          Level of
          <br />
          engagement
        </div>
      ),
      title: (
        <div>
          On a scale of 1 (highly disengaged) to 10 (highly engaged), how{" "}
          <b>engaged</b> were you during this peer evaluation experience?
        </div>
      ),
    },
    {
      type: "text",
      title: (
        <div>
          What have you learned from this peer evaluation experience, in terms
          of <b>cultural heritage</b>?
        </div>
      ),
    },
    {
      type: "text",
      title: (
        <div>
          What have you learned from this peer evaluation experience, in terms
          of <b>digital technologies</b>?
        </div>
      ),
    },
    {
      type: "text",
      title: (
        <div>
          What have you learned from this peer evaluation experience, in terms
          of <b>your skills/abilities</b>?
        </div>
      ),
    },
    {
      type: "text",
      title: (
        <div>
          Which aspect(s)/element(s) from this peer evaluation experience would
          be useful or helpful for you <b>outside this course</b> (e.g., your
          studies; professional contexts; personal life)?
        </div>
      ),
    },
    {
      type: "text",
      title: (
        <div>
          Do you think what you have done (during peer evaluation) would be
          helpful for the <b>community</b> and/or the <b>world</b>? If yes, how?
          If no, why not?
        </div>
      ),
    },
    {
      type: "text",
      title: (
        <div>
          Overall, regarding this peer evaluation experience, what went well?
        </div>
      ),
    },
    {
      type: "text",
      title: (
        <div>
          Overall, for this peer evaluation experience, what could <b>you</b>{" "}
          have done better?
        </div>
      ),
    },
    {
      type: "select",
      title: (
        <div>
          Overall, what was/were your <b>emotion(s)</b> during this{" "}
          <i>peer evaluation</i> experience?
        </div>
      ),
      options: [
        "Joy",
        "Frustration",
        "Boredom",
        "Confusion",
        "Pride",
        "Enjoyment",
        "Anxiety",
        "Shame",
        "Hopeless",
        "Curiosity",
        "Interested",
        "Calmness",
      ],
    },
    {
      type: "text",
      title: (
        <div>
          Acknowledgement: If you have used the ChatGPT (or any other generative
          AI tools) to facilitate your writing of this reflection, please
          acknowledge it here. (e.g., I used the [Tool Name] to ....)
        </div>
      ),
    },
  ],
};

export const REFLECTION_FORM = {
  "ccch9051-2024-week4": REFLECTION_WEEK4,
  "ccch9051-2024-week5": REFLECTION_WEEK5,
  "ccch9051-2024-week10": REFLECTION_WEEK4,
  "ccch9051-2024-week11": REFLECTION_WEEK5,
};
