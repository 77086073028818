import React, { Fragment, useEffect, useState } from "react";
import { Loading, Audio, Button } from "components";
import { search } from "../utils";
import { SongItem } from "./SongItem";
import { ScrollView } from "./ScrollView";
import { analytics } from "ccch9051/src/analytics";
import { captureException } from "ccch9051/src/sentry";

export const SearchResult = ({ params, onChange, story, scene, source }) => {
  // TODO: use list selection
  const [activeIndex, setActiveIndex] = useState(null);
  const [scrollTop, setScrollTop] = useState(0);

  // TODO: optimize state for list
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [items, setItems] = useState([]);
  // TODO: useAsyncList from react-aria
  const activeSong = activeIndex >= 0 ? items[activeIndex] : null;
  const [submitting, setSubmitting] = useState(false);

  const play = (index) => {
    const song = items[index];
    analytics.track("BGM Play Song " + source, {
      story_id: story.id,
      scene_id: scene.id,
      song: song,
      index: index,
    });

    setActiveIndex(index);
  };

  const pause = () => {
    analytics.track("BGM Pause Song " + source, {
      story_id: story.id,
      scene_id: scene.id,
    });

    setActiveIndex(null);
  };

  const select = () => {
    if (submitting) return;
    const song = items[activeIndex];

    analytics.track("BGM Select Song " + source, {
      story_id: story.id,
      scene_id: scene.id,
      song: song,
    });

    setSubmitting(true);
    onChange(song).then(() => {
      setSubmitting(false);
    });
  };

  // TODO: combine reload and loadMore
  const reload = () => {
    setActiveIndex(null);
    setScrollTop((t) => t + 1);

    setLoading(true);

    const reloadParams = {
      q: params.q,
      genres: Array.from(params.genres),
      moods: Array.from(params.moods),
      instruments: Array.from(params.instruments),
      themes: Array.from(params.themes),
      page: 1,
    };

    analytics.track("Music Reload " + source, {
      story_id: story.id,
      scene_id: scene.id,
      parmas: reloadParams,
    });

    search(reloadParams)
      .then((data) => {
        setData(data);
        setItems(data.hits);
        setLoading(false);
      })
      .catch((err) => {
        captureException(err);
      });
  };

  const loadMore = (page) => {
    setLoading(true);

    const loadMoreParams = {
      q: params.q,
      genres: Array.from(params.genres),
      moods: Array.from(params.moods),
      instruments: Array.from(params.instruments),
      themes: Array.from(params.themes),
      page,
    };

    analytics.track("Music Load More " + source, {
      story_id: story.id,
      scene_id: scene.id,
      params: loadMoreParams,
    });

    search(loadMoreParams)
      .then((data) => {
        setData(data);
        setItems(items.concat(data.hits));
        setLoading(false);
      })
      .catch((err) => {
        captureException(err);
      });
  };

  const handleEndReached = () => {
    if (!loading && data && data.hasNextPage) {
      loadMore(data.page + 1);
    }
  };

  useEffect(() => {
    reload();
  }, [params]);

  // TODO: better audio player with controls
  // const next = () => {
  //   setActiveSong(activeSong + 1);
  // };
  // const prev = () => {
  //   setActiveSong(activeSong - 1);
  // };

  // TODO: <Loading /> ?
  return (
    <Fragment>
      <ScrollView
        className="flex-1 overflow-y-auto"
        onEndReached={handleEndReached}
        scrollTop={scrollTop}
      >
        {items.map((song, index) => (
          <SongItem
            key={song.id}
            song={song}
            onPlay={() => play(index)}
            onPause={() => pause()}
            isActive={activeIndex === index}
          />
        ))}
      </ScrollView>

      {activeSong ? (
        <div className="p-2 border-t">
          <div className="flex items-center mb-2">
            <div className="flex-1 min-w-0 break-words">{activeSong.title}</div>
            <Button onClick={select} variant="primary-mini">
              {submitting ? "Uploading..." : "Select"}
            </Button>
          </div>
          <Audio src={activeSong.stream_url} autoPlay />
        </div>
      ) : null}
    </Fragment>
  );
};
