import styles from "./Review.module.scss";
import React from "react";
import ReviewForm from "src/components/ReviewForm";
import { useWindowSize } from "react-use";
import Preview from "src/components/Preview";

const SIDEBAR_WIDTH = 500;

export default function Review({ story }) {
  const { width, height } = useWindowSize();
  const canvasWidth = width - SIDEBAR_WIDTH;
  const canvasHeight = height;

  return (
    <div className={styles.container} style={{ width, height }}>
      <Preview story={story} width={canvasWidth} height={canvasHeight} />
      <div className={styles.sidebar} style={{ width: SIDEBAR_WIDTH, height }}>
        <div className={styles.title}>
          Your feedback for {story.user.name}'s VR story
        </div>
        <div className={styles.content}>
          <ReviewForm story={story} />
        </div>
      </div>
    </div>
  );
}
