import React, { useEffect, useState } from "react";
import { gql, useQuery } from "@apollo/client";
import { Loading } from "components";
import { Chat } from "@modalityml/react";
import { useAuth } from "@swiftcarrot/react-auth";
import { useWindowSize } from "react-use";
import cx from "clsx";

export default function Page() {
  const { currentUser } = useAuth();
  const { loading, data, error } = useQuery(AUTHORIZE_CHAT);
  const { height } = useWindowSize();

  return loading ? (
    <Loading />
  ) : error ? (
    <div>Network Error</div>
  ) : (
    <div style={{ height: height - 200 }}>
      <ChatApp token={data.authorizeChat.token} currentUser={currentUser} />
    </div>
  );
}

const ChatApp = ({ token, currentUser }) => {
  const [sessions, setSessions] = useState([]);
  const [index, setIndex] = useState(0);

  useEffect(() => {
    Promise.all([
      fetch(`https://api.modalityml.com/v1/chat/sessions`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          key: `${currentUser.id}-gpt3`,
          model: "gpt-3.5-turbo-0125",
          title: "GPT-3.5",
        }),
      }).then((r) => r.json()),
      fetch(`https://api.modalityml.com/v1/chat/sessions`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          key: `${currentUser.id}-gpt4`,
          model: "gpt-4-0613",
          title: "GPT-4",
        }),
      }).then((r) => r.json()),
    ]).then((sessions) => {
      setSessions(sessions);
    });
  }, []);

  return (
    <div className="flex h-full w-full pt-[48px] sm:pt-0">
      {sessions.length ? (
        <div className="flex flex-col h-full w-full chatgpt">
          <div className="tabs">
            {sessions.map((session, i) => (
              <div
                key={session.id}
                className={cx("tab", i === index && "tabActive")}
                onClick={() => setIndex(i)}
              >
                {session.title}
              </div>
            ))}
          </div>
          <Chat userToken={token} sessionId={sessions[index].key} />
        </div>
      ) : (
        <Loading />
      )}
    </div>
  );
};

const AUTHORIZE_CHAT = gql`
  query AuthorizeChat {
    authorizeChat {
      token
    }
  }
`;
