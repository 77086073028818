import React from "react";

export default function ReviewContent({ review }) {
  return (
    <div>
      <b>Content</b>
      <div>{review.content.rating1}</div>
      <div>{review.content.comment1}</div>
      <b>Knowledge Application</b>
      <div>{review.content.rating2}</div>
      <div>{review.content.comment2}</div>
      <b>Delivery</b>
      <div>{review.content.rating3}</div>
      <div>{review.content.comment3}</div>
      <b>Mechanics</b>
      <div>{review.content.rating4}</div>
      <div>{review.content.comment4}</div>
      <b>Overall</b>
      <div>{review.content.rating5}</div>
      <div>{review.content.comment5}</div>
    </div>
  );
}
