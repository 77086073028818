// export const ENDPOINT = process.env.BGM_ENDPOINT || "http://localhost:3000";
export const ENDPOINT = "https://lavr-prod-bgmserver.westack.dev";

export const encode = (obj, prefix) => {
  var pairs = [];
  for (var key in obj) {
    if (!Object.prototype.hasOwnProperty.call(obj, key)) {
      continue;
    }

    const value = obj[key];
    const enkey = encodeURIComponent(key);
    let pair;
    if (typeof value === "object") {
      pair = encode(value, prefix ? prefix + "[" + enkey + "]" : enkey);
    } else {
      pair =
        (prefix ? prefix + "[" + enkey + "]" : enkey) +
        "=" +
        encodeURIComponent(value);
    }
    pairs.push(pair);
  }
  return pairs.join("&");
};

export const search = (params) => {
  return fetch(ENDPOINT + "/search?" + encode(params)).then((r) => r.json());
};

export const facets = (tag, params) => {
  return fetch(ENDPOINT + `/facets/${tag}?` + encode(params)).then((r) =>
    r.json()
  );
};

export const formatDuration = (duration) => {
  const mins = parseInt(duration / 60, 10);
  let seconds = duration - mins * 60;
  if (seconds < 10) seconds = `0${seconds}`;

  return `${mins}:${seconds}`;
};
