import React, { Fragment, useEffect, useState } from "react";
import { useForm, Loading } from "components";
import { getTextFeedback } from "src/text";
import { analytics } from "src/analytics";
import { captureException } from "src/sentry";

export default function Feedback({ story }) {
  const { getValue } = useForm();
  const text = getValue("script");
  const [feedback, setFeedback] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    analytics.track("Check Script Feedback", { story_id: story.id });

    setLoading(true);
    getTextFeedback(text)
      .then((html) => {
        setLoading(false);
        setFeedback(html);
      })
      .catch((err) => {
        // TODO:
        alert("network error");
        captureException(err);
      });
  }, [text]);

  return loading ? (
    <Loading />
  ) : (
    <Fragment>
      <div dangerouslySetInnerHTML={{ __html: feedback }} />
      <div className="text-sm mt-2">
        <b>*</b> The feedback here is <b>automatically</b> generated by the
        system.
      </div>
    </Fragment>
  );
}
