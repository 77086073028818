import styles from "./Upload.module.scss";
import cx from "clsx";
import React, { useState } from "react";
import { uploadFile } from "src/utils";
import { captureException } from "src/sentry";

const MAX_FILE_SIZE = 30 * 1024 * 1024; // 30mb;

export default function Upload({
  type,
  children,
  className,
  value,
  onChange,
  keyOnly,
  ...props
}) {
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(null);
  const [upload, setUpload] = useState(null);

  function handleChange(e) {
    const file = e.target.files[0];
    if (file.size > MAX_FILE_SIZE) {
      return alert("The file size exceeds the limit of 30 mb");
    }

    // TODO: handle upload error
    // TODO: add progress indicator
    setLoading(true);
    uploadFile(type, file, setProgress)
      .then((data) => {
        setLoading(false);
        setUpload(data);
        if (keyOnly) {
          onChange(data.key);
        } else {
          onChange(data);
        }
      })
      .catch((err) => {
        alert("Network error");
        // TODO: handle error
        captureException(err);
      });
  }

  return (
    <div className={cx(styles.container, className)}>
      {children({ upload, progress, loading })}
      <input
        type="file"
        className={styles.input}
        onChange={handleChange}
        {...props}
      />
    </div>
  );
}

Upload.defaultProps = {
  keyOnly: false,
};

export function formatProgress(progress) {
  return progress ? progress.toFixed(1) + "%" : "";
}
