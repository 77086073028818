import React from "react";
import {
  Form,
  FormGroup,
  Button,
  FormSubmit,
  FormControl,
  Switch,
} from "components";
import { analytics } from "src/analytics";

export default function Overview({ story, updateStory }) {
  function handleSubmit(values) {
    analytics.track("Update Story Settings", { ...values, story_id: story.id });

    return updateStory(values);
  }

  return (
    <div className="p-4">
      <Form
        initialValues={{
          title: story.title,
          isPublished: story.isPublished,
          isShowcase: story.isShowcase,
        }}
        onSubmit={handleSubmit}
      >
        <FormGroup name="title" label="Story Title" />

        <div className="space-y-3">
          <div className="flex items-center">
            <div className="flex-1">Make public for peer review</div>
            <FormControl name="isPublished" component={Switch} />
          </div>
          <div className="flex items-center">
            <div className="flex-1">Make public to the world</div>
            <FormControl name="isShowcase" component={Switch} />
          </div>

          <FormSubmit component={Button} variant="primary" className="w-full">
            Save Settings
          </FormSubmit>
        </div>
      </Form>
    </div>
  );
}
