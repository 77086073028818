import React from "react";
import { Form, FormSubmit } from "@swiftcarrot/react-form";
import { gql, useMutation } from "@apollo/client";
import { Container, Card, Button, FormGroup } from "components";
import { useLocation } from "@swiftcarrot/react-router";
import { captureException } from "src/sentry";
import { analytics, useAnalyticsPage } from "src/analytics";

export default function Page() {
  const [, navigate] = useLocation();
  const [createClassroom] = useMutation(CREATE_CLASSROOM);
  useAnalyticsPage("New Classroom");

  function handleSubmit(values) {
    return createClassroom({ variables: { input: values } })
      .then((resp) => {
        analytics.track("Create Classroom", {
          classroom_id: resp.data.createClassroom.id,
        });
        navigate(`/admin/classrooms/${resp.data.createClassroom.id}`);
      })
      .catch((err) => {
        alert("network error");
        captureException(err);
      });
  }

  function validate(values) {
    const errors = {};
    if (!values.name.trim()) {
      errors.name = "Name cannot be blank";
    }
    if (!values.details.trim()) {
      errors.details = "Details cannot be blank";
    }
    return errors;
  }

  return (
    <Container>
      <Card title="Create classroom">
        <Form
          initialValues={{ name: "", details: "" }}
          validate={validate}
          onSubmit={handleSubmit}
        >
          <FormGroup name="name" label="Name" />
          <FormGroup name="details" label="Details" multiline minRows={4} />
          <div className="form-submit">
            <FormSubmit variant="primary" component={Button} />
          </div>
        </Form>
      </Card>
    </Container>
  );
}

const CREATE_CLASSROOM = gql`
  mutation CreateClassroom($input: CreateClassroomInput!) {
    createClassroom(input: $input) {
      id
    }
  }
`;
