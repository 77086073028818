import React from "react";
import { gql, useQuery } from "@apollo/client";
import { Button, Loading } from "components";
import { useAnalyticsPage } from "src/analytics";
import { imageURL } from "src/utils";
import { Row, Col } from "react-grid";
import { useSearchParams } from "@swiftcarrot/react-router";

export default function Page({ params: { id } }) {
  const [params] = useSearchParams();
  const page = params.get("page") ? parseInt(params.get("page"), 10) : 1;
  const { loading, data, error } = useQuery(QUERY, {
    variables: { id: id, page },
  });

  useAnalyticsPage("Assignment Stories", { assignment_id: id });

  return loading ? (
    <Loading />
  ) : error ? (
    "Network error"
  ) : (
    <div>
      {data.assignment.stories.map((story) => (
        <div
          key={story.id}
          style={{ padding: 10, border: "1px solid #eee", margin: 10 }}
        >
          <div>
            {story.user.name} ({story.user.email})
          </div>
          <div style={{ fontWeight: "bold", fontSize: 16 }}>{story.title}</div>
          <div>{story.script}</div>
          <a href={`/watch?id=${story.id}`} target="_blank">
            Open Story
          </a>
          <div>
            {story.scenes.map((scene) => (
              <Row key={scene.id} style={{ marginBottom: 20 }}>
                <Col>
                  <img
                    src={imageURL(scene.imageURL, {
                      width: 3840,
                      height: 2160,
                    })}
                  />
                </Col>

                <Col>
                  {scene.musicURL ? (
                    <div>
                      <div>Scene music audio</div>
                      <audio src={scene.musicURL} controls />
                    </div>
                  ) : null}
                  {scene.narrationURL ? (
                    <div>
                      <div>Scene narration audio</div>
                      <audio src={scene.narrationURL} controls />
                    </div>
                  ) : null}
                  <div>
                    {scene.entities.map((entity) => (
                      <div key={entity.id}>
                        <div style={{ fontWeight: "bold" }}>
                          {entity.type} entity
                        </div>
                        {entity.text ? <div>Text: {entity.text}</div> : null}
                        {entity.audioURL ? (
                          <div>
                            Audio: <audio src={entity.audioURL} controls />
                          </div>
                        ) : null}
                        {entity.imageURL ? (
                          <div>
                            Image
                            <img
                              src={imageURL(entity.imageURL, {
                                width: 1024,
                                height: 768,
                              })}
                            />
                          </div>
                        ) : null}
                      </div>
                    ))}
                  </div>
                </Col>
              </Row>
            ))}
          </div>
        </div>
      ))}
      <div>
        {page > 1 ? (
          <Button component="a" href={`?page=${page - 1}`}>
            Prev
          </Button>
        ) : null}
        <Button component="a" href={`?page=${page + 1}`}>
          Next
        </Button>
      </div>
    </div>
  );
}

const QUERY = gql`
  query Assignment($id: ID!, $page: Int) {
    assignment(id: $id) {
      id
      title
      details
      stories(page: $page) {
        id
        title
        script
        submittedAt
        user {
          id
          name
          email
        }
        scenes {
          id
          imageURL
          musicURL
          musicVolume
          narrationURL
          narrationVolume
          entities {
            id
            type
            text
            imageURL
            audioURL
            volume
            scene
          }
        }
      }
    }
  }
`;
