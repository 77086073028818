import cx from "clsx";
import React from "react";

export default function Card({ title, children, className, extra, ...props }) {
  return (
    <div
      className={cx("bg-white rounded border border-gray-200", className)}
      {...props}
    >
      {title ? (
        <div className="flex items-center px-4 py-3 border-b">
          <div className="flex-1 text-lg font-semibold min-w-0">{title}</div>
          {extra ? <div>{extra}</div> : null}
        </div>
      ) : null}
      {children ? <div className="px-4 py-3">{children}</div> : null}
    </div>
  );
}
