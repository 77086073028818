import styles from "./Switch.module.scss";
import React from "react";
import cx from "clsx";

export default function Switch({
  on,
  off,
  value,
  onChange,
  name,
  disabled,
  ...props
}) {
  const checked = value === on;

  function handleClick() {
    if (onChange) {
      onChange(checked ? off : on);
    }
  }

  return (
    <label
      {...props}
      className={cx(styles.container, {
        [styles.disabled]: disabled,
        [styles.checked]: checked,
      })}
      onClick={disabled ? null : handleClick}
    >
      <input type="hidden" name={name} value={value} />
      <span className={styles.track} />
      <span className={styles.button} />
    </label>
  );
}

Switch.defaultProps = {
  value: true,
  on: true,
  off: false,
  disabled: false,
  styles: {},
};
