import React from "react";
import { Button, Checkbox, Form, FormGroup, FormSubmit } from "components";

export default function GroupForm(props) {
  return (
    <Form {...props}>
      <FormGroup name="name" label="Name" />
      <FormGroup name="canReview" label="Can Review" component={Checkbox} />
      <div className="form-submit">
        <FormSubmit component={Button} />
      </div>
    </Form>
  );
}
