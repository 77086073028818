import React from "react";
import { gql, useQuery } from "@apollo/client";
import {
  Container,
  Loading,
  PageHeader,
  Link,
  Card,
  Placeholder,
} from "components";
import { useAnalyticsPage } from "src/analytics";
import ReviewContent from "src/components/ReviewContent";

export default function Page({ params: { id } }) {
  const { loading, data, error } = useQuery(QUERY, {
    variables: { id },
  });
  useAnalyticsPage("Assignment Reviews", { assignment_id: id });

  if (loading) return <Loading />;
  if (error) return "error";

  return (
    <Container>
      <Link href={`/assignments/${data.assignment.id}/peer`}>Back</Link>
      <PageHeader title="Reviews from others" />

      {data.assignment.reviews.length ? (
        data.assignment.reviews.map((review) => (
          <Card key={review.id} title={review.user.name}>
            <ReviewContent review={review} />
          </Card>
        ))
      ) : (
        <Placeholder title="No review" />
      )}
    </Container>
  );
}

const QUERY = gql`
  query Assignment($id: ID!) {
    assignment(id: $id) {
      id
      title
      details
      reviews: reviewsForViewer {
        id
        user {
          id
          name
          avatarURL
        }
        content {
          rating1
          comment1
          rating2
          comment2
          rating3
          comment3
          rating4
          comment4
          rating5
          comment5
        }
      }
    }
  }
`;
