// TODO: add to env
const END_POINT = "https://lavr-prod-text.westack.dev";

export function getTextFeedback(text) {
  return fetch(END_POINT + "/lavr", {
    method: "POST",
    body: JSON.stringify({ text }),
  })
    .then((resp) => resp.json())
    .then((obj) => {
      return obj.res;
    });
}

export function getScriptTags(text) {
  return fetch(END_POINT + "/bgm", {
    method: "POST",
    body: JSON.stringify({ text }),
  }).then((resp) => resp.json());
}
